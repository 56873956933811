import { IErrors } from "../components/framework/types";
import { IElement } from "../components/elements/types";
import { IParcel } from "../components/parcels/types";
import { IProduct, IProductElement } from "../components/products/types";
import { IRegion, IRegionElement } from "../components/regions/types";
import { IMonth, IReportDetails, IReportViewModel } from "../components/createReport2/types";
import { IUser } from "../components/users/types";
import { ITemplate, ITemplateProduct } from "../components/templates/types";
import { IBilling, IBillingHistory, IBillingParcel } from "../components/billing/types";
import { IDistributor, IDistributorProduct } from "../components/distributors/types";
import { ITask } from "../components/tasks/types";

const newUser: IUser = {
  id: 0,
  name: "",
  mobileNumber: "",
  technicalClasification: "",
  distributorId: null,
  technicalId: "",
  technicalName: ""
};

const newParcel: IParcel = {
  id: 0,
  userId: 0,
  regionId: null,
  variety: "",
  area: null,
  cordX: null,
  cordY: null,
  drippersFlowRate: null,
  drippersNumber: null,
  forecastProduction: null,
  irrigationFrequency: null,
  irrigationTime: null,
  plantsNumber: null,
  production: null,
  treesAge: null,
  year: null,
  plantType: null,
  name: null,
  geoJson: null,
  volumeXArea: null,
};

const newElement: IElement = {
  id: 0,
  name: "",
  symbol: "",
  productStandardOptional: false
};

const newRegion: IRegion = {
  id: 0,
  name: ""
};

const newRegionElement: IRegionElement = {
  id: 0,
  regionId: null,
  elementId: null,
  value: null,
  januaryPercentage: null,
  februaryPercentage: null,
  marchPercentage: null,
  aprilPercentage: null,
  mayPercentage: null,
  junePercentage: null,
  julyPercentage: null,
  augustPercentage: null,
  septemberPercentage: null,
  octoberPercentage: null,
  novemberPercentage: null,
  decemberPercentage: null,
};

const newReport: IReportViewModel = {
  details: { 
    id: null, 
    month: null, 
    parcelId: null,
    distributorId: null,
  } as IReportDetails,
  products: [],
  doseProducts: [],
  foliarProducts: [],
  elements: []
}

const newTemplate: ITemplate = {
  id: null,
  month: 0,
  name: "",
  monthName: "",
  regionId: 0,
  comment: "",
  foliarObservations: ""
}

const newTemplateProduct: ITemplateProduct = {
  id: 0,
  productId: 0,
  dose: 0,
  group: "",
  templateId: 0,
}


const newProduct: IProduct = {
  id: 0,
  name: "",
  group: "",
  cost: 0,
  type: "",
  januaryDose: null,
  februaryDose: null,
  marchDose: null,
  aprilDose: null,
  mayDose: null,
  junDose: null,
  julyDose: null,
  augustDose: null,
  septemberDose: null,
  octoberDose: null,
  novemberDose: null,
  decemberDose: null,
};

const newProductElement: IProductElement = {
  id: 0,
  productId: null,
  elementId: null,
  percentage: null
};

const newError: IErrors = {
  onSave: "",
  fieldErrors: []
};

const newBilling: IBilling = {
  id: 0,
  year: 0,
  month: 0,
  totalAmount: 0,
  notes: ""
};

const newBillingParcel : IBillingParcel = {
  id: 0,
  billingId: null,
  parcelId: null,
  amount: null,
  notes: null
};

const newBillingHistory : IBillingHistory = {
  id: 0,
  billingId: null,
  applicationUser: null,
  before: "",
  after: "",
  action: "",
  billingParcelId: null,
  dateModified: null,
  aspNetUserName: null
};

const months: IMonth[] = [
  { id: 1, name: 'Enero' } as unknown as IMonth,
  { id: 2, name: 'Febrero' } as unknown as IMonth,
  { id: 3, name: 'Marzo' } as unknown as IMonth,
  { id: 4, name: 'Abril' } as unknown as IMonth,
  { id: 5, name: 'Mayo' } as unknown as IMonth,
  { id: 6, name: 'Junio' } as unknown as IMonth,
  { id: 7, name: 'Julio' } as unknown as IMonth,
  { id: 8, name: 'Agosto' } as unknown as IMonth,
  { id: 9, name: 'Septiembre' } as unknown as IMonth,
  { id: 10, name: 'Octubre' } as unknown as IMonth,
  { id: 11, name: 'Noviembre' } as unknown as IMonth,
  { id: 12, name: 'Diciembre' } as unknown as IMonth,
]

const newDistributor = {
  id: null,
  name: "",
} as IDistributor;

const newDistributorProduct = {
  id: 0,
  distributorId: null,
  productId: null,
  percentage: null,
  price: null,
} as IDistributorProduct;

const newTask = {
  id: 0,
  aspNetUserId: "",
  parcelId: null,
  title: "",
  description: "",
  priority: 0,
  isCompleted: false,  
  targetDate: null,
  deletedDate: null,
} as ITask;

export { newUser, newParcel, newElement, 
         newRegion, newRegionElement, months, 
         newProduct, newProductElement, newReport, 
         newError, newTemplate, newTemplateProduct,
         newBilling, newBillingParcel, newBillingHistory ,
         newDistributor, newDistributorProduct, newTask}