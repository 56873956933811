import React from "react";

const AboutPage = () => (
  <div>
    <h4>Sobre Nosotros</h4>

    <p>
      Bienvenido a Wise Farmer, tu socio de confianza para la agricultura inteligente.

      En Wise Farmer, estamos comprometidos con el crecimiento y el éxito de los agricultores de todo el mundo. Somos una plataforma líder en la industria agrícola que se enfoca en brindar soluciones inteligentes y efectivas para maximizar el rendimiento de tus parcelas.

      Nuestro objetivo es proporcionarte las herramientas y conocimientos necesarios para enfrentar los desafíos agrícolas actuales y futuros. Con el apoyo de la tecnología más avanzada y un equipo de expertos en agronomía, ofrecemos una amplia gama de servicios personalizados para optimizar tu producción y rentabilidad.
    </p>


    <h4  style={{marginTop:"3em"}}>¿Qué nos hace diferentes?</h4>
    <p>

      En Wise Farmer, comprendemos que cada terreno y cada cultivo es único. Por ello, utilizamos un enfoque personalizado para cada cliente, teniendo en cuenta las características específicas de tu área, las condiciones climáticas, los recursos disponibles y tus objetivos agrícolas.

      Nuestra plataforma te brinda acceso a datos precisos y análisis detallados, lo que te permite tomar decisiones fundamentadas y estratégicas para mejorar la productividad y la sostenibilidad de tus cultivos.

    </p>


    <h4  style={{marginTop:"3em"}}>Nuestros Servicios</h4>
    <p>

      Asesoramiento Agronómico Avanzado: Nuestro equipo de agrónomos altamente capacitados te guiará en cada etapa del proceso, desde la selección de cultivos hasta la implementación de prácticas agrícolas eficientes.

      Tecnología de Última Generación: Utilizamos las últimas tecnologías y herramientas para monitorear y analizar tus parcelas, brindándote información en tiempo real para una toma de decisiones más rápida y efectiva.

      Optimización del Uso de Recursos: Te ayudamos a maximizar el uso de recursos como agua, fertilizantes y pesticidas, reduciendo costos y minimizando el impacto ambiental.

      Informes Personalizados: Recibirás informes detallados y fáciles de entender, que te ofrecerán información clave sobre el rendimiento de tus cultivos y recomendaciones específicas para mejorar tus resultados.

      En Wise Farmer, estamos comprometidos contigo y con el futuro de la agricultura sostenible. Únete a nuestra comunidad y descubre cómo podemos llevar tus parcelas al siguiente nivel.
    </p>
  </div>
);

export default AboutPage;
