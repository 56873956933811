import React from "react";
import PropTypes from "prop-types";
import { IBillingHistory } from "./types";

type Props = {
  billingHistory: IBillingHistory[],
}

const BillingHistoryList = ({ billingHistory }: Props) => (
  <div>
    {billingHistory.length > 0 ? <table className="table">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Usuario</th>
          <th>Acción</th>          
          <th>Antes</th>          
          <th>Después</th>          
        </tr>
      </thead>
      <tbody>
        {billingHistory.map(h => {
          return (
            <tr key={h.id}>
              <td>
                {
                    (h.dateModified instanceof Date) ?
                    h.dateModified?.toLocaleDateString("es-ES", { year: 'numeric', month: '2-digit', day: 'numeric', hour:'2-digit' }) :
                      new Date(h.dateModified!.toString()).toLocaleDateString("es-ES", { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute:'2-digit' })
                  }
              </td>
              <td>
                {h.aspNetUserName}
              </td>              
              <td>
                {h.action}
              </td>
              <td>
                {h.before}
              </td>    
              <td>
                {h.after}
              </td>             
            </tr>
          );
        })}
      </tbody>
    </table> : <div />}

  </div>
);




BillingHistoryList.propTypes = {
  billingHistory: PropTypes.array.isRequired,
};

export default BillingHistoryList;
