import PropTypes from "prop-types";
import { IReportViewModel } from "../createReport2/types";
import ReportForm from "./ReportForm";
import { IParcel } from "../parcels/types";
import { IProduct } from "../products/types";
import { IUser } from "../users/types";
import { Container, Row } from "reactstrap";
import { IRegion } from "../regions/types";
import { IElement } from "./types";
import { IDistributor } from "../distributors/types";

type Props = {
  reports: IReportViewModel[],
  parcels: IParcel[],
  products: IProduct[],
  regions: IRegion[],
  elements: IElement[],
  users: IUser[]
  distributors: IDistributor[]
}

const ReportList = ({ reports, parcels, products, regions, elements, users, distributors }: Props) => {
let index = 1;
  return (
    <div className="scroll-container" style={{width: "100%"}}>
      <h5>{reports?.length + " informes"}</h5>
      <Container>
        {
          reports.map(report => {
            return (
              <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
                {index++}                
                <ReportForm
                  report={report}
                  parcel={parcels.find(p => p.id === report.details.parcelId)!}
                  products={products}
                  regions={regions}
                  users={users}
                  elements={elements}
                  distributors={distributors}
                ></ReportForm>
              </Row>
            );
          })
        }        
        <Row>
          <div id="divCopyReports" style={{ marginTop: '10px', padding: '3px' }} />          
          <div id="divCopyReportProducts" style={{ marginTop: '10px', padding: '3px' }} />          
        </Row>
      </Container>     
    </div>
  )

}




ReportList.propTypes = {
  reports: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired
};

export default ReportList;
