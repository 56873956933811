import React, { Component, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import './assets/css/custom.css';
import AppRoutes from './components/menu/AppRoutes';
import { Layout } from './components/menu/Layout';
import AuthorizeRoute from './api/api-authorization/AuthorizeRoute';
import "react-toastify/dist/ReactToastify.css";
import './App.css'; // Archivo de estilos CSS para la aplicación
import PropTypes from "prop-types";
import { setUser } from './redux/actions/userRoleActions';
import { connect } from 'react-redux';
import authService from './api/api-authorization/AuthorizeService';

type Props = {
  element: JSX.Element | null,
  path: string,
  roles: string[],
  setUser: (userName:any) => Promise<void>;
};

function App
  ({ element, 
    path, 
    roles, 
    setUser,
    ...props }: Props) {

      
useEffect(() => {
  const subscription = authService.subscribe(() => populateAuthenticationState());
  populateAuthenticationState();
  return () => authService.unsubscribe(subscription);
}, []);

const populateAuthenticationState = async () => {
  const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);   
  setUser({userName: user?.name, roleName: user?.role, isAuthenticated: isAuthenticated, loaded: true });  
};
      return (
        <div className="app">
          <div className="background-image"></div>
          <div className="container-fluid">
            <div className="content">
              <Layout>
                <Routes>
                  {AppRoutes.map((route, index) => {
                    const { element, requiredAuth, roles, ...rest } = route;
                    return <Route key={index} {...rest} element={requiredAuth ? <AuthorizeRoute {...rest} element={element} roles={roles} /> : element} />;
                  })}
                </Routes>
              </Layout>
              <ToastContainer autoClose={3000} hideProgressBar />
            </div>
          </div>
        </div>
      );               
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    
  };
}


App.propTypes = {  
   setUser: PropTypes.func.isRequired,
 };


const mapDispatchToProps = {
  setUser
};

export default connect(mapStateToProps, mapDispatchToProps)(App);