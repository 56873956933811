import React from "react";
import PropTypes from "prop-types";
import { IFieldError } from "./types";

type OptionValue = string | number;

export type SelectOption ={

  text: OptionValue,
  value: OptionValue,

}

type Props = {
  name: string,
  label?: string,
  onChange?: (event: any) => void | null,
  defaultOption: string,
  options: SelectOption[],
  value: any,
  errors: IFieldError[],
  wrapperClass: string,
  isReadOnly: boolean
}


const SelectInput = ({
  name,
  label,
  onChange,
  options,
  defaultOption,
  value,
  errors,
  wrapperClass,
  isReadOnly
}: Props) => {

  let fielderror = errors?.find(e => e.fieldName === name)
  if (fielderror) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name} className="">{label}</label>}
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={isReadOnly}        
        className="form-control wise-font"
      >
        <option value="">{defaultOption}</option>
        {options.map(option=> {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}        
      </select>
      {fielderror && <div className="alert alert-danger">{fielderror?.title}</div>}      
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
};

const defaultProps: any = {
  errors: [],
  label: "",
  name: "",
  value: "",
  wrapperClass: "",
  placeholder: "",
  isReadOnly: false
}
SelectInput.defaultProps = defaultProps;


export default SelectInput;
