import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import DateTimeInput from "../framework/DateTimeInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IParcel } from "./types";
import { IRegion } from "../regions/types";
import { ROLE_ADMIN, ROLE_TECHNICAL } from "../utils/constants";
import AdminContainer from "../framework/AdminContainer";


type Props = {
  parcel: IParcel,
  onEdit: (event: any) => void,
  regions: IRegion[],
  errors: IErrors
}

const ParcelFormRO = ({
  parcel,
  onEdit,
  regions,
  errors
}: Props) => {

  const navigate = useNavigate();

  return (
    <div>
      <h4>{parcel.id ? "Editar" : "Crear"} Parcela</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row">
          <Col className="col-sm-3">
            <TextInput
              name="name"
              label="Nombre"
              value={parcel.name?.toString()}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="regionId"
              label="Región"
              value={regions.find(r => r.id === parcel.regionId)?.name}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="volumeXArea"
              label="Vol. Hectárea"
              value={parcel.volumeXArea?.toString()}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-3">
            <TextInput
              name="year"
              label="Año"
              value={parcel.year?.toString()}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="plantsNumber"
              label="Num. Plantas"
              value={parcel.plantsNumber?.toString()}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="area"
              label="Superficie"
              value={parcel.area?.toString()}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-3">
            <TextInput
              name="plantType"
              label="Tipo Planta"
              value={parcel.plantType?.toString()}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
          <AdminContainer>
            <Col className="col-sm-3">
              <TextInput
                name="billingMonthAmount"
                label="Fact. por mes"
                value={parcel.billingMonthAmount?.toString()}
                errors={errors.fieldErrors}
                wrapperClass={"col 4"}
                isReadOnly={true}
              />
            </Col>
            <Col className="col-sm-3">
              <DateTimeInput
                name="billingStartDate"
                label="Fact. desde"
                value={parcel.billingStartDate}
                errors={errors.fieldErrors}
                wrapperClass={"col 4"}
                isReadOnly={true}
                format="MM/yyyy"
              />
            </Col>
          </AdminContainer>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-3">
            <Button
              type="button"
              className="btn btn-primary"
              onClick={onEdit}
              name="Editar"
            ></Button>
          </Col>
          {
            !!parcel.id && (parcel.billingMonthAmount ?? 0 ) > 0 && 
            <Col className="col-sm-1" >
              <Button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => navigate('/parcela/' + parcel.id + '/crearinforme/')}
                name="Calcular"
              ></Button>
            </Col>
          }
          <AdminContainer>
            <Col className="col-sm-1">
              <Button
                type="button"
                className="btn btn-primary"
                name="Foliares"
              ></Button>
            </Col>
            <Col className="col-sm-1">
              <Button
                type="button"
                className="btn btn-primary"
                name="Sonda"
              ></Button>
            </Col>
            <Col className="col-sm-1">
              <Button
                type="button"
                className="btn btn-primary"
                name="Satélite"
              ></Button>
            </Col>
            <Col className="col-sm-1">
              <Button
                type="button"
                className="btn btn-primary"
                name="Plagas"
              ></Button>
            </Col>
            <Col className="col-sm-2">
              <Button
                type="button"
                className="btn btn-primary"
                name="Info Visual"
              ></Button>
            </Col>
          </AdminContainer>
        </Row>
      </Container>
    </div>
  );
};

export default ParcelFormRO;
