import React from "react";
import PropTypes from "prop-types";
import { IElement } from "./types";
import Button from "../framework/Button";

type Props = {
  elements: IElement[],
  onEditClick: (element: IElement) => void,
  onDeleteClick: (element: IElement) => void,
}

const ElementList = ({ elements, onEditClick, onDeleteClick }: Props) => (
  <div className="scroll-container">
    {elements.length > 0 ? <table className="table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th style={{width: '5%'}} />
          <th />
        </tr>
      </thead>
      <tbody>
        {elements.map(element => {
          return (
            <tr key={element.id}>
              <td>
                {element.name}
              </td>
              <td>
                <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => onEditClick(element)}
                  name="Editar"
                ></Button>    
              </td>
              <td>
                <Button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => onDeleteClick(element)}
                  name="Delete"
                  style={{ marginLeft: "5px" }}
                ></Button>  
              </td>
            </tr>
          );
        })}
      </tbody>
    </table> : <div />}

  </div>
);




ElementList.propTypes = {
  elements: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default ElementList;
