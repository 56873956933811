import { IElement } from "../components/elements/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/element";


export async function getElements(): Promise<IElement[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveElement(element: IElement): Promise<IElement> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: element.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(element)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteElement(elementId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + elementId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
