import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mazeReducer(state = initialState, action) {
  switch (action.type) {
    case types.MOVE_HERO:
      var newHeroCords = move(
        state.heroCords,
        action.dir,
        state.boardRows,
        state.boardColumns
      );
      return {
        ...state,
        tail: state.tail.map((c) =>
          move(c, action.dir, state.boardRows, state.boardColumns)
        ),
        heroCords: { ...newHeroCords },
      };
    case types.CHANGE_HERO_DIR:
      var newDir = action.dir;
      if (
        (state.lastHeroDir === "left" && action.dir === "right") ||
        (state.lastHeroDir === "right" && action.dir === "left") ||
        (state.lastHeroDir === "up" && action.dir === "down") ||
        (state.lastHeroDir === "down" && action.dir === "up")
      )
        return state;

      return {
        ...state,
        lastHeroDir: newDir,
      };
    case types.SHOW_BUG:
      return {
        ...state,
        bugCords: { ...action.cords },
      };
    case types.ADD_TAIL:
      /* var x = state.heroCords.x;
      var y = state.heroCords.y;

      if (action.dir == "up") y = y - 1;
      else if (action.dir == "down") y = y + 1;
      else if (action.dir == "left") x = x - 1;
      else if (action.dir == "right") x = x - 1;

      return {
        ...state,
        tail: [...state.tail, { ...{ x, y } }],
      };
      */
      return state;
    default:
      return state;
  }
}

const move = (cords, dir, numberOfRows, numberOfColumns) => {
  if (dir === "up" && cords.y > 0) return { x: cords.x, y: cords.y - 1 };
  else if (dir === "down" && cords.y < numberOfColumns - 1)
    return { x: cords.x, y: cords.y + 1 };
  else if (dir === "left" && cords.x > 0) return { x: cords.x - 1, y: cords.y };
  else if (dir === "right" && cords.x < numberOfRows - 1)
    return { x: cords.x + 1, y: cords.y };
  else return cords;
};
