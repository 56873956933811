import { IProductElement } from "../components/products/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/productElement";


export async function getProductElements(): Promise<IProductElement[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveProductElement(productElement: IProductElement): Promise<IProductElement> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: productElement.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(productElement)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteProductElement(productElementId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + productElementId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
