import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../framework/Button";
import { IReportViewModel } from "../createReport2/types";
import { IProduct } from "../products/types";
import { ConvertToMonthName } from "../utils/monthCalculations";
import html2canvas from "html2canvas";
import { IParcel } from "../parcels/types";
import { IUser } from "../users/types";
import { Link } from "react-router-dom";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { IRegion } from "../regions/types";
import { IElement } from "./types";
import { IDistributor } from "../distributors/types";
import { formatDate } from "../utils/dateUtils";

type Props = {
  report: IReportViewModel,
  parcel: IParcel,
  products: IProduct[],
  regions: IRegion[],
  elements: IElement[],
  users: IUser[],
  distributors: IDistributor[],
}

const ReportForm = ({
  report,
  parcel,
  products,
  regions,
  elements,
  users,
  distributors
}: Props) => {

  const [downloadUrl, setdownloadUrl] = useState(false);
  const [loading, setloading] = useState(true);
  const [showDistributorProducts, setShowDistributorProducts] = useState(false);
  const [distributorName, setDistributorName] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalPercentage, setTotalPercentage] = useState(0);
  let indexStandardProducts = 0;

  const reportName = parcel.name ? parcel.name + " - " + report.details.year + ' ' + report.details.monthName :
    report.details.year + ' ' + report.details.monthName



  useEffect(() => {
    if (!loading)
      setdownloadUrl(true);
  }, [loading]);

  useEffect(() => {

    report.details.monthName = ConvertToMonthName(report.details.month!);
    report.details.year = new Date(report.details.insertDate!).getFullYear()
    report.details.user = users.find(u => u.id === parcel.userId)!;
    

    if (report.details.distributorId)
    {
      setShowDistributorProducts(true);
      setDistributorName(distributors.find(d => d.id === report.details.distributorId)?.name ?? "");

      setTotalPrice(report.products.map(p => p.price ?? 0).reduce((a, b) => a + b, 0) + 
      report.doseProducts.map(p => p.price ?? 0).reduce((a, b) => a + b, 0) +
      report.foliarProducts.map(p => p.price ?? 0).reduce((a, b) => a + b, 0));

      setTotalCost(report.products.map(p => p.cost ?? 0).reduce((a, b) => a + b, 0) +
      report.doseProducts.map(p => p.cost ?? 0).reduce((a, b) => a + b, 0) +
      report.foliarProducts.map(p => p.cost ?? 0).reduce((a, b) => a + b, 0));

      setTotalCommission(report.products.map(p => p.commission ?? 0).reduce((a, b) => a + b, 0) +
      report.doseProducts.map(p => p.commission ?? 0).reduce((a, b) => a + b, 0) +
      report.foliarProducts.map(p => p.commission ?? 0).reduce((a, b) => a + b, 0));

      setTotalPercentage(report.products.map(p => p.percentage ?? 0).reduce((a, b) => a + b, 0) +
      report.doseProducts.map(p => p.percentage ?? 0).reduce((a, b) => a + b, 0) +
      report.foliarProducts.map(p => p.percentage ?? 0).reduce((a, b) => a + b, 0));


    }
    else
      setShowDistributorProducts(false);

    setloading(false);
  }, [products, report]);

  function customRound(value: number) {
    return Math.round(value * 10) / 10;
  }

  function handleCopy(reportId: number) {
    var r = document.createRange();
    r.selectNode(document.getElementById("divReport" + reportId)!);
    window.getSelection()!.removeAllRanges();
    window.getSelection()!.addRange(r);
    try {
      document.execCommand('copy');
      window.getSelection()!.removeAllRanges();
      toast.info("Copiado");
    } catch (err) {
      console.log('Unable to copy!');
    }
  }

  function handleCopyImage(reportId: number) {
    let src = document.getElementById("divReport" + reportId)!;
    html2canvas(src).then(function (canvas) {
      canvas.toBlob(function (blob) {
        navigator.clipboard
          .write([
            new ClipboardItem(
              Object.defineProperty({}, blob!.type, {
                value: blob,
                enumerable: true
              })
            )
          ])
          .then(function () {
            toast.info("Copiado");
          });
      });
    });
  }

  function handleCopyImageDistributor(reportId: number) {
    let src = document.getElementById("divReportProducts" + reportId)!;
    html2canvas(src).then(function (canvas) {
      canvas.toBlob(function (blob) {
        navigator.clipboard
          .write([
            new ClipboardItem(
              Object.defineProperty({}, blob!.type, {
                value: blob,
                enumerable: true
              })
            )
          ])
          .then(function () {
            toast.info("Copiado");
          });
      });
    });
  }

  function sumUfTotal(elementSymbol: | "N" | "P" | "K" | "C") {

    const elementId = elements.find(e => e.symbol === elementSymbol)?.id;

    let sumDiff = 0;
    report.elements.filter(e => e.elementId === elementId).forEach(element => {
      sumDiff += Math.abs(element.ufTotal!) - Math.abs(element.ufCurrentTotal!);
    });

    return Math.round(sumDiff);
  }

  const handleShareWhatsapp = async (reportId: number) => {
    // here image is url/location of image

    let src = document.getElementById("divReport" + reportId)!;
    html2canvas(src).then(function (canvas) {
      canvas.toBlob(async function (blob) {
        if (blob) {
          const file = new File([blob], 'share.jpg', { type: blob.type });
          if (navigator.share) {
            await navigator.share({
              title: "Informe " + report.details.monthName,
              url: "",
              files: [file]
            })
              .catch((error) => console.log('Error in sharing', error));
          } else {
            console.log(`system does not support sharing files.`);
          }
        }
      });
    });
  }

  function handleDownload(reportId: number, fileName: string) {
    let src = document.getElementById("divReport" + reportId)!;
    html2canvas(src).then(function (canvas) {
      canvas.toBlob(async function (blob) {
        if (blob) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
          // const url = URL.createObjectURL(blob);
          // return url;
        }
      });
    });


    return "";
  }

  return (

    <div style={{ marginTop: '10px', padding: '3px' }} key={report.details.id + "m"} className="scroll-container">
      {
        !loading &&
        <div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", flexWrap: "wrap" }}>
              <div>
                <b>Parcela: </b>{parcel.name}
              </div>
              <div>
                <b>Region: </b>{regions.find(r => r.id === parcel.regionId)?.name}
              </div>
              <div>
                <b>Usuario: </b>{users.find(r => r.id === parcel.userId)?.name}
              </div>
              <div>
                <b>Usuario Técnico: </b>{users.find(r => r.id === parcel.userId)?.technicalClasification}
              </div>
              <div>
                <b>Distribuidor: </b>{distributorName}
              </div>
              <div>
                <b>Fecha Creación: </b> 
                {
                  !!report.details.insertDate ? formatDate(new Date(report.details.insertDate.toString() + "Z"), "dd/mm/yyyy hh:min:ss") : ""
                }
                
              </div>
              <div>
                {report.elements.some(e => Math.abs(e.ufCurrentTotal ?? 0) - Math.abs(e.ufTotal ?? 0) >= 1) &&
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    UF Total no ajustado
                    N:{sumUfTotal("N")} F:{sumUfTotal("P")} K:{sumUfTotal("K")} C:{sumUfTotal("C")}
                  </div>}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", flexWrap: "wrap" }}>
              <div>
                <Link to={"/parcela/" + parcel.id?.toString() + "/crearinforme/" + report.details.id} className="btn btn-outline-primary wise-font">
                  Editar
                </Link>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", flexWrap: "wrap" }}>
            <div>
              <Button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => handleCopyImage(report.details.id!)}
                name="Copiar Informe Agrícola"
              ></Button>
            </div>
            {
              showDistributorProducts &&
              <div>
                <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => handleCopyImageDistributor(report.details.id!)}
                  name="Copiar Informe Distribuidor"
                ></Button>
              </div>
            }
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div id={"divReport" + report.details.id}>
              <table className="table table-bordered" >
                <tbody>
                  <tr style={{ fontWeight: "bold" }}>
                    <td style={{ backgroundColor: "#548235" }}>{parcel.plantType?.toUpperCase()}</td>
                    <td style={{ backgroundColor: "#FFD966" }}>AÑO</td>
                    <td colSpan={2} style={{ backgroundColor: "#FFD966" }}>{reportName}</td>
                    <td style={{ backgroundColor: "#FFD966" }}>{report.details.user?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#DBDBDB" }}>PLANTAS</td>
                    <td style={{ backgroundColor: "#FFD966" }}>KG/M</td>
                    <td style={{ backgroundColor: "#FFD966" }}>KG/SEM</td>
                    <td style={{ backgroundColor: "#FFD966" }}>G/P Y SEM</td>
                    <td style={{ backgroundColor: "#FFD966" }}>PRODUCTO</td>
                  </tr>
                  {
                    report.products.filter(p => p.group === "Grupo A" && p.kgXMonth).map(product => {
                      return (
                        <tr key={report.details.id + "_a" + product.id} style={{ backgroundColor: "#F4B084", textAlign: 'right' }}>
                          <td style={{ backgroundColor: "#DBDBDB" }}>
                            {indexStandardProducts++ === 0 ? report.details.plantsNumber : null}
                          </td>
                          <td>
                            {customRound((product.kgXMonth ?? 0))}
                          </td>
                          <td>
                            {customRound((product.kgXMonth ?? 0) / 4)}
                          </td>
                          <td>
                            {customRound((product.kgXMonth ?? 0) * 1000 / 4 / report.details.plantsNumber!)}
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            {products.find(p => p.id === product.productId)?.name}
                          </td>
                        </tr>
                      );
                    })}
                  {report.products.filter(p => p.group === "Grupo B" && p.kgXMonth).map(product => {
                    return (
                      <tr key={report.details.id + "_sb" + product.id} style={{ backgroundColor: "#9BC2E6", textAlign: 'right' }}>
                        <td style={{ backgroundColor: "#DBDBDB" }}></td>
                        <td>
                          {customRound(product.kgXMonth ?? 0)}
                        </td>
                        <td>
                          {customRound((product.kgXMonth ?? 0) / 4)}
                        </td>
                        <td>
                          {customRound((product.kgXMonth ?? 0) * 1000 / 4 / report.details.plantsNumber!)}
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          {products.find(p => p.id === product.productId)?.name}
                        </td>
                      </tr>
                    );
                  })}
                  {report.doseProducts.filter(p => p.group === "Grupo B" && p.doseTotal).map(product => {
                    return (
                      <tr key={report.details.id + "_db" + product.id} style={{ backgroundColor: "#9BC2E6", textAlign: 'right' }}>
                        <td style={{ backgroundColor: "#DBDBDB" }}></td>
                        <td>
                          {customRound(product.doseTotal ?? 0)}
                        </td>
                        <td>
                          {customRound((product.doseTotal ?? 0) / 4)}
                        </td>
                        <td>
                          {customRound((product.doseTotal ?? 0) * 1000 / 4 / report.details.plantsNumber!)}
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          {products.find(p => p.id === product.productId)?.name}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {report.doseProducts && report.doseProducts.filter(p => p.group === "Grupo E" && p.doseTotal).length > 0 &&
                <div>
                  <div>&nbsp;</div>
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ backgroundColor: "#9BC2E6", fontWeight: "bold" }}>
                        <td colSpan={2}>Producto</td>
                        <td colSpan={2}>Tipo Dosis</td>
                        <td>Dosis</td>
                      </tr>
                    </thead>
                    <tbody>
                      {report.doseProducts.filter(p => p.group === "Grupo E" && p.doseTotal).map(product => {
                        return (
                          <tr key={report.details.id + "_x" + product.id} style={{ backgroundColor: "#9BC2E6" }}>
                            <td colSpan={2}>
                              {products.find(p => p.id === product.productId)?.name}
                            </td>
                            <td colSpan={2}>
                              {product.group === 'Grupo B' ? "Por Hectárea" : "Por Planta"}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {customRound(product.doseTotal ?? 0)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              }
              {
                report.foliarProducts && report.foliarProducts.filter(p => p.doseTotal).length > 0 &&
                <div>
                  <div>&nbsp;</div>
                  <table className="table " style={{ border: '1px solid #ddd' }}>
                    <tbody>
                      <tr style={{ fontWeight: "bold" }} >
                        <td rowSpan={report.foliarProducts.length + 1} style={{ textAlign: "center", verticalAlign: "middle" }}>FOLIAR {parcel.plantType?.toUpperCase()}</td>
                        <td
                          rowSpan={report.foliarProducts.length + 1}
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            backgroundColor: "#B8E0BE",
                            width: '25%'
                          }}>
                          {report.details.foliarObservations}
                        </td>
                        <td>PRODUCTO</td>
                        <td>DOSIS CC/G</td>
                      </tr>
                      {report.foliarProducts.map(fp => {
                        return (
                          <tr key={report.details.id + "_f" + fp.id}>
                            <td style={{ backgroundColor: "#DBDBDB" }}>{products.find(p => p.id === fp.productId)?.name}</td>
                            <td colSpan={2} style={{ backgroundColor: "#DBDBDB" }}>{customRound(fp.doseTotal ?? 0)}</td>
                          </tr>
                        );

                      })}
                    </tbody>
                  </table>
                </div>
              }
              {report.details.comment &&
                <div>
                  <h6>
                    <b>Observaciones:</b> {report.details.comment}
                  </h6>
                </div>
              }

            </div>
            {
              showDistributorProducts &&
              <div style={{ display: "flex", width: "40%" }}>
                <div style={{ width: "2%" }}></div>
                <div style={{ width: "98%" }} id={"divReportProducts" + report.details.id}>
                  <div id={"divReportProducts" + report.details.id}>
                    <table className="table table-bordered" style={{backgroundColor:"#ffd083"}} >
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}></td>
                          <td style={{ width: "15%" }}></td>
                          <td style={{ width: "15%" }}></td>
                          <td style={{ width: "20%" }}></td>
                          <td style={{ width: "20%" }}>&nbsp;</td>
                        </tr>
                        <tr style={{ fontWeight: "bold" }}>
                          <td>Producto</td>
                          <td>Precio</td>
                          <td>Coste</td>
                          <td>Porcentaje</td>
                          <td>Comisión</td>
                        </tr>
                        {
                          report.products.filter(p => p.group === "Grupo A" && p.kgXMonth).map(product => {
                            return (
                              <tr key={report.details.id + "_aa" + product.id} style={{ textAlign: 'right' }}>
                                <td>
                                  {products.find(p => p.id === product.productId)?.name}
                                </td>
                                <td>
                                  {customRound((product.price ?? 0))}
                                </td>
                                <td>
                                  {customRound((product.cost ?? 0))}
                                </td>
                                <td>
                                  {customRound((product.percentage ?? 0))}
                                </td>
                                <td>
                                  {customRound(product.commission ?? 0)}
                                </td>
                              </tr>
                            );
                          })}
                        {report.products.filter(p => p.group === "Grupo B" && p.kgXMonth).map(product => {
                          return (
                            <tr key={report.details.id + "_sbb" + product.id} style={{ textAlign: 'right' }}>
                              <td>
                                {products.find(p => p.id === product.productId)?.name}
                              </td>
                              <td>
                                {customRound((product.price ?? 0))}
                              </td>
                              <td>
                                {customRound((product.cost ?? 0))}
                              </td>
                              <td>
                                {customRound((product.percentage ?? 0))}
                              </td>
                              <td>
                                {customRound(product.commission ?? 0)}
                              </td>
                            </tr>
                          );
                        })}
                        {report.doseProducts.filter(p => p.group === "Grupo B" && p.doseTotal).map(product => {
                          return (
                            <tr key={report.details.id + "_dbb" + product.id} style={{ textAlign: 'right' }}>
                              <td>
                                {products.find(p => p.id === product.productId)?.name}
                              </td>
                              <td>
                                {customRound((product.price ?? 0))}
                              </td>
                              <td>
                                {customRound((product.cost ?? 0))}
                              </td>
                              <td>
                                {customRound((product.percentage ?? 0))}
                              </td>
                              <td>
                                {customRound(product.commission ?? 0)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {report.doseProducts && report.doseProducts.filter(p => p.group === "Grupo E" && p.doseTotal).length > 0 &&
                      <div>
                        <div>&nbsp;</div>
                        <table className="table table-bordered"  style={{backgroundColor:"#ffd083"}}>
                          <thead>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ width: "30%" }}>Producto</td>
                              <td style={{ width: "15%" }}>Precio</td>
                              <td style={{ width: "15%" }}>Coste</td>
                              <td style={{ width: "20%" }}>Porcentaje</td>
                              <td style={{ width: "20%" }}>Comisión</td>
                            </tr>
                          </thead>
                          <tbody>
                            {report.doseProducts.filter(p => p.group === "Grupo E" && p.doseTotal).map(product => {
                              return (
                                <tr key={report.details.id + "_xx" + product.id} style={{ textAlign: 'right' }} >
                                  <td>
                                    {products.find(p => p.id === product.productId)?.name}
                                  </td>
                                  <td>
                                    {customRound((product.price ?? 0))}
                                  </td>
                                  <td>
                                    {customRound((product.cost ?? 0))}
                                  </td>
                                  <td>
                                    {customRound((product.percentage ?? 0))}
                                  </td>
                                  <td>
                                    {customRound(product.commission ?? 0)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    }
                    {
                      report.foliarProducts && report.foliarProducts.filter(p => p.doseTotal).length > 0 &&
                      <div>
                        <div>&nbsp;</div>
                        <table className="table table-bordered"  style={{backgroundColor:"#ffd083"}}>
                          <thead>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ width: "30%" }}>Producto</td>
                              <td style={{ width: "15%" }}>Precio</td>
                              <td style={{ width: "15%" }}>Coste</td>
                              <td style={{ width: "20%" }}>Porcentaje</td>
                              <td style={{ width: "20%" }}>Comisión</td>
                            </tr>
                          </thead>
                          <tbody>
                            {report.foliarProducts.filter(p => p.group === "Grupo F" && p.doseTotal).map(product => {
                              return (
                                <tr key={report.details.id + "ff" + product.id} style={{ textAlign: 'right' }} >
                                  <td>
                                    {products.find(p => p.id === product.productId)?.name}
                                  </td>
                                  <td>
                                    {customRound((product.price ?? 0))}
                                  </td>
                                  <td>
                                    {customRound((product.cost ?? 0))}
                                  </td>
                                  <td>
                                    {customRound((product.percentage ?? 0))}
                                  </td>
                                  <td>
                                    {customRound(product.commission ?? 0)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    }
                    <div>
                      <div>&nbsp;</div>
                      <table className="table table-bordered"  style={{backgroundColor:"#ffd083"}}>
                        <thead>
                          <tr style={{ fontWeight: "bold" }}>
                            <td style={{ width: "30%" }}>Total</td>
                            <td style={{ width: "15%" }}>Precio</td>
                            <td style={{ width: "15%" }}>Coste</td>
                            <td style={{ width: "20%" }}>Porcentaje</td>
                            <td style={{ width: "20%" }}>Comisión</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ textAlign: 'right' }}>
                            <td></td>
                            <td> {customRound(totalPrice ?? 0)}</td>
                            <td> {customRound(totalCost ?? 0)}</td>
                            <td> {customRound(((totalCommission ?? 0)* 100 / totalCost ?? 0))}</td>
                            <td> {customRound(totalCommission ?? 0)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            }
          </div>
        </div>
      }
    </div>
  );
};

export default ReportForm;
