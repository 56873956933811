import React from "react";
import "./Maze.css";
import PropTypes from "prop-types";

const HeroControl = ({ handleHeroMove }) => {
  const onClick = (evt) => {
    handleHeroMove(evt.target.name);
  };

  document.onkeydown = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode === 37) {
      handleHeroMove("left");
    } else if (evt.keyCode === 38) {
      handleHeroMove("up");
    } else if (evt.keyCode === 39) {
      handleHeroMove("right");
    } else if (evt.keyCode === 40) {
      handleHeroMove("down");
    }
  };

  return (
    <div className="control">
      <div className="control-row">
        <button
          name="left"
          className="col control-key-left"
          onClick={onClick}
        ></button>
        <button
          name="up"
          className="col control-key-up"
          onClick={onClick}
        ></button>
        <button
          name="right"
          className="col control-key-right"
          onClick={onClick}
        ></button>
      </div>
      <div className="control-row">
        <div className="col no-button" />
        <button
          name="down"
          className="col control-key-down"
          onClick={onClick}
        ></button>
        <div className="col no-button" />
      </div>
    </div>
  );
};

HeroControl.propTypes = {
  handleHeroMove: PropTypes.func.isRequired,
};

export default HeroControl;
