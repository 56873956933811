import React from "react";
import PropTypes from "prop-types";
import { IBillingParcel } from "./types";
import { IParcel } from "../parcels/types";
import Button from "../framework/Button";

type Props = {
  billingParcels: IBillingParcel[],
  parcels: IParcel[]
  onEditClick: (event: any) => void,
  onDeleteClick: (event: any) => void,
}

const BillingParcelList = ({ billingParcels, parcels, onEditClick, onDeleteClick }: Props) => (
  <div>
    {billingParcels.length > 0 ? <table className="table">
      <thead>
        <tr>
          <th>Parcela</th>
          <th>Cantidad</th>
          <th>Notas</th>          
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {billingParcels.map(billingParcel => {
          return (
            <tr key={billingParcel.id}>
              <td>
                {parcels.find(e => e.id === billingParcel.parcelId)?.name}
              </td>
              <td>
                {billingParcel.amount}
              </td>
              <td>
                {billingParcel.notes}
              </td>  
              <td>
                <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => onEditClick(billingParcel)}
                  name="Editar"
                ></Button>                
              </td>
            </tr>
          );
        })}
      </tbody>
    </table> : <div />}

  </div>
);




BillingParcelList.propTypes = {
  billingParcels: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default BillingParcelList;
