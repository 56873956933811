import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function elementReducer(state = initialState.elements, action) {
  switch (action.type) {
    case types.CREATE_ELEMENT_SUCCESS:
      return [...state, { ...action.element }];
    case types.UPDATE_ELEMENT_SUCCESS:
      return state.map(element =>
        element.id === action.element.id ? action.element : element
      );
    case types.LOAD_ELEMENTS_SUCCESS:
      return action.elements;
    case types.DELETE_ELEMENT_OPTIMISTIC:
      return state.filter(element => element.id !== action.elementId);
    default:
      return state;
  }
}
