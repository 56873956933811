import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadProducts, saveProduct } from "../../redux/actions/productsActions";
import { loadElements } from "../../redux/actions/elementsActions";
import { loadProductElements, saveProductElement, deleteProductElement } from "../../redux/actions/productElementsActions";
import PropTypes from "prop-types";
import ProductForm from "./ProductForm";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { IProduct, IProductElement } from "./types";
import { newError, newProduct, newProductElement } from "../../tools/mockData";
import { Container, Row } from "reactstrap";
import { IElement } from "../elements/types";
import ProductElementForm from "./ProductElementForm";
import ProductElementList from "./ProductElementList";

type Props = {
  products: IProduct[],
  elements: IElement[],
  allProductElements: IProductElement[],
  loadProducts: () => Promise<void>,
  saveProduct: (event: any) => Promise<void>
  loadElements: () => Promise<void>;
  loadProductElements: () => Promise<void>;
  saveProductElement: (event: any) => Promise<void>;
}

function ManageProductPage({
  products,
  elements,
  allProductElements,
  loadProducts,
  saveProduct,
  loadElements,
  loadProductElements,
  saveProductElement,
  ...props
}: Props) {

  const emptyProductElement: IProductElement[] = [];
  const [product, setProduct] = useState(newProduct);
  const [productElements, setProductElements] = useState(emptyProductElement);

  const [productErrors, setProductErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const [productElement, setProductElement] = useState(newProductElement);
  const [elementErrors, setProductElementErrors] = useState(newError);
  const params = useParams();
  const [showElements, setShowElements] = useState(false);
  const [showElementForm, setShowElementForm] = useState(false);
  const navigate = useNavigate();

  const productId = params.id;

  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          console.error("Loading products failed" + error);
        });
    }
    else {
      const productSelected = productId && products.length > 0 ? getProductById(products, productId) : newProduct;
      if (productSelected)
      {
        setProduct(productSelected);
        setShowElements(productSelected.type === "Standard");
      }
        

      if (productSelected?.id) {
        const elements = getElementsByProductId(productSelected.id, allProductElements);
        if (elements.length > 0)
          setProductElements(elements);
      }
    }
  }, [allProductElements]);

  useEffect(() => {
    if (elements.length === 0) {
      loadElements()
        .catch(error => {
          console.error("Loading elements failed" + error);
        });
    }
  }, [loadElements, elements.length]);

  useEffect(() => {
    if (allProductElements.length === 0) {
      loadProductElements()
        .catch(error => {
          console.error("Loading product elements failed" + error);
        });
    }
  }, [loadProductElements, allProductElements.length]);

  function handleProductChange(event: any) {
    const { name, value } = event.target;

    if (name === "type")
      setShowElements(value === "Standard");
 
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: value
    }));
  }

  function handleProductSave(event: any) {
    event.preventDefault();

    const isNew = !product.id || product.id === 0;

    setSaving(true);
    saveProduct(product)
      .then(() => {
        toast.success("Producto guardado");
        if (isNew) {
          loadProductElements();
        }

        setTimeout(() => {
          setSaving(false);
        }, (700));

        navigate(`/productos`);
      })
      .catch(error => {
        setSaving(false);
        setProductErrors({ ...error, onSave: error.message });
      });
  }

  function handleProductCancel(event: any) {
    setSaving(false);
    navigate(`/productes`);
  }

  function productElementFormIsValid() {
    const newError = { ...elementErrors, onSave: null };

    if (!productElement.elementId) newError.fieldErrors.push({ title: "Elemento es requerido", fieldName: "elementId" })

    setProductElementErrors(newError);
    // Form is valid if the errors object still has no properties
    return newError.fieldErrors.length === 0;
  }

  function handleProductElementChange(event: any) {
    const { name, value } = event.target;
    setProductElement(prevProductElement => ({
      ...prevProductElement,
      [name]: value
    }));

    const newError = { ...elementErrors };

    newError.fieldErrors = newError.fieldErrors.filter(e => e.fieldName !== name)

    setProductElementErrors(newError);
  }

  function handleElementCancel(event: any) {
    setShowElementForm(!showElementForm);
    setSaving(false);
  }

  function handleElementSave(event: any) {
    event.preventDefault();
    if (!productElementFormIsValid()) return;
    setSaving(true);
    saveProductElement(productElement)
      .then(() => {
        toast.success("Elemento guardado");
        setShowElementForm(false);
        setProductElementErrors(newError);
        setSaving(false);
      })
      .catch(error => {
        setSaving(false);
        setProductElementErrors({ ...elementErrors, onSave: error.message });
      });
  }

  async function handleCreateElement() {
    setProductElementErrors(newError);
    setProductElement({ ...newProductElement, productId: product.id ?? 0 });
    setShowElementForm(true)
  }

  async function handleEditElement(productElement: IProductElement) {
    setProductElementErrors(newError);
    setProductElement(productElement);
    setShowElementForm(true)
  }

  async function handleDeleteElement(element: IElement) {
    toast.success("Elemento borrado");
    try {
      if (element.id)
        await deleteProductElement(element.id);

    } catch (error: any) {
      toast.error("Delete failed. " + error.message, { autoClose: false });
    }
  }

  return (
    <div>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <h4>Producto</h4>
          <ProductForm
            product={product}
            errors={productErrors}
            onChange={handleProductChange}
            onSave={handleProductSave}
            onCancel={handleProductCancel}
            saving={saving}
          />
        </Row>
        {showElements && product.id ? <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {showElementForm ? <ProductElementForm
            productElement={productElement}
            elements={elements}
            errors={elementErrors}
            onChange={handleProductElementChange}
            onSave={handleElementSave}
            onCancel={handleElementCancel}
            saving={saving}
          /> :
            <div>
              <h4>Elementos</h4>
              <ProductElementList
                productElements={productElements}
                elements={elements}
                onEditClick={handleEditElement}
                onDeleteClick={handleDeleteElement}
              />
            </div>
          }
        </Row> : <div></div>}
      </Container>
    </div >
  );
}

ManageProductPage.propTypes = {
  products: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  loadProducts: PropTypes.func.isRequired,
  loadElements: PropTypes.func.isRequired,
  saveProduct: PropTypes.func.isRequired,
  loadProductElements: PropTypes.func.isRequired,
  saveProductElement: PropTypes.func.isRequired,

};

export function getProductById(products: IProduct[], id: string): IProduct | null {
  return products.find(product => product.id?.toString() === id) || null;
}

export function getElementsByProductId(productId: number, producteElements: IProductElement[]) {
  return producteElements.filter((productElement) => productElement.productId === productId) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    products: state.products,
    elements: state.elements,
    allProductElements: state.productElements
  };
}

const mapDispatchToProps = {
  loadProducts,
  saveProduct,
  loadElements,
  loadProductElements,
  saveProductElement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProductPage);
