import React from "react";
import PropTypes from "prop-types";
import { IFieldError } from "./types"

type Props = {
  name: string,
  label: string,
  onChange: (event: any) => void | null,
  value: boolean,
  wrapperClass: string
}

const CheckBoxInput = ({
  name,
  label,
  onChange,
  value,
  wrapperClass,
}: Props) => {

  if (!wrapperClass) wrapperClass = "";

  return (
    <div className={wrapperClass}>
      <input
        name={name}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />&nbsp;
      {label && <label htmlFor={name}>{label}</label>}     
    </div>
  );
};

CheckBoxInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

const defaultProps: any = {
  label: "",
  name: "",
  value: false,
  wrapperClass: "",
}
CheckBoxInput.defaultProps = defaultProps;

export default CheckBoxInput;
