import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import authService, { AuthenticationResultStatus } from "./api-authorization/AuthorizeService";

export async function handleResponse(response) {

  await handleGenericResponse(response); 
  if (response.ok){
    return await response.json();
  }
  else
  {
    console.error(response.text())  
  }
}


export async function handleResponseImage(response) {
  await handleGenericResponse(response);
  if (response.ok){
    return response.blob();
  }
}

export async function handleGenericResponse(response){

  if (response.status === 401) {
    //window.location.href = ApplicationPaths.login;
    await login();
  }
  else if (response.status === 403){
    //window.location.href = ApplicationPaths.login;
    await login();
   }
  else if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  else if (response.status === 403) {
    console.error(response);
    throw new Error("Error. Insuficientes permisos para realizar esta acción.");    
  }
  else if (!response.ok){
    throw new Error("Network response was not ok.");
  }
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

 async function logout() {
   try {
    const returnUrl = `${window.location.origin}`
    const result = await authService.completeSignOut(returnUrl);
     switch (result.status) {
       case AuthenticationResultStatus.Redirect:
         break;
       case AuthenticationResultStatus.Success:
         break;
       case AuthenticationResultStatus.Fail:
         window.location.href = ApplicationPaths.logout;
         break;
       default:
         window.location.href = ApplicationPaths.logout;
     }
   }
   catch(e)
   {
    console.error(e);
   }
}

  async function login() {
   const state = {};
   const result =  await authService.signIn(state);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        window.location.reload();
        break;
      case AuthenticationResultStatus.Fail:
        window.location.href = ApplicationPaths.Login;
        break;
      default:
        window.location.href = ApplicationPaths.Login;
    }

  }
