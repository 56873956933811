import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import { useState } from "react";
import { IDistributor } from "./types";
import Button from "../framework/Button";

type Props = {
    distributor: IDistributor,
    handleChange: (event: any) => void,
    handleSave: (event: any) => void,
}

const DistributorForm = ({ 
    distributor,
    handleChange,
    handleSave,
   }: Props) => {

    return (
        <Container>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
                <Col className="col-sm-3">
                    <TextInput
                        name="name"
                        label="Nombre"
                        value={distributor.name}
                        onChange={handleChange}
                    />
                </Col>     
                <Col className="col-sm-1">
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        name="Guardar"
                        actionName="Guardando"
                        onClick={handleSave}
                    ></Button>
                </Col>               
            </Row>  
        </Container>
    );
}


export default DistributorForm;
