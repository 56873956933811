import * as types from "./actionTypes";

function setUserSuccess(applicationUser) {
  return { type: types.SET_USER, applicationUser };
}

function getUserSuccess() {
  return { type: types.GET_USER};
}

export function setUser(applicationUser) {
  return function(dispatch) {
    return dispatch(setUserSuccess(applicationUser));
  };
}

export function getUser() {
  return function(dispatch) {
    return dispatch(getUserSuccess());
  };
}



