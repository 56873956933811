import PropTypes from "prop-types";
import { IReportViewModel } from "../createReport2/types";
import { IParcel } from "../parcels/types";
import { IProduct } from "../products/types";
import { IUser } from "../users/types";
import { Container, Row } from "reactstrap";
import { IRegion } from "../regions/types";
import { IElement } from "./types";
import YearReportForm from "../reports/YearReportForm";

type Props = {
  reports: IReportViewModel[],
  parcels: IParcel[],
  products: IProduct[],
  regions: IRegion[],
  elements: IElement[],
  users: IUser[]
}

const AnualReportList = ({ reports, parcels, products, regions, elements, users }: Props) => {

  const distinctParcelIds = reports.map(r => r.details.parcelId).filter((value, index, self) => self.indexOf(value) === index);

let index = 1;
  return (
    <div className="scroll-container" style={{width: "100%"}}>
      <h5>{reports?.length + " informes"}</h5>
      <Container>
        {

         distinctParcelIds.map(parcelId => {
            return (
              <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
                {index++}                
                <YearReportForm
                  reports={reports.filter(r => r.details.parcelId === parcelId)}
                  parcel={parcels.find(p => p.id === parcelId)!}
                  products={products}
                  regions={regions}
                  users={users}
                ></YearReportForm>
              </Row>             
            );
          }) 
        }
        <Row>
          <div id="divCopyReports" style={{ marginTop: '10px', padding: '3px' }} />          
          <div id="divCopyReportProducts" style={{ marginTop: '10px', padding: '3px' }} />          
        </Row>
      </Container>     
    </div>
  )

}




AnualReportList.propTypes = {
  reports: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired
};

export default AnualReportList;
