import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IFieldError } from "./types"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  name: string,
  label: string,
  onChange: (event: any) => void | null,
  value: Date | null | undefined,
  errors: IFieldError[],
  wrapperClass: string,
  isReadOnly: boolean,
  format: string
}

const DateTimeInput = ({
  name,
  label,
  onChange,
  value,
  errors,
  wrapperClass,
  isReadOnly,
  format
}: Props) => {



  if (!wrapperClass) wrapperClass = "";

  let fielderror = errors?.find(e => e.fieldName === name)

  if (fielderror) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}
      <div>
      <DatePicker 
        selected={value == null ? null : new Date(value?.toString())} 
        popperClassName="wise-font"
        className="form-control wise-font"
        onChange={(date) => onChange({ target: { name, value: date } })} 
        readOnly={isReadOnly}
        dateFormat={format}
      />  
      </div>
      
    
      {fielderror && <div className="alert alert-danger">{fielderror?.title}</div>}
    </div>
  );
};

DateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  format: PropTypes.string.isRequired
};

const defaultProps: any = {
  errors: [],
  label: "",
  name: "",
  value: null,
  wrapperClass: "",
  isReadOnly: false
}
DateTimeInput.defaultProps = defaultProps;

export default DateTimeInput;
