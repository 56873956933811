import * as types from "./actionTypes";
import * as productElementApi from "../../api/productElementApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadProductElementsSuccess(productElements) {
  return { type: types.LOAD_PRODUCT_ELEMENTS_SUCCESS, productElements };
}

export function deleteProductElementOptimistic(productElement) {
  return { type: types.DELETE_PRODUCT_ELEMENT_OPTIMISTIC, productElement };
}

export function createProductElementSuccess(productElement) {
  return { type: types.CREATE_PRODUCT_ELEMENT_SUCCESS, productElement };
}

export function updateProductElementSuccess(productElement) {
  return { type: types.UPDATE_PRODUCT_ELEMENT_SUCCESS, productElement };
}


export function loadProductElements() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return productElementApi
      .getProductElements()
      .then(productElements => {
        dispatch(loadProductElementsSuccess(productElements));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveProductElement(productElement) {
  //eslint-disable-next-line no-unused-vars
  return function (dispatch, getState) {
    dispatch(beginApiCall());
    return productElementApi
      .saveProductElement(productElement)
      .then(savedProductElement => {
        productElement.id
          ? dispatch(updateProductElementSuccess(savedProductElement))
          : dispatch(createProductElementSuccess(savedProductElement));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteProductElement(productElementId) {
  return function (dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteProductElementOptimistic(productElementId));
    return productElementApi.deleteProductElement(productElementId);
  };
}