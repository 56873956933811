import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import SelectInput from "../framework/SelectInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IElement } from "../elements/types";
import { IRegionElement } from "./types";

type Props = {
  regionElement: IRegionElement,
  elements: IElement[],
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const RegionElementForm = ({
  regionElement,
  elements,
  onSave,
  onChange,
  onCancel,
  saving = false,
  errors
}: Props) => {
  return (
    <form onSubmit={onSave} className="form-flex">
      <h4>{regionElement.id ? "Editar" : "Crear"} Elemento</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row">
          <Col className="col-sm-6">
            <TextInput
              name="element"
              label="Elemento"
              value={elements.find(e => e.id === regionElement.elementId)?.name}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-6">
            <TextInput
              name="value"
              label="Valor"
              value={regionElement.value?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-3">
            <TextInput
              name="januaryPercentage"
              label="Enero"
              value={regionElement.januaryPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="februaryPercentage"
              label="Febrero"
              value={regionElement.februaryPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="marchPercentage"
              label="Marzo"
              value={regionElement.marchPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="aprilPercentage"
              label="Abril"
              value={regionElement.aprilPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-3">
            <TextInput
              name="mayPercentage"
              label="Mayo"
              value={regionElement.mayPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="junePercentage"
              label="Junio"
              value={regionElement.junePercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="julyPercentage"
              label="Julio"
              value={regionElement.julyPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="augustPercentage"
              label="Agosto"
              value={regionElement.augustPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-3">
            <TextInput
              name="septemberPercentage"
              label="Septiembre"
              value={regionElement.septemberPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="octoberPercentage"
              label="Octubre"
              value={regionElement.octoberPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="novemberPercentage"
              label="Noviembre"
              value={regionElement.novemberPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
          <Col className="col-sm-3">
            <TextInput
              name="decemberPercentage"
              label="Diciembre"
              value={regionElement.decemberPercentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 3"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-1">
            <Button
              type="submit"
              isDisabled={saving}
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
            ></Button>             
          </Col>
          <Col className="col-sm-1">
            <Button
              type="button"
              isDisabled={saving}
              className="btn btn-secondary"
              onClick={onCancel}
              name="Cancelar"
            ></Button> 
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default RegionElementForm;
