import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function regionReducer(state = initialState.regions, action) {
  switch (action.type) {
    case types.CREATE_REGION_SUCCESS:
      return [...state, { ...action.region }];
    case types.UPDATE_REGION_SUCCESS:
      return state.map(region =>
        region.id === action.region.id ? action.region : region
      );
    case types.LOAD_REGIONS_SUCCESS:
      return action.regions;
    case types.DELETE_REGION_OPTIMISTIC:
      return state.filter(region => region.id !== action.regionId);
    default:
      return state;
  }
}
