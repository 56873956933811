import React from "react";
import PropTypes from "prop-types";
import { IRegion } from "./types";
import { Link, useNavigate } from "react-router-dom";
import Button from "../framework/Button";

type Props = {
  regions: IRegion[],
  onEditClick: (region: IRegion) => void,
  onDeleteClick: (region: IRegion) => void,
}

const RegionList = ({ regions, onEditClick, onDeleteClick }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="scroll-container">
      {regions.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {regions.map(region => {
            return (
              <tr key={region.id}>
                <td>
                  {region.name}
                </td>
                <td>
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/region/' + region.id)}
                    name="Editar"
                  ></Button>   
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}

    </div>
  )

}




RegionList.propTypes = {
  regions: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default RegionList;
