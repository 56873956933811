import * as types from "./actionTypes";
import * as billingParcelApi from "../../api/billingParcelApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadBillingParcelsSuccess(billingParcels) {
  return { type: types.LOAD_BILLING_PARCELS_SUCCESS, billingParcels };
}

export function deleteBillingParcelOptimistic(billingParcel) {
  return { type: types.DELETE_BILLING_PARCEL_OPTIMISTIC, billingParcel };
}

export function createBillingParcelSuccess(billingParcel) {
  return { type: types.CREATE_BILLING_PARCEL_SUCCESS, billingParcel };
}

export function updateBillingParcelSuccess(billingParcel) {
  return { type: types.UPDATE_BILLING_PARCEL_SUCCESS, billingParcel };
}


export function loadBillingParcels() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return billingParcelApi
      .getBillingParcels()
      .then(billingParcels => {
        dispatch(loadBillingParcelsSuccess(billingParcels));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveBillingParcel(billingParcel) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return billingParcelApi
      .saveBillingParcel(billingParcel)
      .then(savedBillingParcel => {
        billingParcel.id
          ? dispatch(updateBillingParcelSuccess(savedBillingParcel))
          : dispatch(createBillingParcelSuccess(savedBillingParcel));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteBillingParcel(billingParcelId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteBillingParcelOptimistic(billingParcelId));
    return billingParcelApi.deleteBillingParcel(billingParcelId);
  };
}