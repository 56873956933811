import TextInput from "../framework/TextInput";
import { newError, newTemplate, newTemplateProduct } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import { useState } from "react";
import { ITemplate, ITemplateProduct, ITemplateVieWModel } from "../templates/types";
import { toast } from "react-toastify";
import { loadTemplates, saveTemplateWithProducts } from "../../redux/actions/templateActions";
import { connect } from "react-redux";
import { IParcelProduct } from "./types";
import PropTypes from "prop-types";

type Props = {
    monthId: number,   
    regionId: number,
    comment: string,
    standardProducts: IParcelProduct[],
    dosisProducts: IParcelProduct[],
    foliarProducts: IParcelProduct[]
    foliarObservations: string,
    saveTemplateWithProducts: (event: any) => Promise<void>;   
    loadTemplates: () => Promise<void>;
}

const ReportTemplateForm2 = ({ 
    monthId, 
    regionId, 
    comment, 
    standardProducts, 
    dosisProducts, 
    foliarProducts, 
    foliarObservations, 
    saveTemplateWithProducts,
    loadTemplates }: Props) => {
    
    const [template, setTemplate] = useState(newTemplate);
    const [templateErrors, setTemplateErrors] = useState(newError);

    function handleTemplateChange(event: any) {
        const { name, value } = event.target;
        setTemplate({ ...template, [name]: value });
    }

    function handleTemplateSave(event: any) {
        if (event)
            event.preventDefault();

        const newTemplate = {
            ...template,
            month: Number(monthId),
            comment,
            foliarObservations,
            regionId: regionId
        };

        const templateProducts = [] as unknown as ITemplateProduct[];

        standardProducts.filter(p => p.isSelected).map(p => 
           templateProducts.push(
               {
                   ...newTemplateProduct,
                   productId: p.productId,
                   group: p.group,
                   dose: p.calculatedValue
               } as ITemplateProduct
           )
        );
        dosisProducts.filter(p => p.isSelected).map(p =>
            templateProducts.push(
                {
                    ...newTemplateProduct,
                    productId: p.productId,
                    group: p.group,
                    dose: p.dose
                } as ITemplateProduct
            )
        );
        foliarProducts.filter(p => p.isSelected).map(p =>
            templateProducts.push(
                {
                    ...newTemplateProduct,
                    productId: p.productId,
                    group: p.group,
                    dose: p.doseTotal
                } as ITemplateProduct
            )
        );

        const newTemplateViewModel = {

            template: newTemplate,
            products: templateProducts
        } as ITemplateVieWModel;

        if (!templateFormIsValid(newTemplateViewModel.template)) return;

        saveTemplateWithProducts(newTemplateViewModel)
            .then(() => {
                toast.success("Plantilla creada");
                loadTemplates();
            })
            .catch(error => {
                toast.error("Error creando plantilla");
                throw error;
            });
    }

    function templateFormIsValid(template: ITemplate) {
        const newTemplateError = {...newError};
        newTemplateError.fieldErrors = [];

        if (!template.name) newTemplateError.fieldErrors.push({ title: "Nombre es requerido", fieldName: "name" })
        if (!template.month) newTemplateError.fieldErrors.push({ title: "Mes es requerido", fieldName: "name" })
        if (!template.regionId) newTemplateError.fieldErrors.push({ title: "Region es requerido", fieldName: "name" })

        setTemplateErrors(newTemplateError);
        // Form is valid if the errors object still has no properties
        return newTemplateError.fieldErrors.length === 0;
    }

    return (
        <Container>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>                
                <Col className="col-sm-3">
                    <TextInput
                        name="name"
                        label="Nombre"
                        value={template.name}
                        errors={templateErrors.fieldErrors}
                        onChange={handleTemplateChange}
                    />
                </Col>                
                <Col className="col-sm-2">
                    <Button
                        type="button"
                        className="btn btn-primary"
                        name={"Crear plantilla"}
                        onClick={handleTemplateSave}
                    ></Button>
                </Col>
            </Row>            
        </Container>
    );
}

ReportTemplateForm2.propTypes = {
    monthId: PropTypes.number.isRequired,
    regionId: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    foliarObservations: PropTypes.string.isRequired,
    standardProducts: PropTypes.array.isRequired,
    dosisProducts: PropTypes.array.isRequired,
    foliarProducts: PropTypes.array.isRequired,
    saveTemplateWithProducts: PropTypes.func.isRequired,
    loadTemplates: PropTypes.func.isRequired,
};

const mapDispatchToProps = {   
    saveTemplateWithProducts,
    loadTemplates,
};


function mapStateToProps(state: any, ownProps: any) {
    return {      
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTemplateForm2);

