import React, { useState } from "react";
import PropTypes from "prop-types";
import { IProduct } from "../products/types";
import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { GetAllGroups } from "../utils/constants";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { ITemplateProduct } from "./types";
import Button from "../framework/Button";

type Props = {
  templateProducts: ITemplateProduct[],
  products: IProduct[],
  onChange: (templateProduct: ITemplateProduct, event: any) => void,
  onDelete: (templateProduct: ITemplateProduct, event: any) => void,
}

const TemplateProductList = ({ templateProducts, products, onChange, onDelete }: Props) => {

  const [errors] = useState(newError);
  const productGroups = GetAllGroups()

  const standardProducts = templateProducts.filter(tp => products.some(p => p.id === tp.productId && p.type === "Standard" && (tp.group === "Grupo A" || tp.group === "Grupo B")));
  const dosisHaProducts = templateProducts.filter(tp => products.find(p => p.id === tp.productId && p.type === "Dosis por Hectárea" && tp.group === "Grupo B"));
  const dosisPlantaProducts = templateProducts.filter(tp => products.find(p => p.id === tp.productId && p.type === "Dosis por Hectárea" && tp.group === "Grupo E"));
  const foliarProducts = templateProducts.filter(tp => tp.group === "Grupo F");

  return (
    <div>
      {standardProducts.length > 0 ?
        <div>
          <h5>Productos Standard</h5>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Producto</th>
                <th style={{ width: '20%' }}>Grupo</th>
                {<th style={{ width: '25%' }}>KG x Ha</th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {standardProducts.map(templateProduct => {
                return (
                  <tr key={templateProduct.id}>
                    <td>
                      {products.find(e => e.id === templateProduct.productId)?.name}
                    </td>
                    <td>
                      <SelectInput
                        name="group"
                        value={templateProduct.group}
                        defaultOption="Seleccione Grupo"
                        options={productGroups.map(pg => ({
                          value: pg.name,
                          text: pg.name
                        } as SelectOption))}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <TextInput
                        name="dose"
                        value={templateProduct.dose?.toString()}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td> 
                    <td>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        name="Borrar"
                        onClick={(e) => onDelete(templateProduct, e)}
                      ></Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        : <div />}

      {dosisHaProducts.length > 0 ? 
      <div>
          <h5>Productos Dosis por Hectárea</h5>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Producto</th>
                <th style={{ width: '20%' }}>Grupo</th>
                <th style={{ width: '25%' }}>Dosis</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dosisHaProducts.map(templateProduct => {
                return (
                  <tr key={templateProduct.id}>
                    <td>
                      {products.find(e => e.id === templateProduct.productId)?.name}
                    </td>
                    <td>
                      <SelectInput
                        name="group"
                        value={templateProduct.group}
                        defaultOption="Seleccione Grupo"
                        options={productGroups.map(pg => ({
                          value: pg.name,
                          text: pg.name
                        } as SelectOption))}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <TextInput
                        name="dose"
                        value={templateProduct.dose?.toString()}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        name="Borrar"
                        onClick={(e) => onDelete(templateProduct, e)}
                      ></Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
      </div>
      : <div />}


      {dosisPlantaProducts.length > 0 ?
        <div>
          <h5>Productos Dosis por Planta</h5>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Producto</th>
                <th style={{ width: '20%' }}>Grupo</th>
                <th style={{ width: '25%' }}>Dosis</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dosisPlantaProducts.map(templateProduct => {
                return (
                  <tr key={templateProduct.id}>
                    <td>
                      {products.find(e => e.id === templateProduct.productId)?.name}
                    </td>
                    <td>
                      <SelectInput
                        name="group"
                        value={templateProduct.group}
                        defaultOption="Seleccione Grupo"
                        options={productGroups.map(pg => ({
                          value: pg.name,
                          text: pg.name
                        } as SelectOption))}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <TextInput
                        name="dose"
                        value={templateProduct.dose?.toString()}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        name="Borrar"
                        onClick={(e) => onDelete(templateProduct, e)}
                      ></Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        : <div />}

      {foliarProducts.length > 0 ?
        <div>
          <h5>Productos Tratamiento Foliar</h5>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Producto</th>
                <th style={{ width: '20%' }}>Grupo</th>
                <th style={{ width: '25%' }}>Dosis</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {foliarProducts.map(templateProduct => {
                return (
                  <tr key={templateProduct.id}>
                    <td>
                      {products.find(e => e.id === templateProduct.productId)?.name}
                    </td>
                    <td>
                      <SelectInput
                        name="group"
                        value={templateProduct.group}
                        defaultOption="Seleccione Grupo"
                        options={productGroups.map(pg => ({
                          value: pg.name,
                          text: pg.name
                        } as SelectOption))}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <TextInput
                        name="dose"
                        value={templateProduct.dose?.toString()}
                        onChange={(e) => onChange(templateProduct, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        name="Borrar"
                        onClick={(e) => onDelete(templateProduct, e)}
                      ></Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        : <div />}

    </div>
  );
}

TemplateProductList.propTypes = {
  templateProducts: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TemplateProductList;
