import React from "react";
import PropTypes from "prop-types";
import { IFieldError } from "./types"

type Props = {
  name: string,
  label: string,
  onChange?: (event: any) => void | null,
  placeholder?: string,
  value: any,
  errors: IFieldError[],
  wrapperClass: string,
  isReadOnly: boolean
}

const TextArea = ({
  name,
  label,
  onChange,
  placeholder = "",
  value,
  errors,
  wrapperClass,
  isReadOnly
}: Props) => {

  if (!wrapperClass) wrapperClass = "";

  let fielderror = errors?.find(e => e.fieldName === name)

  if (fielderror) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea        
        name={name}
        className="form-control wise-font"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={isReadOnly}              
        rows={10} 
      />
      {fielderror && <div className="alert alert-danger">{fielderror?.title}</div>}
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps: any = {
  errors: [],
  label: "",
  name: "",
  value: "",
  wrapperClass: "",
  placeholder: "",
  isReadOnly: false
}
TextArea.defaultProps = defaultProps;

export default TextArea;
