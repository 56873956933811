import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router-dom";
import { IDistributor } from "./types";
import Button from "../framework/Button";
import { loadDistributors, saveDistributor } from "../../redux/actions/distributorActions";
import DistributorList from "./DistributorList";
import { Container, Row } from "reactstrap";

type Props = {
  distributors: IDistributor[],
  loadDistributors: () => Promise<void>,
  loadRegions: () => Promise<void>,
}

function DistributorPage({
  distributors,
  loadDistributors,
  loadRegions,
  ...props
}: Props) {

  const [redirectToAddDistributorPage, setRedirectToAddDistributorPage] = useState(false);

  useEffect(() => {
    if (distributors.length === 0) {
      loadDistributors()
        .catch(error => {
          console.error("Loading distributors failed" + error);
        });
    }
  }, [loadDistributors, distributors.length]);

  return (
    <>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {redirectToAddDistributorPage && <Navigate to="/distribuidor" />}
          <h4>Distribuidores</h4>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => setRedirectToAddDistributorPage(true)}
            name="  Crear Distribuidor"
          ></Button>
          {
            <DistributorList
              distributors={distributors}
            />
          }
        </Row>
      </Container>
    </>
  );
}

DistributorPage.propTypes = {
  distributors: PropTypes.array.isRequired,
  loadDistributors: PropTypes.func.isRequired,
};

export function getDistributorById(distributors: IDistributor[], id: string): IDistributor | null {
  return distributors.find(distributor => distributor.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    distributors: state.distributors,
  };
}

const mapDispatchToProps = {
  loadDistributors,
  saveDistributor
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorPage);
