import React, { useState } from "react";
import PropTypes from "prop-types";
import { IProduct } from "../products/types";
import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { IDistributorProduct } from "./types";
import Button from "../framework/Button";

type Props = {
  distributorProducts: IDistributorProduct[],
  products: IProduct[],
  onChange: (distributorProduct: IDistributorProduct, event: any) => void,
  onDelete: (distributorProduct: IDistributorProduct, event: any) => void,
}

const DistributorProductList = ({ distributorProducts, products, onChange, onDelete }: Props) => {

  const [errors] = useState(newError);

  return (
    <div>
    <h5>Productos</h5>
    <table className="table">
      <thead>
        <tr>
          <th style={{ width: '25%' }}>Producto</th>
          <th style={{ width: '20%' }}>Precio</th>
          {<th style={{ width: '25%' }}>Comisión</th>}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {distributorProducts.map(distributorProduct => {
          return (
            <tr key={distributorProduct.id}>
              <td>
                {products.find(e => e.id === distributorProduct.productId)?.name}
              </td>
              <td>
                <TextInput
                  name="price"
                  value={distributorProduct.price?.toString()}
                  onChange={(e) => onChange(distributorProduct, e)}
                  errors={errors.fieldErrors}
                  wrapperClass={"col 1"}
                />
              </td>
              <td>
                <TextInput
                  name="percentage"
                  value={distributorProduct.percentage?.toString()}
                  onChange={(e) => onChange(distributorProduct, e)}
                  errors={errors.fieldErrors}
                  wrapperClass={"col 1"}
                />
              </td> 
              <td>
                <Button
                  type="button"
                  className="btn btn-primary"
                  name="Borrar"
                  onClick={(e) => onDelete(distributorProduct, e)}
                ></Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
  );
}

DistributorProductList.propTypes = {
  distributorProducts: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DistributorProductList;
