import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadBilling, saveBilling } from "../../redux/actions/billingActions";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router-dom";
import { IBilling } from "./types";
import BillingList from "./BillingList";
import Button from "../framework/Button";
import { Container, Row } from "reactstrap";

type Props = {
  billing: IBilling[],
  loadBilling: () => Promise<void>,
}

function BillingsPage({
  billing,
  loadBilling,
  ...props
}: Props) {

  const [redirectToAddBillingPage, setRedirectToAddBillingPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (billing.length === 0) {
      loadBilling()
        .catch(error => {
          console.error("Loading billing failed" + error);
        });
    }
  }, [loadBilling, billing.length]);

  function handleDeleteBilling(billing: IBilling) {
    console.log("Don´t do that!");
  }

  function handleEditBilling(billing: IBilling) {
    navigate(`/factura/${billing?.id}`);
  }

  return (
    <>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {redirectToAddBillingPage && <Navigate to="/factura" />}
          <h4>Facturas</h4>
          <Button
            type="button"
            className="btn btn-primary add-course"
            onClick={() => setRedirectToAddBillingPage(true)}
            name="Crear"
          ></Button>
          {
            <BillingList
              onDeleteClick={handleDeleteBilling}
              onEditClick={handleEditBilling}
              billing={billing}
            />
          }
        </Row>
      </Container>
    </>
  );
}

BillingsPage.propTypes = {
  billing: PropTypes.array.isRequired,
  loadBilling: PropTypes.func.isRequired,
};

export function getBillingById(billing: IBilling[], id: string): IBilling | null {
  return billing.find(b => b.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    billing: state.billing,
  };
}

const mapDispatchToProps = {
  loadBilling,
  saveBilling
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingsPage);
