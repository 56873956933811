import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function distributorReducer(state = initialState.distributors, action) {
  switch (action.type) {
    case types.CREATE_DISTRIBUTOR_SUCCESS:
      return [...state, { ...action.distributor }];
    case types.UPDATE_DISTRIBUTOR_SUCCESS:
      return state.map(distributor =>
        distributor.id === action.distributor.id ? action.distributor : distributor
      );
    case types.LOAD_DISTRIBUTORS_SUCCESS:
      return action.distributors;
    case types.DELETE_DISTRIBUTOR_OPTIMISTIC:
      return state.filter(distributor => distributor.id !== action.distributorId);
    default:
      return state;
  }
}
