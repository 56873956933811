import { IRegionElement } from "../components/regions/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/regionElement";


export async function getRegionElements(): Promise<IRegionElement[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveRegionElement(regionElement: IRegionElement): Promise<IRegionElement> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: regionElement.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(regionElement)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteRegionElement(regionElementId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + regionElementId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
