import { Col, Container, Row } from "reactstrap";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IProduct } from "./types";

type Props = {
  product: IProduct,
  onChange: (event: any) => void,
  errors: IErrors
}

const ProductDosesForm = ({
  product,
  onChange,
  errors
}: Props) => {
  return (
    <Container>
      <h4>Dosis</h4>      
      <Row className="container-row">
        <Col className="col-sm-3">
          <TextInput
            name="januaryDose"
            label="Enero"
            value={product.januaryDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 6"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="februaryDose"
            label="Febrero"
            value={product.februaryDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 6"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="marchDose"
            label="Marzo"
            value={product.marchDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 6"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="aprilDose"
            label="Abril"
            value={product.aprilDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 6"}
          />
        </Col>
      </Row>
      <Row className="container-row">
        <Col className="col-sm-3">
          <TextInput
            name="mayDose"
            label="Mayo"
            value={product.mayDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 6"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="junDose"
            label="Junio"
            value={product.junDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="julyDose"
            label="Julio"
            value={product.julyDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="augustDose"
            label="Agosto"
            value={product.augustDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
      </Row>
      <Row className="container-row">
        <Col className="col-sm-3">
          <TextInput
            name="septemberDose"
            label="Septiembre"
            value={product.septemberDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="octoberDose"
            label="Octubre"
            value={product.octoberDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="novemberDose"
            label="Noviembre"
            value={product.novemberDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
        <Col className="col-sm-3">
          <TextInput
            name="decemberDose"
            label="Diciembre"
            value={product.decemberDose?.toString()}
            onChange={onChange}
            errors={errors.fieldErrors}
            wrapperClass={"col 3"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDosesForm;
