import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IParcel } from "../parcels/types";
import { IBillingParcel } from "./types";

type Props = {
  billingParcel: IBillingParcel,
  parcels: IParcel[],
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const BillingParcelForm = ({
  billingParcel,
  parcels,
  onSave,
  onChange,
  onCancel,
  saving = false,
  errors
}: Props) => {
  return (
    <form onSubmit={onSave} className="form-flex">
      <h4>Editar Factura Parcela</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row">
          <Col className="col-sm-4">
            <TextInput
              name="parcel"
              label="Parcela"
              value={parcels.find(e => e.id === billingParcel.parcelId)?.name}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 4"}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-4">
            <TextInput
              name="amount"
              label="Cantidad"
              value={billingParcel.amount?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 4"}             
            />
          </Col>
          <Col className="col-sm-4">
            <TextInput
              name="notes"
              label="Notas"
              value={billingParcel.notes?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 4"}
            />
          </Col>    
        </Row>    
        <Row className="container-row">
          <Col className="col-sm-1">
            <Button
              type="submit"
              isDisabled={saving}
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
            ></Button>             
          </Col>
          <Col className="col-sm-1">
            <Button
              type="button"
              isDisabled={saving}
              className="btn btn-secondary"
              onClick={onCancel}
              name="Cancelar"
            ></Button> 
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default BillingParcelForm;
