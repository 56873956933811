import { IUser } from "./types";
import PropTypes from 'prop-types'
import Button from "../framework/Button";
import { IDistributor } from "../distributors/types";
import { ROLE_ADMIN } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import AdminContainer from "../framework/AdminContainer";

type Props = {
  users: IUser[],
  distributors: IDistributor[],
}

const UserList = ({ users, distributors }: Props) => {

  const navigate = useNavigate();

  return (
    <div className="scroll-container">
      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre</th>
            <AdminContainer>
              <th>Usuario Técnico</th>
              <th>Clasificación Técnico</th>
              <th>Distribuidor</th>
            </AdminContainer>
            <th />
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            return (
              <tr key={user.id}>
                <td>
                  {user.id}
                </td>
                <td>
                  {user.name}
                </td>
                <AdminContainer>
                  <td>{user.technicalName}</td>
                  <td>{user.technicalClasification}</td>
                  <td>{user.distributorId ? distributors.find(d => d.id === user.distributorId)?.name : ""}</td>
                </AdminContainer>
                <td>
                  <Button
                    type="submit"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/usuario/' + user.id)}
                    name="Ver"
                  ></Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}



UserList.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UserList;
