import { IMonth, IReportViewModel } from "./types";
import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import Button from "../framework/Button";
import { IParcel } from "../parcels/types";
import { useState } from "react";
import { IUser } from "../users/types";
import CheckBoxInput from "../framework/CheckBoxInput";
import { Link } from "react-router-dom";

type Props = {
    report: IReportViewModel,
    months: IMonth[],
    parcel: IParcel,
    user: IUser,
    isGenerateButtonDisabled: boolean,
    handleMonthChange: (event: any) => void,
    handleGenerateButton: (event: any) => void,
}

const ReportInfo2 = ({ report,
    months,
    parcel,
    user,
    handleMonthChange,
    handleGenerateButton,
    isGenerateButtonDisabled }: Props) => {

    const [errors] = useState(newError);

    return (
        <div className="scroll-container">
            <Container>
                <Row className="container-row">
                    <Col className="col-sm-6">
                        <SelectInput
                            name="monthId"
                            label="Mes"
                            value={report.details.month ?? ""}
                            defaultOption=""
                            options={months.map(month => ({
                                value: month.id,
                                text: month.name
                            } as SelectOption))}
                            onChange={handleMonthChange}
                            errors={errors.fieldErrors}
                            wrapperClass={""}
                        />
                    </Col>
                    <Col className="col-sm-6">
                        <TextInput
                            name="name"
                            label="Usuario"
                            value={user.name}
                            isReadOnly={true}
                        />
                    </Col>
                </Row>
                <Row className="container-row">
                    <Col className="col-sm-6">
                        <TextInput
                            name="area"
                            label="Superficie"
                            value={parcel.area?.toString()}
                            isReadOnly={true}
                        />
                    </Col>
                    <Col className="col-sm-6">
                        <TextInput
                            name="plantsNumber"
                            label="Num. Plantas"
                            value={parcel.plantsNumber?.toString()}
                            isReadOnly={true}
                        />
                    </Col>
                </Row>
                <Row className="container-row">
                    <Col className="col-sm-6">
                        <Button
                            type="button"
                            isDisabled={isGenerateButtonDisabled}
                            className="btn btn-primary"
                            name={report.details.id ? "Editar Informe" : "Generar Informe"}
                            onClick={handleGenerateButton}
                        ></Button>
                    </Col>
                    <Col className="col-sm-3">
                        <Link to={'/usuario/' + user.id}>Usuario</Link>
                    </Col>
                    <Col className="col-sm-3">
                        <Link to={'/parcela/' + parcel.id}>Parcela</Link>
                    </Col>
                </Row>

            </Container>
        </div>

    );
}


export default ReportInfo2;
