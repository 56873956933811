import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import PropTypes from "prop-types";
import { getUser } from "../../redux/actions/userRoleActions";
import { connect } from 'react-redux';
import { IApplicationUser } from '../../components/menu/types';

type Props = {
  element: JSX.Element | null,
  path: string,
  roles: string[],
  applicationUser: IApplicationUser
};

function AuthorizeRoute
  ({ element,
    path,
    roles,
    applicationUser,
    ...props }: Props) {
  const [ready, setReady] = useState(false);
  const [readyAndAuthtenticated, setReadyAndAuthtenticated] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {

    setReady(!!applicationUser?.loaded);     
    if (applicationUser?.loaded) {      
      if (roles && roles.length) {        
        if (roles.includes(applicationUser.roleName ?? "")) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }          
      }       
    }   
  }, [applicationUser, authenticated, roles]);


  if (ready){
    if (authenticated){
      return element;
    }
    else{
      const redirectUrl = `${ApplicationPaths.Login}`;
      return <Navigate replace to={redirectUrl}></Navigate>;
    }
  }
  else{
    return null;
  }

}

function mapStateToProps(state: any, ownProps: any) {
  return {
    applicationUser: state.applicationUser,
  };
}


export default connect(mapStateToProps)(AuthorizeRoute);