import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadParcels, saveParcel } from "../../redux/actions/parcelsActions";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { IParcel } from "./types";
import ParcelList from "./ParcelList";
import { IRegion } from "../regions/types";
import { IUser } from "../users/types";
import { loadRegions } from "../../redux/actions/regionsActions";
import { loadUsers } from "../../redux/actions/usersActions";
import { Container, Row } from "reactstrap";

type Props = {
  parcels: IParcel[],
  regions: IRegion[],
  users: IUser[],
  loadParcels: () => Promise<void>,
  loadRegions: () => Promise<void>,
  loadUsers: () => Promise<void>,
}

function ParcelsPage({
  parcels,
  regions,
  users,
  loadParcels,
  loadRegions,
  loadUsers,
  ...props
}: Props) {

  const [redirectToAddParcelPage, setRedirectToAddParcelPage] = useState(false);

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          console.error("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
  }, [loadRegions, regions.length]);


  useEffect(() => {
    if (users.length === 0) {
      loadUsers()
        .catch(error => {
          console.error("Loading users failed" + error);
        });
    }
  }, [loadUsers, users.length]);


  return (
    <>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card  wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {redirectToAddParcelPage && <Navigate to="/parcela" />}
          <h4>Parcelas</h4>
          {
            <ParcelList
              parcels={parcels}
              regions={regions}
              users={users}
            />
          }
        </Row>
      </Container>
    </>
  );
}

ParcelsPage.propTypes = {
  parcels: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  loadParcels: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,

};

function mapStateToProps(state: any, ownProps: any) {
  return {
    parcels: state.parcels,
    regions: state.regions,
    users: state.users
  };
}

const mapDispatchToProps = {
  loadParcels,
  loadRegions,
  loadUsers,
  saveParcel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParcelsPage);
