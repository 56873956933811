import React from "react";
import PropTypes from "prop-types";
import { IParcel } from "./types";
import { useNavigate } from "react-router-dom";
import { IRegion } from "../regions/types";
import { IUser } from "../users/types";
import Button from "../framework/Button";
import { ROLE_ADMIN } from "../utils/constants";
import AdminContainer from "../framework/AdminContainer";

type Props = {
  parcels: IParcel[],
  regions: IRegion[],
  users: IUser[]
}

const ParcelList = ({ parcels, regions, users }: Props) => {

  const navigate = useNavigate();
  return (
    <div className="scroll-container">
      {parcels.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre Parcela</th>
            <th>Usuario</th>
            <th>Region</th>
            <AdminContainer>
              <th>Facturación</th>
            </AdminContainer>

            <th style={{ width: '5%' }} />
            <th style={{ width: '5%' }} />
          </tr>
        </thead>
        <tbody>
          {parcels.map(parcel => {
            return (
              <tr key={parcel.id}>
                <td>
                  {parcel.id}
                </td>
                <td>
                  {parcel.name}
                </td>
                <td>
                  {users.find(u => u.id === parcel.userId)?.name}
                </td>
                <td>
                  {regions.find(r => r.id === parcel.regionId)?.name}
                </td>
                <AdminContainer>
                  <td>
                    {parcel.billingMonthAmount}
                  </td>
                </AdminContainer>
                <td>
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/parcela/' + parcel.id)}
                    name="Ver"
                  ></Button>
                </td>
                <td>
                  {
                  (parcel.billingMonthAmount ?? 0 )> 0 &&
                  <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => navigate('/parcela/' + parcel.id + '/crearinforme/')}
                  name="Calcular"
                  ></Button>
                  }
                  
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}
    </div>
  );

}

ParcelList.propTypes = {
  parcels: PropTypes.array.isRequired,
};

export default ParcelList;
