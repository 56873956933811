import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadTasks, saveTask } from "../../redux/actions/tasksActions";
import { loadParcels } from "../../redux/actions/parcelsActions";
import { loadUsers } from "../../redux/actions/usersActions";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { ITask } from "./types";
import { newError, newTask } from "../../tools/mockData";
import TaskForm from "./TaskForm";
import { IParcel } from "../parcels/types";
import { IUser } from "../users/types";
import { Container, Row } from "reactstrap";

type Props = {
  tasks: ITask[],
  parcels: IParcel[],
  users: IUser[],
  loadTasks: () => Promise<void>,
  loadUsers: () => Promise<void>,
  loadParcels: () => Promise<void>,
  saveTask: (event: any) => Promise<void>
}

function ManageTaskPage({
  tasks,
  parcels,
  users,
  loadTasks,
  loadUsers,
  loadParcels,
  saveTask,
  ...props
}: Props) {


  const [task, setTask] = useState<ITask>(newTask);
  const [errors, setErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const taskId = params.id;

  useEffect(() => {
    if (tasks == null || tasks?.length === 0) {
      loadTasks()
        .catch(error => {
          console.error("Loading tasks failed" + error);
        });
    }
  }, [loadTasks]);

  useEffect(() => {
    if (users?.length === 0) {
      loadUsers()
        .catch(error => {
          console.error("Loading users failed" + error);
        });
    }
  }, [loadUsers]);

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          console.error("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);

  useEffect(() => {
    if (tasks?.length > 0) {
      const taskSelected = taskId && tasks.length > 0 ? getTaskById(tasks, taskId) : newTask;
      if (taskSelected)
        setTask(taskSelected);
    }
  }, [tasks, taskId]);

  function handleCancel(event: any) {
    setSaving(false);
    navigate(`/tareas`);
  }

  return (
    <Container>
      <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
      <TaskForm
        tasks={tasks}
        taskId={Number(taskId)}
        parcels={parcels}
        errors={errors}        
        onCancel={handleCancel}
        onNotify={(action: string) => navigate(`/tareas`)}
        saving={saving}
      />
      </Row>
    </Container>
  );
}

ManageTaskPage.propTypes = {
  tasks: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  loadTasks: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadParcels: PropTypes.func.isRequired,
  saveTask: PropTypes.func.isRequired

};

export function getTaskById(tasks: ITask[], id: string): ITask | null {
  return tasks.find(task => task.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    tasks: state.tasks,
    users: state.users,
    parcels: state.parcels,
  };
}

const mapDispatchToProps = {
  loadTasks,
  loadParcels,
  loadUsers,
  saveTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTaskPage);
