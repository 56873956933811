import React from "react";
import PropTypes from "prop-types";
import { IDistributor } from "./types";
import { useNavigate } from "react-router-dom";
import Button from "../framework/Button";

type Props = {
  distributors: IDistributor[],
}

const DistributorList = ({ distributors }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="scroll-container">
      {distributors.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {distributors.map(distributor => {
            return (
              <tr key={distributor.id}>
                <td>
                  {distributor.name}
                </td>                             
                <td>
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/distribuidor/' + distributor.id)}
                    name="Editar"
                  ></Button>   
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}

    </div>
  )

}




DistributorList.propTypes = {
  distributors: PropTypes.array.isRequired,
};

export default DistributorList;
