export function hasPermission (roles:string[], userRole:string | undefined) 
{
    if (roles.length === 0) {
        return true;
    }
    else 
    {
        if (userRole) {
            return roles.includes(userRole);
        }
        else {
            return false;
        }
    }
};
