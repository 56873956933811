import * as types from "./actionTypes";
import * as reportApi from "../../api/reportApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadReportsSuccess(reports) {
  return { type: types.LOAD_REPORTS_SUCCESS, reports };
}

export function createReportSuccess(report) {
  return { type: types.CREATE_REPORT_SUCCESS, report };
}

export function deleteReportOptimistic(reportId) {
  return { type: types.DELETE_REPORT_OPTIMISTIC, reportId };
}

export function loadParcelReports(parcelId) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return reportApi
      .getReportByParcelId(parcelId)
      .then(reports => {
        dispatch(loadReportsSuccess(reports));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadReportById(reportId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return reportApi
      .getReportById(reportId)
      .then(reports => {
        dispatch(loadReportsSuccess(reports));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function createReport(report) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return reportApi
      .createReport(report)
      .then(savedReport => {
        dispatch(createReportSuccess(savedReport));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteReport(reportId) {
  return function (dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteReportOptimistic(reportId));
    return reportApi.deleteReport(reportId);
  };
}