import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadBilling, saveBilling } from "../../redux/actions/billingActions";
import { loadParcels } from "../../redux/actions/parcelsActions";
import { loadBillingParcels, saveBillingParcel } from "../../redux/actions/billingParcelAction";
import PropTypes from "prop-types";
import BillingForm from "./BillingForm";
import { toast } from "react-toastify";
import {  useNavigate, useParams } from "react-router-dom";
import { IBilling, IBillingHistory, IBillingParcel } from "./types";
import { newError, newBilling, newBillingParcel } from "../../tools/mockData";
import { Container, Row } from "reactstrap";
import { IParcel } from "../parcels/types";
import BillingParcelForm from "./BillingParcelForm";
import BillingParcelList from "./BillingParcelList";
import * as billingApi from "../../api/billingApi";
import BillingHistoryList from "./BillingHistory";
import Button from "../framework/Button";


type Props = {
  billingRecords: IBilling[],
  parcels: IParcel[],
  allBillingParcels: IBillingParcel[],
  loadBilling: () => Promise<void>,
  saveBilling: (event: any) => Promise<void>
  loadParcels: () => Promise<void>;
  loadBillingParcels: () => Promise<void>;
  saveBillingParcel: (event: any) => Promise<void>;
}

function ManageBillingPage({
  billingRecords,
  parcels,
  allBillingParcels,
  loadBilling,
  saveBilling,
  loadParcels,
  loadBillingParcels,
  saveBillingParcel,
  ...props
}: Props) {

  const emptyBillingParcel: IBillingParcel[] = [];
  const emptyBillingHistory: IBillingHistory[] = [];
  const [billing, setBilling] = useState(newBilling);
  const [billingParcels, setBillingParcels] = useState(emptyBillingParcel);
  const [billingHistory, setBillingHistory] = useState(emptyBillingHistory);

  const [billingErrors, setBillingErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const [billingParcel, setBillingParcel] = useState(newBillingParcel);
  const [parcelErrors, setBillingParcelErrors] = useState(newError);
  const params = useParams();
  const [showParcelForm, setShowParcelForm] = useState(false);
  const navigate = useNavigate();
  const [showHistory, setShowHistory] = useState(false);


  const billingId = params.id;

  useEffect(() => {
    if (billingId && allBillingParcels && billingRecords)
    {
      const billingSelected = billingId && billingRecords.length > 0 ? getBillingById(billingRecords, billingId) : newBilling;
      if (billingSelected)
        setBilling(billingSelected);
  
      if (billingSelected?.id) {
        const parcels = getParcelsByBillingId(billingSelected.id, allBillingParcels);
        if (parcels.length > 0)
          setBillingParcels(parcels);        
      }
      else {
        setBillingParcels(emptyBillingParcel);
      }
    }
    
  }, [allBillingParcels, billingId, billingRecords]);

  useEffect(() => {
    if (!billingRecords || billingRecords.length === 0) {
      loadBilling()
        .catch(error => {
          console.error("Loading billing failed" + error);
        });
    }
  }, [billingRecords, loadBilling]);

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          console.error("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);

  useEffect(() => {
    if (allBillingParcels.length === 0) {
      loadBillingParcels()
        .catch(error => {
          console.error("Loading billing parcels failed" + error);
        });
    }
  }, [loadBillingParcels, allBillingParcels.length]);

  useEffect(() => {
    if (showHistory && billingId) {
      getHistory(Number(billingId));      
    }
  }, [showHistory]);

  function handleBillingChange(event: any) {
    const { name, value } = event.target;
    setBilling(prevBilling => ({
      ...prevBilling,
      [name]: value
    }));
  }

  function handleBillingSave(event: any) {
    event.preventDefault();

    const isNew = !billing.id || billing.id === 0;

    setSaving(true);
    saveBilling(billing)
      .then(() => {
        if (isNew) {
          loadBillingParcels();
        }

        toast.success("Facturación guardada");
        setTimeout(() => {
          setSaving(false);
        }, (700));

        navigate(`/facturas`);
      })
      .catch(error => {
        setSaving(false);
        setBillingErrors({ ...error, onSave: error.message });
      });
  }


  function billingParcelFormIsValid() {
    const newError = { ...parcelErrors, onSave: null };

    if (!billingParcel.parcelId) newError.fieldErrors.push({ title: "Parcel es requerido", fieldName: "parcelId" })

    setBillingParcelErrors(newError);
    // Form is valid if the errors object still has no properties
    return newError.fieldErrors.length === 0;
  }

  function handleBillingParcelChange(event: any) {
    const { name, value } = event.target;
    setBillingParcel(prevBillingParcel => ({
      ...prevBillingParcel,
      [name]: value
    }));

    const newError = { ...parcelErrors };

    newError.fieldErrors = newError.fieldErrors.filter(e => e.fieldName !== name)

    setBillingParcelErrors(newError);
  }

  function handleParcelCancel(event: any) {
    setShowParcelForm(!showParcelForm);
    setSaving(false);
  }

  function handleParcelSave(event: any) {
    event.preventDefault();
    if (!billingParcelFormIsValid()) return;
    setSaving(true);
    saveBillingParcel(billingParcel)
      .then(() => {
        toast.success("Guardado");
        loadBilling();
        setShowParcelForm(false);
        setBillingParcelErrors(newError);
        setSaving(false);        
      })
      .catch(error => {
        setSaving(false);
        setBillingParcelErrors({ ...parcelErrors, onSave: error.message });
      });
  }

  async function handleEditParcel(billingParcel: IBillingParcel) {
    setBillingParcelErrors(newError);
    setBillingParcel(billingParcel);
    setShowParcelForm(true)
  }

  async function handleDeleteParcel(parcel: IParcel) {
    console.log("Don´t do that!");
  }

  function getHistory(billingId: number) {
    return billingApi
      .getHistory(billingId)
      .then(historyRecords => {       
        setBillingHistory(historyRecords);
      })
      .catch(error => {
        toast.error("Error retrieving history");
        
        throw error;
      });
  }


  return (
    <div>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <h4>Factura</h4>
          <BillingForm
            billing={billing}
            errors={billingErrors}
            onChange={handleBillingChange}
            onSave={handleBillingSave}
            saving={saving} 
          />
        </Row>
        {billing.id ? <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {showParcelForm ? <BillingParcelForm
            billingParcel={billingParcel}
            parcels={parcels}
            errors={parcelErrors}
            onChange={handleBillingParcelChange}
            onSave={handleParcelSave}
            onCancel={handleParcelCancel}
            saving={saving}
          /> :
            <div>
              <h4>Parcelas</h4>
              <BillingParcelList
                billingParcels={billingParcels}
                parcels={parcels}
                onEditClick={handleEditParcel}
                onDeleteClick={handleDeleteParcel}
              />
            </div>
          }       
        </Row> : <div></div>
        }              
         {!showHistory && 
         <Button
            type= "button"            
            className="btn btn-primary"
            name={"Ver historial"}                
            onClick={() => setShowHistory(true)}     
          ></Button>
        }
        {billingHistory.length > 0 && 
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <h4>Histórico</h4>
            <BillingHistoryList
              billingHistory={billingHistory}
            />
        </Row>} 
      </Container>
    </div >
  );
}

ManageBillingPage.propTypes = {
  parcels: PropTypes.array.isRequired,
  loadBilling: PropTypes.func.isRequired,
  loadParcels: PropTypes.func.isRequired,
  saveBilling: PropTypes.func.isRequired,
  loadBillingParcels: PropTypes.func.isRequired,
  saveBillingParcel: PropTypes.func.isRequired,

};

export function getBillingById(billingRecords: IBilling[], id: string): IBilling | null {
  return billingRecords.find(billing => billing.id?.toString() === id) || null;
}

export function getParcelsByBillingId(billingId: number, billingeParcels: IBillingParcel[]) {
  return billingeParcels.filter((billingParcel) => billingParcel.billingId === billingId) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    billingRecords: state.billing,
    parcels: state.parcels,
    allBillingParcels: state.billingParcels
  };
}

const mapDispatchToProps = {
  loadBilling,
  saveBilling,
  loadParcels,
  loadBillingParcels,
  saveBillingParcel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageBillingPage);
