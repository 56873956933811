import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadRegions } from "../../redux/actions/regionsActions";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { months, newDistributor, newError, } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import { IProduct } from "../products/types";
import { IRegion } from "../regions/types";
import { IDistributor, IDistributorProduct, IDistributorVieWModel } from "./types";
import { loadDistributors, saveDistributorWithProducts } from "../../redux/actions/distributorActions";
import { loadProducts } from "../../redux/actions/productsActions";
import DistributorForm from "./DistributorForm";
import Button from "../framework/Button";
import { toast } from "react-toastify";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import DistributorProductList from "./DistributorProductList";
import * as distributorApi from "../../api/distributorApi";
import { GetAllGroups } from "../utils/constants";

type Props = {
  products: IProduct[],
  regions: IRegion[],
  distributors: IDistributor[],
  loadProducts: () => Promise<void>;
  loadRegions: () => Promise<void>,
  loadDistributors: () => Promise<void>,
  saveDistributorWithProducts: (event: any) => Promise<void>
}

function ManageDistributorPage({
  regions,
  products,
  distributors,
  loadProducts,
  loadRegions,
  loadDistributors,
  saveDistributorWithProducts,
  ...props
}: Props) {

  const [productIndex, setProductIndex] = useState(-1);
  const [distributor, setDistributor] = useState(newDistributor);
  const [distributorProducts, setDistributorProducts] = useState([] as IDistributorProduct[]);
  const [productId, setProductId] = useState(0);
  const [saving, setSaving] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const distributorId = params.id;

  useEffect(() => {
    if (distributors.length === 0) {
      loadDistributors()
        .catch(error => {
          console.error("Loading distributors failed" + error);
        });
    }
    else {
      const distributorSelected = distributorId && distributors.length > 0 ? getDistributorById(distributors, distributorId) : newDistributor;
      if (distributorSelected)
        setDistributor(distributorSelected);

      if (distributorSelected?.id) {

      }
    }
  }, [loadDistributors, distributorId, distributors]);

  useEffect(() => {
    if (distributorId) {
      distributorApi.getDistributorProducts(Number(distributorId))
        .then((products) => {
          setDistributorProducts(products);
        })
        .catch(error => {
          console.error("Loading distributor prodcuts failed" + error);
        });
    }
  }, [distributorId]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
  }, [loadRegions, regions.length]);


  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          console.error("Loading products failed" + error);
        });
    }
  }, [loadProducts, products.length]);


  function handleDistributorChange(event: any) {
    const { name, value } = event.target;
    setDistributor(prevRegion => ({
      ...prevRegion,
      [name]: value
    }));
  }

  function handleDistributorSave(event: any) {
    event.preventDefault();
    setSaving(true);

    const distributorViewMode = {
      distributor,
      products: distributorProducts
    } as IDistributorVieWModel;

    saveDistributorWithProducts(distributorViewMode)
      .then(() => {
        toast.success("Distribuidor guardado");
        setTimeout(() => {
          setSaving(false);
        }, (700));

        navigate(`/distribuidores`);;
      })
      .catch(error => {
        setSaving(false);
        toast.error("Error. " + error.message, { autoClose: false });
      });
  }

  function handleProductChange(distributorProduct: IDistributorProduct, event: any) {
    const { name, value } = event.target;
    let distributorProductChanged = distributorProducts.find(pe => pe.id === distributorProduct.id);
    distributorProductChanged = {
      ...distributorProduct,
      [name]: value
    }

    const newDistributorProducts = distributorProducts.map(p => p.id === distributorProductChanged?.id ? distributorProductChanged : p);
    setDistributorProducts(newDistributorProducts);
  }

  function handleProductDelete(distributorProduct: IDistributorProduct, event: any) {
    const newDistributorProducts = distributorProducts.filter(p => p.id !== distributorProduct?.id);
    setDistributorProducts(newDistributorProducts);
  }

  function handleNewProduct(event: any) {

    if (distributorProducts.some(p => p.productId === productId)) {
      toast.error("Error. Producto duplicado");
      return;
    }

    const newProduct = {
      id: productIndex,
      distributorId: distributorId,
      productId: productId,
      cost: 0,
      percentage: 0,
    } as unknown as IDistributorProduct;
    const newDistributorProducts = [...distributorProducts, newProduct];
    setDistributorProducts(newDistributorProducts);

    setProductIndex(productIndex - 1);
  }

  return (
    <div>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <h4>Distribuidor</h4>
          <DistributorForm
            distributor={distributor}
            handleChange={handleDistributorChange}
            handleSave={handleDistributorSave}
          />
        </Row>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <Container>
            <h5>Añadir Producto</h5>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
              <Col className="col-sm-3">
                <SelectInput
                  name="products"
                  value={productId}
                  defaultOption="Seleccione Producto"
                  options={products.map(pg => ({
                    value: pg.id,
                    text: pg.name
                  } as SelectOption))}
                  onChange={(e) => setProductId(products.find(p => p.id?.toString() === e.target.value)?.id ?? 0)}
                />
              </Col>            
              <Col className="col-sm-3">
                <Button
                  type="button"
                  className="btn btn-primary"
                  name="Añadir Producto"
                  onClick={handleNewProduct}
                  isDisabled={productId === 0}
                ></Button>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      { distributorProducts && distributorProducts.length > 0 &&
        <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <Col className="col-sm-12">
            <DistributorProductList
              products={products}
              distributorProducts={distributorProducts}
              onChange={handleProductChange}
              onDelete={handleProductDelete}
            />
          </Col>
        </Row>
      </Container>
      }
    </div >
  );
}

ManageDistributorPage.propTypes = {
  regions: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  loadDistributors: PropTypes.func.isRequired,
  loadProducts: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  saveDistributorWithProducts: PropTypes.func.isRequired,
};

export function getDistributorById(distributors: IDistributor[], id: string): IDistributor | null {
  return distributors.find(distributor => distributor.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    regions: state.regions,
    products: state.products,
    distributors: state.distributors
  };
}

const mapDispatchToProps = {
  loadDistributors,
  loadRegions,
  loadProducts,
  saveDistributorWithProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDistributorPage);
