import * as types from "./actionTypes";
import * as templateApi from "../../api/templateApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadTemplatesSuccess(templates) {
  return { type: types.LOAD_TEMPLATES_SUCCESS, templates };
}


export function deleteTemplateOptimistic(template) {
  return { type: types.DELETE_TEMPLATE_OPTIMISTIC, template };
}

export function createTemplateSuccess(template) {
  return { type: types.CREATE_TEMPLATE_SUCCESS, template };
}

export function updateTemplateSuccess(template) {
  return { type: types.UPDATE_TEMPLATE_SUCCESS, template };
}


export function loadTemplates() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return templateApi
      .getTemplates()
      .then(templates => {
        dispatch(loadTemplatesSuccess(templates));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveTemplate(template) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return templateApi
      .saveTemplate(template)
      .then(savedTemplate => {
        template.id
          ? dispatch(updateTemplateSuccess(savedTemplate))
          : dispatch(createTemplateSuccess(savedTemplate));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveTemplateWithProducts(template) {
  //eslint-disable-next-line no-unused-vars
  return function (dispatch, getState) {
    dispatch(beginApiCall());
    return templateApi
      .saveTemplateWithProducts(template)
      .then(savedTemplate => {
        template.template.id
          ? dispatch(updateTemplateSuccess(savedTemplate.template))
          : dispatch(createTemplateSuccess(savedTemplate.template));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteTemplate(TemplateId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteTemplateOptimistic(TemplateId));
    return templateApi.deleteTemplate(TemplateId);
  };
}