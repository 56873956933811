export const MOVE_HERO = "MOVE_HERO";
export const CHANGE_HERO_DIR = "CHANGE_HERO_DIR";
export const SHOW_BUG = "SHOW_BUG";
export const ADD_TAIL = "ADD_TAIL";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";

export const CREATE_USER = "CREATE_USER";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_OPTIMISTIC = "DELETE_USER_OPTIMISTIC";

export const CREATE_PARCEL = "CREATE_PARCEL";
export const LOAD_PARCELS_SUCCESS = "LOAD_PARCELS_SUCCESS";
export const CREATE_PARCEL_SUCCESS = "CREATE_PARCEL_SUCCESS";
export const UPDATE_PARCEL_SUCCESS = "UPDATE_PARCEL_SUCCESS";
export const DELETE_PARCEL_OPTIMISTIC = "DELETE_PARCEL_OPTIMISTIC";

export const CREATE_PARCEL_ELEMENT = "CREATE_PARCEL_ELEMENT";
export const LOAD_PARCEL_ELEMENTS_SUCCESS = "LOAD_PARCEL_ELEMENTS_SUCCESS";
export const CREATE_PARCEL_ELEMENT_SUCCESS = "CREATE_PARCEL_ELEMENT_SUCCESS";
export const UPDATE_PARCEL_ELEMENT_SUCCESS = "UPDATE_PARCEL_ELEMENT_SUCCESS";
export const DELETE_PARCEL_ELEMENT_OPTIMISTIC = "DELETEPARCEL_ELEMENT_OPTIMISTIC";

export const CREATE_ELEMENT = "CREATE_ELEMENT";
export const LOAD_ELEMENTS_SUCCESS = "LOAD_ELEMENTS_SUCCESS";
export const CREATE_ELEMENT_SUCCESS = "CREATE_ELEMENT_SUCCESS";
export const UPDATE_ELEMENT_SUCCESS = "UPDATE_ELEMENT_SUCCESS";
export const DELETE_ELEMENT_OPTIMISTIC = "DELETE_ELEMENT_OPTIMISTIC";

export const CREATE_REGION = "CREATE_REGION";
export const LOAD_REGIONS_SUCCESS = "LOAD_REGIONS_SUCCESS";
export const CREATE_REGION_SUCCESS = "CREATE_REGION_SUCCESS";
export const UPDATE_REGION_SUCCESS = "UPDATE_REGION_SUCCESS";
export const DELETE_REGION_OPTIMISTIC = "DELETE_REGION_OPTIMISTIC";

export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const LOAD_TEMPLATES_SUCCESS = "LOAD_TEMPLATES_SUCCESS";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_OPTIMISTIC = "DELETE_TEMPLATE_OPTIMISTIC";

export const CREATE_REGION_ELEMENT = "CREATE_REGION_ELEMENT";
export const LOAD_REGION_ELEMENTS_SUCCESS = "LOAD_REGION_ELEMENTS_SUCCESS";
export const CREATE_REGION_ELEMENT_SUCCESS = "CREATE_REGION_ELEMENT_ELEMENT_SUCCESS";
export const UPDATE_REGION_ELEMENT_SUCCESS = "UPDATE_REGION_ELEMENT_SUCCESS";
export const DELETE_REGION_ELEMENT_OPTIMISTIC = "DELETE_REGION_ELEMENT_OPTIMISTIC";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_OPTIMISTIC = "DELETE_PRODUCT_OPTIMISTIC";

export const CREATE_PRODUCT_ELEMENT = "CREATE_PRODUCT_ELEMENT";
export const LOAD_PRODUCT_ELEMENTS_SUCCESS = "LOAD_PRODUCT_ELEMENTS_SUCCESS";
export const CREATE_PRODUCT_ELEMENT_SUCCESS = "CREATE_PRODUCT_ELEMENT_ELEMENT_SUCCESS";
export const UPDATE_PRODUCT_ELEMENT_SUCCESS = "UPDATE_PRODUCT_ELEMENT_SUCCESS";
export const DELETE_PRODUCT_ELEMENT_OPTIMISTIC = "DELETE_PRODUCT_ELEMENT_OPTIMISTIC";

export const CREATE_DISTRIBUTOR = "CREATE_DISTRIBUTOR";
export const LOAD_DISTRIBUTORS_SUCCESS = "LOAD_DISTRIBUTORS_SUCCESS";
export const CREATE_DISTRIBUTOR_SUCCESS = "CREATE_DISTRIBUTOR_SUCCESS";
export const UPDATE_DISTRIBUTOR_SUCCESS = "UPDATE_DISTRIBUTOR_SUCCESS";
export const DELETE_DISTRIBUTOR_OPTIMISTIC = "DELETE_DISTRIBUTOR_OPTIMISTIC";

export const CREATE_DISTRIBUTOR_PRODUCT = "CREATE_DISTRIBUTOR_PRODUCT";
export const LOAD_DISTRIBUTOR_PRODUCTS_SUCCESS = "LOAD_DISTRIBUTOR_PRODUCTS_SUCCESS";
export const CREATE_DISTRIBUTOR_PRODUCT_SUCCESS = "CREATE_DISTRIBUTOR_PRODUCT_PRODUCT_SUCCESS";
export const UPDATE_DISTRIBUTOR_PRODUCT_SUCCESS = "UPDATE_DISTRIBUTOR_PRODUCT_SUCCESS";
export const DELETE_DISTRIBUTOR_PRODUCT_OPTIMISTIC = "DELETE_DISTRIBUTOR_PRODUCT_OPTIMISTIC";

export const CREATE_REPORT = "CREATE_REPORT";
export const LOAD_REPORTS_SUCCESS = "LOAD_REPORTS_SUCCESS";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const DELETE_REPORT_OPTIMISTIC = "DELETE_REPORT_OPTIMISTIC";

export const CREATE_BILLING = "CREATE_BILLING";
export const LOAD_BILLINGS_SUCCESS = "LOAD_BILLINGS_SUCCESS";
export const CREATE_BILLING_SUCCESS = "CREATE_BILLING_SUCCESS";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const DELETE_BILLING_OPTIMISTIC = "DELETE_BILLING_OPTIMISTIC";

export const CREATE_BILLING_PARCEL = "CREATE_BILLING_PARCEL";
export const LOAD_BILLING_PARCELS_SUCCESS = "LOAD_BILLING_PARCELS_SUCCESS";
export const CREATE_BILLING_PARCEL_SUCCESS = "CREATE_BILLING_PARCEL_PARCEL_SUCCESS";
export const UPDATE_BILLING_PARCEL_SUCCESS = "UPDATE_BILLING_PARCEL_SUCCESS";
export const DELETE_BILLING_PARCEL_OPTIMISTIC = "DELETE_BILLING_PARCEL_OPTIMISTIC";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const CREATE_TASK = "CREATE_TASK";
export const LOAD_TASKS_SUCCESS = "LOAD_TASKS_SUCCESS";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const DELETE_TASK_OPTIMISTIC = "DELETE_TASK_OPTIMISTIC";

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
