import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import CheckBoxInput from "../framework/CheckBoxInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IElement } from "./types";

type Props = {
  element: IElement,
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const ElementForm = ({
  element,
  onSave,
  onChange,
  onCancel,
  saving = false,
  errors
}: Props) => {
  return (
    <form onSubmit={onSave} className="form-flex">
      <h4>{element.id ? "Editar" : "Crear"} Elemento</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row">
          <Col className="col-sm-4">
            <TextInput
              name="name"
              label="Nombre"
              value={element.name?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 4"}
            />
          </Col>
          <Col className="col-sm-4">
            <TextInput
              name="symbol"
              label="Símbolo"
              value={element.symbol?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 4"}
            />
          </Col>
          <Col className="col-sm-4">
            <CheckBoxInput
              name="productStandardOptional"
              label="Opcional para Standard Products"
              value={element.productStandardOptional ?? false}
              onChange={onChange}
              wrapperClass={"col 4"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-1">
            <Button
              type="submit"
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
              isDisabled={saving}
            ></Button>      
          </Col>
          <Col className="col-sm-1">
            <Button
              type="button"
              isDisabled={saving}
              className="btn btn-secondary"
              onClick={onCancel}
              name="Cancelar"
            ></Button>      
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ElementForm;
