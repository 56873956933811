import React from "react";
import PropTypes from "prop-types";
import { IReportViewModel } from "../createReport2/types";
import { ConvertToMonthName } from "../utils/monthCalculations";
import Button from "../framework/Button";
import { useNavigate } from "react-router-dom";
import ReportForm from "../reports/ReportForm";
import { formatDate } from "../utils/dateUtils";

type Props = {
  reports: IReportViewModel[],
  onDeleteClick: (event: any) => void,
  onPreview: (display: boolean, event: any) => void,
  reportIdDisplayed: number
}

const ParcelReportList = ({ reports, onDeleteClick, onPreview, reportIdDisplayed }: Props) => {

  return (
    <div>
      {reports.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th style={{ width: '40%' }}>Fecha Creacion</th>
            <th style={{ width: '10%' }}>Mes</th>
            <th style={{ width: '10px' }} />
          </tr>
        </thead>
        <tbody>
          {reports.map(report => {
            return (
              <tr key={report.details.id}>
                <td>
                  {
                    //print targetdate on dd/mm/yyyy HH:MM:SS format in localtime
                    !!report.details.insertDate ? formatDate(new Date(report.details.insertDate.toString() + "Z"), "dd/mm/yyyy hh:min:ss") : ""
                                        
                  }
                </td>
                <td>
                  {ConvertToMonthName(report.details.month ?? 0)}
                </td>
                {
                  report.details.id === reportIdDisplayed ?
                    <td>
                      <Button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={(e) => onPreview(false, report)}
                        name="Ocultar"
                      ></Button>
                    </td>
                    :
                    <td>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => onPreview(true, report)}
                        name="Mostrar"
                      ></Button>
                    </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}
    </div>
  );

}

ParcelReportList.propTypes = {
  reports: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default ParcelReportList;
