import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadElements, saveElement } from "../../redux/actions/elementsActions";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { IElement } from "./types";
import { newError, newElement } from "../../tools/mockData";
import ElementForm from "./ElementForm";

type Props = {
  elements: IElement[],
  loadElements: () => Promise<void>,
  saveElement: (event: any) => Promise<void>
}

function ManageElementPage({
  elements,
  loadElements,
  saveElement,
  ...props
}: Props) {


  const [element, setElement] = useState(newElement);
  const [errors, setErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const elementId = params.id;

  useEffect(() => {
    if (elements.length === 0) {
      loadElements()
        .catch(error => {
          console.error("Loading elements failed" + error);
        });
    }
  }, [loadElements, elements.length]);

  useEffect(() => {
    if (elements.length > 0) {
      const elementSelected = elementId && elements.length > 0 ? getElementById(elements, elementId) : newElement;
      if (elementSelected)
        setElement(elementSelected);
    }
  }, [elements, elementId]);

  function handleChange(event: any) {
    const { name, value } = event.target;

    if (name === "productStandardOptional") {
      setElement(prevElement => ({
        ...prevElement,
        [name]: event.target.checked
      }));
    }
    else {
      setElement(prevElement => ({
        ...prevElement,
        [name]: value
      }));

    }

  }

  function handleSave(event: any) {
    event.preventDefault();

    setSaving(true);
    saveElement(element)
      .then(() => {
        toast.success("Elemento guardado");
        setTimeout(() => {
          setSaving(false);
          navigate(`/elementos`);
        }, (700));
      })
      .catch(error => {
        setSaving(false);
        setErrors({ ...error, onSave: error.message });
      });
  }

  function handleCancel(event: any) {
    setSaving(false);
    navigate(`/elementos`);
  }


  return (
    <ElementForm
      element={element}
      errors={errors}
      onChange={handleChange}
      onSave={handleSave}
      onCancel={handleCancel}
      saving={saving}
    />
  );
}

ManageElementPage.propTypes = {
  elements: PropTypes.array.isRequired,
  loadElements: PropTypes.func.isRequired,
  saveElement: PropTypes.func.isRequired

};

export function getElementById(elements: IElement[], id: string): IElement | null {
  return elements.find(element => element.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    elements: state.elements,
  };
}

const mapDispatchToProps = {
  loadElements,
  saveElement
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageElementPage);
