import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function regionElementReducer(state = initialState.regionElements, action) {
  switch (action.type) {
    case types.CREATE_REGION_ELEMENT_SUCCESS:
      return [...state, { ...action.regionElement }];
    case types.UPDATE_REGION_ELEMENT_SUCCESS:
      return state.map(regionElement =>
        regionElement.id === action.regionElement.id ? action.regionElement : regionElement
      );
    case types.LOAD_REGION_ELEMENTS_SUCCESS:
      return action.regionElements;
    case types.DELETE_REGION_ELEMENT_OPTIMISTIC:
      return state.filter(regionElement => regionElement.id !== action.regionElementId);
    default:
      return state;
  }
}
