import React from "react";
import Box from "./Box";
import "./Maze.css";
import PropTypes from "prop-types";

const Board = ({ heroCords, bugCords, tail, numRows, numColumns }) => {
  return (
    <div className="board">
      {[...Array(numColumns)].map((x, i) => (
        <div className="board-row" key={i}>
          {[...Array(numRows)].map((x, j) => (
            <div className="col" key={[i, j]}>
              <Box
                x={j}
                y={i}
                heroCords={heroCords}
                bugCords={bugCords}
                tail={tail}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

Board.propTypes = {
  heroCords: PropTypes.object.isRequired,
  bugCords: PropTypes.object.isRequired,
  tail: PropTypes.array.isRequired,
  numRows: PropTypes.number.isRequired,
  numColumns: PropTypes.number.isRequired,
};

export default Board;
