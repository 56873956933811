import * as types from "./actionTypes";

export function moveHero(dir) {
  return { type: types.MOVE_HERO, dir };
}

export function showBug(cords) {
  return { type: types.SHOW_BUG, cords };
}

export function changeHeroDir(dir) {
  return { type: types.CHANGE_HERO_DIR, dir };
}

export function addTail(dir) {
  return { type: types.ADD_TAIL, dir };
}
