import { useSelector } from "react-redux";
import { ROLE_ADMIN } from "../utils/constants";

interface Props  extends React.PropsWithChildren { 
}

const AdminContainer = ({  
  children
}: Props) => {

  const userRole = useSelector((state: any) => state.applicationUser?.roleName);
  const isVisible = userRole === ROLE_ADMIN;

  return (
    <>
      {isVisible &&
        <>
         { children   }
        </>
      }
    </>
  );
};

AdminContainer.propTypes = {
};

const defaultProps: any = { 
}
AdminContainer.defaultProps = defaultProps;

export default AdminContainer;
