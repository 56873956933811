import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function parcelReducer(state = initialState.parcels, action) {
  switch (action.type) {
    case types.CREATE_PARCEL_SUCCESS:
      return [...state, { ...action.parcel }];
    case types.UPDATE_PARCEL_SUCCESS:
      return state.map(parcel =>
        parcel.id === action.parcel.id ? action.parcel : parcel
      );
    case types.LOAD_PARCELS_SUCCESS:
      return action.parcels;
    case types.DELETE_PARCEL_OPTIMISTIC:
      return state.filter(parcel => parcel.id !== action.parcelId);
    default:
      return state;
  }
}
