import * as types from "./actionTypes";
import * as elementApi from "../../api/elementApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadElementsSuccess(elements) {
  return { type: types.LOAD_ELEMENTS_SUCCESS, elements };
}

export function deleteElementOptimistic(element) {
  return { type: types.DELETE_ELEMENT_OPTIMISTIC, element };
}

export function createElementSuccess(element) {
  return { type: types.CREATE_ELEMENT_SUCCESS, element };
}

export function updateElementSuccess(element) {
  return { type: types.UPDATE_ELEMENT_SUCCESS, element };
}


export function loadElements() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return elementApi
      .getElements()
      .then(elements => {
        dispatch(loadElementsSuccess(elements));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveElement(element) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return elementApi
      .saveElement(element)
      .then(savedElement => {
        element.id
          ? dispatch(updateElementSuccess(savedElement))
          : dispatch(createElementSuccess(savedElement));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteElement(elementId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteElementOptimistic(elementId));
    return elementApi.deleteElement(elementId);
  };
}