import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function reportReducer(state = initialState.reports, action) {
  switch (action.type) {
    case types.CREATE_REPORT_SUCCESS:
      return [...state, { ...action.report }];
    case types.UPDATE_REPORT_SUCCESS:
      return state.map(report =>
        report.id === action.report.id ? action.report : report
      );
     case types.LOAD_REPORTS_SUCCESS:
       return action.reports;
    case types.DELETE_REPORT_OPTIMISTIC:
      return state.filter(report => report.details.id !== action.reportId);
    default:
      return state;
  }
}
