import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function templateReducer(state = initialState.templates, action) {
  switch (action.type) {
    case types.CREATE_TEMPLATE_SUCCESS:
      return [...state, { ...action.template }];
    case types.UPDATE_TEMPLATE_SUCCESS:
      return state.map(template =>
        template.id === action.template.id ? action.template : template
      );
    case types.LOAD_TEMPLATES_SUCCESS:
      return action.templates;
    case types.DELETE_TEMPLATE_OPTIMISTIC:
      return state.filter(template => template.id !== action.template);
    default:
      return state;
  }
}
