import * as types from "./actionTypes";
import * as parcelApi from "../../api/parcelApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadParcelsSuccess(parcels) {
  return { type: types.LOAD_PARCELS_SUCCESS, parcels };
}

export function deleteParcelOptimistic(parcel) {
  return { type: types.DELETE_PARCEL_OPTIMISTIC, parcel };
}

export function createParcelSuccess(parcel) {
  return { type: types.CREATE_PARCEL_SUCCESS, parcel };
}

export function updateParcelSuccess(parcel) {
  return { type: types.UPDATE_PARCEL_SUCCESS, parcel };
}


export function loadParcels() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return parcelApi
      .getParcels()
      .then(parcels => {
        dispatch(loadParcelsSuccess(parcels));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveParcel(parcel) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return parcelApi
      .saveParcel(parcel)
      .then(savedParcel => {
        parcel.id
          ? dispatch(updateParcelSuccess(savedParcel))
          : dispatch(createParcelSuccess(savedParcel));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteParcel(parcelId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteParcelOptimistic(parcelId));
    return parcelApi.deleteParcel(parcelId);
  };
}