import React, { useState } from "react";
import PropTypes from "prop-types";
import { IProduct } from "../products/types";
import { IParcelProduct } from "./types";
import CheckBoxInput from "../framework/CheckBoxInput";
import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import { IParcel } from "../parcels/types";
import TextArea from "../framework/TextArea";

type Props = {
  parcel: IParcel,
  foliarProducts: IParcelProduct[],
  observations: string,
  products: IProduct[],
  setFoliarProducts: (event: any) => void,
  setFoliarObservations: (event: any) => void,
}

const ReportFoliarTreatment2 = ({ parcel, foliarProducts, observations, products, setFoliarProducts, setFoliarObservations }: Props) => {

  const [errors] = useState(newError);


  function handleFoliarTreamentChange(event: any): void {
    setFoliarObservations(event.target.value);
  }

  function handleFoliarTreamentProductSelected(id: number | null, event: any) {
    const parcelProductChanged = foliarProducts.find(pp => pp.id === id);

    if (parcelProductChanged) {
      const newParcelProducts = foliarProducts.map(pp => pp.id === id ? { ...pp, isSelected: !pp.isSelected } : pp);
      setFoliarProducts(newParcelProducts);
    }
  }

  function handleFoliarTreamentDosedProductChange(id: number | null, event: any) {
    const { name, value } = event.target;
    const productChanged = foliarProducts.find(pe => pe.id === id);

    if (productChanged) {

      const newParcelProducts = foliarProducts.map(pe => pe.id === id ? {
        ...pe,
        [name]: value,
      } : pe);
      setFoliarProducts(newParcelProducts);
    }
  }

  return (
    <div>

      <Container>
        <Row className="container-row">
          <Col className="col-sm-3">           
            <TextInput
              name="plantType"
              label="Tipo de Planta"
              value={parcel.plantType}
              errors={errors.fieldErrors}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-7">
            <TextArea
              name="observations"
              label="Observaciones"
              value={observations || ""}
              onChange={handleFoliarTreamentChange}
            />
          </Col>          
        </Row>
        <Row>
          {products.length > 0 ? <table className="table">
            <thead>
              <tr>
                <th></th>
                <th style={{ width: '10%' }}>Nombre</th>
                <th style={{ width: '20%' }}>Dosis CC</th>
                <th style={{ width: '70%' }}></th>
              </tr>
            </thead>
            <tbody>
              {foliarProducts.map(parcelProduct => {
                return (
                  <tr key={parcelProduct.id}>
                    <td>
                      <CheckBoxInput
                        label=""
                        value={parcelProduct.isSelected}
                        onChange={(e) => handleFoliarTreamentProductSelected(parcelProduct.id, e)}
                      />
                    </td>
                    <td>
                      {products.find(e => e.id === parcelProduct.productId)?.name}
                    </td>
                    <td>
                      <TextInput
                        name="doseTotal"
                        value={parcelProduct.doseTotal?.toString()}
                        onChange={(e) => handleFoliarTreamentDosedProductChange(parcelProduct.id, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> : <div />}
        </Row>
      </Container>

     

    </div>
  );
}

ReportFoliarTreatment2.propTypes = {
  foliarProducts: PropTypes.array.isRequired,
  observations: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  setFoliarProducts: PropTypes.func.isRequired,
  setFoliarObservations: PropTypes.func.isRequired
};

export default ReportFoliarTreatment2;
