import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import SelectInput from "../framework/SelectInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IElement } from "../elements/types";
import { IProductElement } from "./types";

type Props = {
  productElement: IProductElement,
  elements: IElement[],
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const ProductElementForm = ({
  productElement,
  elements,
  onSave,
  onChange,
  onCancel,
  saving = false,
  errors
}: Props) => {
  return (
    <form onSubmit={onSave} className="form-flex">
      <h4>{productElement.id ? "Editar" : "Crear"} Elemento</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row">
          <Col className="col-sm-6">
            <TextInput
              name="element"
              label="Elemento"
              value={elements.find(e => e.id === productElement.elementId)?.name}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
              isReadOnly={true}
            />
          </Col>
          <Col className="col-sm-6">
            <TextInput
              name="percentage"
              label={"Porcentaje"}
              value={productElement.percentage?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-1">
            <Button
              type="submit"
              isDisabled={saving}
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
            ></Button>             
          </Col>
          <Col className="col-sm-1">
            <Button
              type="button"
              disabled={saving}
              className="btn btn-secondary"
              onClick={onCancel}
              name="Cancelar"
            ></Button>   
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ProductElementForm;
