import AboutPage from '../about/AboutPage';
import ApiAuthorzationRoutes from '../../api/api-authorization/ApiAuthorizationRoutes';
import { Counter } from "../examples/Counter";
import { FetchData } from "../examples/FetchData";
import HomePage from '../home/HomePage';
import MazeGame from '../examples/MazeGame/MazeGame';
import UserPage from '../users/UsersPage';
import ManageUserPage from '../users/ManageUserPage';
import ParcelsPage from '../parcels/ParcelsPage';
import ManageParcelPage from '../parcels/ManageParcelPage';
import ElementsPage from '../elements/ElementsPage';
import ManageElementPage from '../elements/ManageElementPage';
import RegionsPage from '../regions/RegionsPage';
import ManageRegionPage from '../regions/ManageRegionPage';
import ManageGenerateReportPage2 from '../createReport2/ManageGenerateReportPage2';
import ProductsPage from '../products/ProductsPage';
import ManageProductPage from '../products/ManageProductPage';
import ManageReportPage from '../reports/ManageReportPage';
import ManageTemplatePage from '../templates/ManageTemplatePage';
import TemplatePage from '../templates/TemplatePage';
import BillingPage from '../billing/BillingPage';
import ManageBillingPage from '../billing/ManageBillingPage';
import AdminPage from '../administration/AdminPage';
import DistributorsPage from '../distributors/DistributorsPage';
import ManageDistributorsPage from '../distributors/ManageDistributorsPage';
import TasksPage from '../tasks/TasksPage';
import ManageTaskPage from '../tasks/ManageTaskPage';
import { IAppRoute } from './types';
import ManageAnualReportPage from '../anualReports/ManageAnualReportPage';


const AppRoutes = [
  {
    index: true,
    element: <HomePage />
  } as unknown as IAppRoute,
  {
    path: "/maze",
    element: <MazeGame />,
    roles: ['Admin']
  },
  {
    path: '/counter',
    element: <Counter />,
    roles: ['Admin']
  },
  {
    path: '/fetch-data',
    requiredAuth: true,
    element: <FetchData />,
    roles: ['Admin']
  },
  {
    path: '/usuarios',
    requiredAuth: true,
    element: <UserPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/usuario/:id',
    requiredAuth: true,
    element: <ManageUserPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/usuario/',
    requiredAuth: true,
    element: <ManageUserPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/parcelas',
    requiredAuth: true,
    element: <ParcelsPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/parcela/:id',
    requiredAuth: true,
    element: <ManageParcelPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/parcela/',
    requiredAuth: true,
    element: <ManageParcelPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/tareas',
    requiredAuth: true,
    element: <TasksPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/tarea/:id',
    requiredAuth: true,
    element: <ManageTaskPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/tarea/',
    requiredAuth: true,
    element: <ManageTaskPage />,
    roles: ['Admin', 'Tec']
  },

  {
    path: '/elementos',
    requiredAuth: true,
    element: <ElementsPage />,
    roles: ['Admin']
  },
  {
    path: '/elemento/:id',
    requiredAuth: true,
    element: <ManageElementPage />,
    roles: ['Admin']
  },
  {
    path: '/elemento/',
    requiredAuth: true,
    element: <ManageElementPage />,
    roles: ['Admin']
  },
  {
    path: '/regiones',
    requiredAuth: true,
    element: <RegionsPage />,
    roles: ['Admin']
  },
  {
    path: '/region/:id',
    requiredAuth: true,
    element: <ManageRegionPage />,
    roles: ['Admin']
  },
  {
    path: '/region/',
    requiredAuth: true,
    element: <ManageRegionPage />,
    roles: ['Admin']
  },
  {
    path: '/productos',
    requiredAuth: true,
    element: <ProductsPage />,
    roles: ['Admin']
  },
  {
    path: '/producto/:id',
    requiredAuth: true,
    element: <ManageProductPage />,
    roles: ['Admin']
  },
  {
    path: '/producto/',
    requiredAuth: true,
    element: <ManageProductPage />,
    roles: ['Admin']
  },
  {
    path: '/parcela/:parcelId/crearinforme/',
    requiredAuth: true,
    element: <ManageGenerateReportPage2 />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/parcela/:parcelId/crearinforme/:reportId',
    requiredAuth: true,
    element: <ManageGenerateReportPage2 />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/informes',
    requiredAuth: true,
    element: <ManageReportPage />,
    roles: ['Admin', 'Tec']
  },  
  {
    path: '/informe/:id',
    requiredAuth: true,
    element: <ManageReportPage />,
    roles: ['Admin', 'Tec']
  }, 
  {
    path: '/informes_anuales',
    requiredAuth: true,
    element: <ManageAnualReportPage />,
    roles: ['Admin', 'Tec']
  },  
  {
    path: '/about',
    element: <AboutPage />,
    roles: ['Admin', 'Tec']
  },
  {
    path: '/plantillas',
    requiredAuth: true,
    element: <TemplatePage />,
    roles: ['Admin']
  },
  {
    path: '/plantilla/:id',
    requiredAuth: true,
    element: <ManageTemplatePage />,
    roles: ['Admin']
  },
  {
    path: '/plantilla/',
    requiredAuth: true,
    element: <ManageTemplatePage />,
    roles: ['Admin']
  },
  {
    path: '/facturas',
    requiredAuth: true,
    element: <BillingPage />,
    roles: ['Admin']
  },
  {
    path: '/factura/:id',
    requiredAuth: true,
    element: <ManageBillingPage />,
    roles: ['Admin']
  },
  {
    path: '/factura/',
    requiredAuth: true,
    element: <ManageBillingPage />,
    roles: ['Admin']
  },
  {
    path: '/admin/',
    requiredAuth: true,
    element: <AdminPage />,
    roles: ['Admin']
  },
  {
    path: '/distribuidores',
    requiredAuth: true,
    element: <DistributorsPage />,
    roles: ['Admin']
  },
  {
    path: '/distribuidor/:id',
    requiredAuth: true,
    element: <ManageDistributorsPage />,
    roles: ['Admin']
  },
  {
    path: '/distribuidor/',
    requiredAuth: true,
    element: <ManageDistributorsPage />,
    roles: ['Admin']
  },
  ...ApiAuthorzationRoutes
] as IAppRoute[];

export default AppRoutes;
