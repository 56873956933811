import { IParcelImage } from "../components/parcels/types";
import authService from "./api-authorization/AuthorizeService";
import { handleError, handleResponse} from "./apiUtils";

const controllerName = "api/parcel-images";

export async function getImages(parcelId: number): Promise<IParcelImage[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName + "/" + parcelId, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

