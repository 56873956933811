import React from "react";
import PropTypes from "prop-types";
import "./Maze.css";

const Box = ({ x, y, heroCords, bugCords, tail }) => {
  const tailIsHere = tail.some((f) => f.x === x && f.y === y);

  const heroIsHere = x === heroCords.x && y === heroCords.y;
  const bugIsHere = x === bugCords.x && y === bugCords.y;
  const boxClass = tailIsHere
    ? "tail"
    : heroIsHere
    ? "hero"
    : bugIsHere
    ? "bug"
    : "";

  return (
    <>
      <div className={boxClass}>&nbsp;</div>
    </>
  );
};

Box.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  heroCords: PropTypes.object.isRequired,
  bugCords: PropTypes.object.isRequired,
  tail: PropTypes.array.isRequired,
};

export default Box;
