import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { hasPermission } from "../utils/securityUtils";

type Props = {
  name: string,
  isDisabled: boolean,
  className: string,
  actionName: string,
  type: 'submit' | 'reset' | 'button' | undefined,
  onClick: (event: any) => void;
  placeholder?: string,
  wrapperClass: string,
  roles: string[],
}

const Button = ({
  name,
  isDisabled,
  className,
  actionName,
  onClick,
  type,
  wrapperClass,
  roles
}: Props) => {

  const userRole = useSelector((state: any) => state.applicationUser?.roleName);
  const isVisible = hasPermission(roles, userRole);

  return (
    <div className={wrapperClass}>
      {isVisible &&
        <button
          type={type}
          disabled={isDisabled}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            if (onClick)
              onClick(event);
          }}
          className={className + " wise-font"}
        >
          {isDisabled ? actionName ?? name : name}
        </button>
      }
    </div>
  );
};

Button.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

const defaultProps: any = {
  name: "",
  value: "",
  wrapperClass: "",
  placeholder: "",
  isReadOnly: false,
  roles: []
}
Button.defaultProps = defaultProps;

export default Button;
