import * as types from "./actionTypes";
import * as regionApi from "../../api/regionApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadRegionsSuccess(regions) {
  return { type: types.LOAD_REGIONS_SUCCESS, regions };
}

export function deleteRegionOptimistic(region) {
  return { type: types.DELETE_REGION_OPTIMISTIC, region };
}

export function createRegionSuccess(region) {
  return { type: types.CREATE_REGION_SUCCESS, region };
}

export function updateRegionSuccess(region) {
  return { type: types.UPDATE_REGION_SUCCESS, region };
}


export function loadRegions() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return regionApi
      .getRegions()
      .then(regions => {
        dispatch(loadRegionsSuccess(regions));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveRegion(region) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return regionApi
      .saveRegion(region)
      .then(savedRegion => {
        region.id
          ? dispatch(updateRegionSuccess(savedRegion))
          : dispatch(createRegionSuccess(savedRegion));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteRegion(regionId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteRegionOptimistic(regionId));
    return regionApi.deleteRegion(regionId);
  };
}