import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { GetProductsGroups } from "../utils/constants";
import ProductDosesForm from "./ProductDosesForm";
import { IProduct, IProductGroup, IProductType } from "./types";

type Props = {
  product: IProduct,
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const ProductForm = ({
  product,
  onSave,
  onChange,
  onCancel,
  saving = false,
  errors
}: Props) => {

  const productTypes: IProductType[] = [
    { id: 1, name: 'Standard' } as unknown as IProductType,
    { id: 2, name: 'Dosis por Hectárea' } as unknown as IProductType,
  ]

  const productGroups = GetProductsGroups()

  return (
    <form onSubmit={onSave} className="form-flex">

      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-6">
            <TextInput
              name="name"
              label="Nombre"
              value={product.name}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
          <Col className="col-sm-6">
            <SelectInput
              name="type"
              label="Tipo"
              value={product.type || ""}
              defaultOption="Seleccione Tipo"
              options={productTypes.map(pg => ({
                value: pg.name,
                text: pg.name
              } as SelectOption ))}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
        </Row>
        <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-6">
            <SelectInput
              name="group"
              label="Grupo"
              value={product.group || ""}
              defaultOption="Seleccione Grupo"
              options={productGroups.map(pg => ({
                value: pg.name,
                text: pg.name
              } as SelectOption))}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
          <Col className="col-sm-6">
            <TextInput
              name="cost"
              label="Coste"
              value={product.cost?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 6"}
            />
          </Col>
        </Row>
        {product.type === "Dosis por Hectárea" ? <ProductDosesForm product={product} errors={errors} onChange={onChange}></ProductDosesForm> : <div></div>}
        <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-3">
            <Button
              type="submit"
              isDisabled={saving}
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
            ></Button>             
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ProductForm;
