import { IBilling, IBillingHistory } from "../components/billing/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/billing";


export async function getBilling(): Promise<IBilling[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getHistory(billingId: number): Promise<IBillingHistory[]> {
  const token = await authService.getAccessToken();
  const response = await fetch("api/billingHistory/" + billingId, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveBilling(billing: IBilling): Promise<IBilling> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: billing.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(billing)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteBilling(billingId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + billingId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

