import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadRegions, saveRegion } from "../../redux/actions/regionsActions";
import { loadElements } from "../../redux/actions/elementsActions";
import { loadRegionElements, saveRegionElement, deleteRegionElement } from "../../redux/actions/regionElementsActions";
import PropTypes from "prop-types";
import RegionForm from "./RegionForm";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IRegion, IRegionElement } from "./types";
import { newError, newRegion, newRegionElement } from "../../tools/mockData";
import { Container, Row } from "reactstrap";
import { IElement } from "../elements/types";
import RegionElementForm from "./RegionElementForm";
import RegionElementList from "./RegionElementList";
import * as regionApi from "../../api/regionApi";

type Props = {
  regions: IRegion[],
  elements: IElement[],
  allRegionElements: IRegionElement[],
  loadRegions: () => Promise<void>,
  saveRegion: (event: any) => Promise<void>
  loadElements: () => Promise<void>;
  loadRegionElements: () => Promise<void>;
  saveRegionElement: (event: any) => Promise<void>;
}

function ManageRegionPage({
  regions,
  elements,
  allRegionElements,
  loadRegions,
  saveRegion,
  loadElements,
  loadRegionElements,
  saveRegionElement,
  ...props
}: Props) {

  const emptyRegionElement: IRegionElement[] = [];
  const [region, setRegion] = useState(newRegion);
  const [regionElements, setRegionElements] = useState(emptyRegionElement);

  const [regionErrors, setRegionErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const [regionElement, setRegionElement] = useState(newRegionElement);
  const [elementErrors, setRegionElementErrors] = useState(newError);
  const params = useParams();
  const [showElementForm, setShowElementForm] = useState(false);
  const navigate = useNavigate();

  const regionId = params.id;

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
    else {
      const regionSelected = regionId && regions.length > 0 ? getRegionById(regions, regionId) : newRegion;
      if (regionSelected)
        setRegion(regionSelected);

      if (regionSelected?.id) {
        const elements = getElementsByRegionId(regionSelected.id, allRegionElements);
        if (elements.length > 0)
          setRegionElements(elements);
      }
    }
  }, [allRegionElements]);

  useEffect(() => {
    if (elements.length === 0) {
      loadElements()
        .catch(error => {
          console.error("Loading elements failed" + error);
        });
    }
  }, [loadElements, elements.length]);

  useEffect(() => {
    if (allRegionElements.length === 0) {
      loadRegionElements()
        .catch(error => {
          console.error("Loading region elements failed" + error);
        });
    }
  }, [loadRegionElements, allRegionElements.length]);

  function handleRegionChange(event: any) {
    const { name, value } = event.target;
    setRegion(prevRegion => ({
      ...prevRegion,
      [name]: value
    }));
  }

  function handleRegionSave(event: any) {
    event.preventDefault();

    const isNew = !region.id || region.id === 0;

    setSaving(true);
    saveRegion(region)
      .then(() => {
        if (isNew) {
          loadRegionElements();
        }

        toast.success("Region guardada");
        setTimeout(() => {
          setSaving(false);
        }, (700));

        navigate(`/regiones`);
      })
      .catch(error => {
        setSaving(false);
        setRegionErrors({ ...error, onSave: error.message });
      });
  }

  function handleRegionCancel(event: any) {
    setSaving(false);
    navigate(`/regiones`);
  }

  function regionElementFormIsValid() {
    const newError = { ...elementErrors, onSave: null };

    if (!regionElement.elementId) newError.fieldErrors.push({ title: "Elemento es requerido", fieldName: "elementId" })

    setRegionElementErrors(newError);
    // Form is valid if the errors object still has no properties
    return newError.fieldErrors.length === 0;
  }

  function handleRegionElementChange(event: any) {
    const { name, value } = event.target;
    setRegionElement(prevRegionElement => ({
      ...prevRegionElement,
      [name]: value
    }));

    const newError = { ...elementErrors };

    newError.fieldErrors = newError.fieldErrors.filter(e => e.fieldName !== name)

    setRegionElementErrors(newError);
  }

  function handleElementCancel(event: any) {
    setShowElementForm(!showElementForm);
    setSaving(false);
  }

  function handleElementSave(event: any) {
    event.preventDefault();
    if (!regionElementFormIsValid()) return;
    setSaving(true);
    saveRegionElement(regionElement)
      .then(() => {
        toast.success("Elemento guardado");
        setShowElementForm(false);
        setRegionElementErrors(newError);
        setSaving(false);
      })
      .catch(error => {
        setSaving(false);
        setRegionElementErrors({ ...elementErrors, onSave: error.message });
      });
  }

  async function handleEditElement(regionElement: IRegionElement) {
    setRegionElementErrors(newError);
    setRegionElement(regionElement);
    setShowElementForm(true)
  }

  async function handleDeleteElement(element: IElement) {
    toast.success("Elemento borrado");
    try {
      if (element.id)
        await deleteRegionElement(element.id);

    } catch (error: any) {
      toast.error("Delete failed. " + error.message, { autoClose: false });
    }
  }

  function handleCopy(regionid: number) {
    return regionApi
      .copyRegion(regionid)
      .then(newRegionId => {
        loadRegions();
        loadRegionElements();
        toast.success("Region Copiada:  ID:" + newRegionId);
      })
      .catch(error => {
        toast.error("Error copiando region");
        
        throw error;
      });
  }


  return (
    <div>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <h4>Region</h4>
          <RegionForm
            region={region}
            errors={regionErrors}
            onChange={handleRegionChange}
            onSave={handleRegionSave}
            onCancel={handleRegionCancel}
            onCopying={handleCopy}
            saving={saving}
          />
        </Row>
        {region.id ? <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {showElementForm ? <RegionElementForm
            regionElement={regionElement}
            elements={elements}
            errors={elementErrors}
            onChange={handleRegionElementChange}
            onSave={handleElementSave}
            onCancel={handleElementCancel}
            saving={saving}
          /> :
            <div>
              <h4>Elementos</h4>
              <RegionElementList
                regionElements={regionElements}
                elements={elements}
                onEditClick={handleEditElement}
                onDeleteClick={handleDeleteElement}
              />
            </div>
          }
        </Row> : <div></div>}

      </Container>
    </div >
  );
}

ManageRegionPage.propTypes = {
  regions: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  loadRegions: PropTypes.func.isRequired,
  loadElements: PropTypes.func.isRequired,
  saveRegion: PropTypes.func.isRequired,
  loadRegionElements: PropTypes.func.isRequired,
  saveRegionElement: PropTypes.func.isRequired,

};

export function getRegionById(regions: IRegion[], id: string): IRegion | null {
  return regions.find(region => region.id?.toString() === id) || null;
}

export function getElementsByRegionId(regionId: number, regioneElements: IRegionElement[]) {
  return regioneElements.filter((regionElement) => regionElement.regionId === regionId) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    regions: state.regions,
    elements: state.elements,
    allRegionElements: state.regionElements
  };
}

const mapDispatchToProps = {
  loadRegions,
  saveRegion,
  loadElements,
  loadRegionElements,
  saveRegionElement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRegionPage);
