import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function billingParcelReducer(state = initialState.billingParcels, action) {
  switch (action.type) {
    case types.CREATE_BILLING_PARCEL_SUCCESS:
      return [...state, { ...action.billingParcel }];
    case types.UPDATE_BILLING_PARCEL_SUCCESS:
      return state.map(billingParcel =>
        billingParcel.id === action.billingParcel.id ? action.billingParcel : billingParcel
      );
    case types.LOAD_BILLING_PARCELS_SUCCESS:
      return action.billingParcels;
    case types.DELETE_BILLING_PARCEL_OPTIMISTIC:
      return state.filter(billingParcel => billingParcel.id !== action.billingParcelId);
    default:
      return state;
  }
}
