import React from "react";
import PropTypes from "prop-types";
import { ITask } from "./types";
import Button from "../framework/Button";
import { IParcel } from "../parcels/types";
import { formatDate } from "../utils/dateUtils";

type Props = {
  tasks: ITask[],
  parcels: IParcel[],
  allowEdit: boolean,
  onEditClick: (task: ITask) => void | undefined,
  onSave: (task: ITask, action: string) => void | undefined,
  onDeleteClick: (task: ITask) => void | undefined,
}

const TaskList = ({ tasks, parcels, allowEdit, onEditClick, onSave, onDeleteClick }: Props) => (
  
  <div className="scroll-container">
    {tasks?.length ? <table className="table">
      <thead>
        <tr>
          <th style={{ width: '30%' }}>Título</th>
          <th style={{ width: '20%' }}>Parcela</th>
          <th style={{ width: '15%' }}>Fecha Notificación</th>
          <th style={{ width: '5%' }} />
          {/* <th style={{ width: '5%' }} /> */}
          {allowEdit && <th style={{ width: '5%' }} />}
        </tr>
      </thead>
      <tbody>
        {tasks.map(task => {
          return (
            //tr red if targetdate is today or before
            <tr key={task.id} className={!!task.targetDate && !task.deletedDate && new Date(new Date(task.targetDate).toDateString()) <= new Date() ? "bg-warning" : ""}>            
              <td>
                {task.title}
              </td>
              <td>
                {parcels.find(p => p.id === task.parcelId)?.name}
              </td>
              <td>
                {
                   //print targetdate on dd/mm/yyyy format
                  !!task.targetDate ? formatDate(new Date(task.targetDate), "dd/mm/yyyy") : ""
                }
              </td>
              <td>
                <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => onEditClick(task)}
                  name="Ver"
                ></Button>
              </td>
              {/* <td>
                <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => onSave(task, "Complete")}
                  name="Completar"
                  isDisabled={task.isCompleted || !!task.deletedDate}
                ></Button>
              </td> */}
              {
                allowEdit && <td>
                  {!task.deletedDate ?
                    <Button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => onSave(task, "Archive")}
                      name="Archivar"
                    ></Button> :
                    <Button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => onSave(task, "Reactive")}
                      name="Reactivar"
                      isDisabled={!task.deletedDate && !task.isCompleted}
                    ></Button>}
                </td>
              }
            </tr>
          );
        })}
      </tbody>
    </table> : <div />}

  </div>
);




TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default TaskList;
