export function ConvertToMonthName(monthId: number) : string{

    if (monthId === 1)
        return "Enero";
    else if (monthId === 2)
        return "Febrero";
    else if (monthId === 3)
        return "Marzo";
    else if (monthId === 4)
        return "Abril";
    else if (monthId === 5)
        return "Mayo";
    else if (monthId === 6)
        return "Junio";
    else if (monthId === 7)
        return "Julio";
    else if (monthId === 8)
        return "Agosto";
    else if (monthId === 9)
        return "Septiembre";
    else if (monthId === 10)
        return "Octubre";
    else if (monthId === 11)
        return "Noviembre";
    else if (monthId === 12)
        return "Diciembre";
    else
        return "";

}