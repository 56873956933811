export function formatDate(date, format) {
    const map = {
        mm: date.getMonth() + 1,
        dd: date.getDate(),
        yyyy: date.getFullYear(),
        hh: ("0" + date.getHours()).slice(-2),
        min: ("0" + date.getMinutes()).slice(-2),
        ss: ("0" + date.getSeconds()).slice(-2),
    }

    return format.replace(/mm|hh|min|ss|dd|yyyy/gi, matched => map[matched])
}