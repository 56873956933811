import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function billingReducer(state = initialState.billing, action) {
  switch (action.type) {
    case types.CREATE_BILLING_SUCCESS:
      return [...state, { ...action.billing }];
    case types.UPDATE_BILLING_SUCCESS:
      return state.map(billing =>
        billing.id === action.billing.id ? action.billing : billing
      );
    case types.LOAD_BILLINGS_SUCCESS:
      return action.billing;
    case types.DELETE_BILLING_OPTIMISTIC:
      return state.filter(billing => billing.id !== action.billingId);
    default:
      return state;
  }
}
