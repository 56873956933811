import * as types from "./actionTypes";
import * as regionElementApi from "../../api/regionElementApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadRegionElementsSuccess(regionElements) {
  return { type: types.LOAD_REGION_ELEMENTS_SUCCESS, regionElements };
}

export function deleteRegionElementOptimistic(regionElement) {
  return { type: types.DELETE_REGION_ELEMENT_OPTIMISTIC, regionElement };
}

export function createRegionElementSuccess(regionElement) {
  return { type: types.CREATE_REGION_ELEMENT_SUCCESS, regionElement };
}

export function updateRegionElementSuccess(regionElement) {
  return { type: types.UPDATE_REGION_ELEMENT_SUCCESS, regionElement };
}


export function loadRegionElements() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return regionElementApi
      .getRegionElements()
      .then(regionElements => {
        dispatch(loadRegionElementsSuccess(regionElements));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveRegionElement(regionElement) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return regionElementApi
      .saveRegionElement(regionElement)
      .then(savedRegionElement => {
        regionElement.id
          ? dispatch(updateRegionElementSuccess(savedRegionElement))
          : dispatch(createRegionElementSuccess(savedRegionElement));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteRegionElement(regionElementId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteRegionElementOptimistic(regionElementId));
    return regionElementApi.deleteRegionElement(regionElementId);
  };
}