import React from "react";
import PropTypes from "prop-types";
import { IProduct } from "./types";
import { Link, useNavigate } from "react-router-dom";
import Button from "../framework/Button";

type Props = {
  products: IProduct[],
  onEditClick: (product: IProduct) => void,
  onDeleteClick: (product: IProduct) => void,
}

const ProductList = ({ products, onEditClick, onDeleteClick }: Props) => {

  const navigate = useNavigate();

  return (
    <div className="scroll-container">
      {products.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Grupo</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {products.map(product => {
            return (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>{product.type}</td>
                <td>{product.group}</td>
                <td>
                  <Button
                    type="submit"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/producto/' + product.id)}
                    name="Editar"
                  ></Button>                              
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}

    </div>
  );
}


ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default ProductList;
