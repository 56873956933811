import React, { useState, useEffect } from 'react';
import { Link} from 'react-router-dom';
import { Collapse, NavItem, Navbar, NavbarBrand, NavbarToggler, NavLink } from 'reactstrap';
import './NavMenu.css';
import LoginMenu from '../../api/api-authorization/LoginMenu';
import { IApplicationUser, IMenuItem } from './types';
import { ITask } from '../tasks/types';
import { connect } from 'react-redux';
import { loadTasks } from '../../redux/actions/tasksActions';

type Props = {
  tasks: ITask[], 
  loadTasks: () => Promise<void>;  
  applicationUser: IApplicationUser
}

function NavMenu({
  tasks,
  loadTasks,
  applicationUser,
  ...props
}: Props) {

  const [collapsed, setCollapsed] = useState(false);
  const [menus, setMenus] = useState<IMenuItem[]>([]);
  const [tasksWithTargetToday, setTasksWithTargetToday] = useState<ITask[]>([]);
  const [tasksLoaded, setTasksLoaded] = useState<boolean>(false);

  useEffect(() => { 

    if (applicationUser?.isAuthenticated && !tasksLoaded)
    {
      loadTasks()   
      .then(() => setTasksLoaded(true))      
      .catch(error => {
        console.error("Loading tasks failed" + error);
      });
    }
    
  }, [applicationUser?.isAuthenticated, loadTasks, tasksLoaded]);

  useEffect(() => {
    if (tasks) {
      const todaysTasks = tasks.filter(t => !!t.targetDate && !t.deletedDate).filter(t => {
        const targetDate = new Date(t.targetDate!);
        const today = new Date();

        //return those where targetDate is prior to today
        return targetDate <= today;       
      });
      setTasksWithTargetToday(todaysTasks);      
    }
  }, [tasks]);

  useEffect(() => {
    
    if (applicationUser?.isAuthenticated) {
    
      var _menus = [
        { path: '/', text: 'Inicio' },
      ];
      if (applicationUser.roleName === 'Admin') {
        _menus.push({ path: '/usuarios', text: 'Usuarios' });
        _menus.push({ path: '/parcelas', text: 'Parcelas' });
        _menus.push({ path: '/tareas', text: 'Tareas' });
        _menus.push({ path: '/informes', text: 'Informes' });
        _menus.push({ path: '/informes_anuales', text: 'Informes Anuales' });
        _menus.push({ path: '/regiones', text: 'Regiones' });
        _menus.push({ path: '/productos', text: 'Productos' });
        _menus.push({ path: '/distribuidores', text: 'Distribuidores' });
        _menus.push({ path: '/plantillas', text: 'Plantillas' });
        _menus.push({ path: '/facturas', text: 'Facturación' });
        _menus.push({ path: '/admin', text: 'Admin' });
      }
      else if (applicationUser.roleName  === 'Tec') {
        _menus.push({ path: '/usuarios', text: 'Usuarios' });
        _menus.push({ path: '/parcelas', text: 'Parcelas' });
        _menus.push({ path: '/informes', text: 'Informes' });
        _menus.push({ path: '/tareas', text: 'Tareas' });

      }
      
      _menus.push({ path: '/about', text: 'Sobre Nosotros' });
     setMenus(_menus);
    }   
  }, [applicationUser]);


  function toggleNavbar() {
   setCollapsed(!collapsed);
  }

  return (
    <header>
     {
        menus && <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
        <img src="favicon.ico" alt="Wise Farmer" style={{ width: "20px", height: "20px" }}></img>
        <NavbarBrand tag={Link} to="/">Wise Farmer</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
          {menus.map((menu, index) => (
             <NavItem key={index}>
               <NavLink 
                tag={Link}                
                //className={menu.text === 'Tareas' && tasksWithTargetToday.length > 0 ? "bg-warning" : "text-dark"}
                to={menu.path}>
                  {menu.text}                  
                  {menu.text === 'Tareas' && tasksWithTargetToday.length > 0 &&                     
                    <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> 
                  }              
                </NavLink>
             </NavItem>
            ))}           
            <LoginMenu
              isAuthenticated={applicationUser?.isAuthenticated}
              userName={applicationUser?.userName}
            />              
          </ul>
        </Collapse>
      </Navbar>
     }     
    </header>
  );
};

function mapStateToProps(state: any, ownProps: any) {
  return {
    tasks: state.tasks,    
    applicationUser: state.applicationUser
  };
}

const mapDispatchToProps = {
  loadTasks,  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavMenu);

