import { ITask } from "../components/tasks/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/task";


export async function getTasks(): Promise<ITask[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function getTasksByParcelId(parcelId:number): Promise<ITask[]> {
  var endpoint = controllerName + "/tasks-parcel/" + parcelId;
  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function saveTask(task: ITask): Promise<ITask> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: task.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(task)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteTask(taskId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + taskId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
