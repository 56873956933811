import React from "react";
import PropTypes from "prop-types";
import { IRegionElement } from "./types";
import { IElement } from "../elements/types";
import Button from "../framework/Button";

type Props = {
  regionElements: IRegionElement[],
  elements: IElement[]
  onEditClick: (event: any) => void,
  onDeleteClick: (event: any) => void,
}

const RegionElementList = ({ regionElements, elements, onEditClick, onDeleteClick }: Props) => (
  <div className="scroll-container">
    {regionElements.length > 0 ? <table className="table">
      <thead>
        <tr>
          <th>Elemento</th>
          <th>Valor</th>
          <th>Enero</th>
          <th>Febrero</th>
          <th>Marzo</th>
          <th>Abril</th>
          <th>Mayo</th>
          <th>Junio</th>
          <th>Julio</th>
          <th>Agosto</th>
          <th>Septiembre</th>
          <th>Octubre</th>
          <th>Noviembre</th>
          <th>Diciembre</th>
          <th>Total Año</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {regionElements.map(regionElement => {
          return (
            <tr key={regionElement.id}>
              <td>
                {elements.find(e => e.id === regionElement.elementId)?.name}
              </td>
              <td>
                {regionElement.value}
              </td>
              <td>
                {regionElement.januaryPercentage}
              </td>
              <td>
                {regionElement.februaryPercentage}
              </td>
              <td>
                {regionElement.marchPercentage}
              </td>
              <td>
                {regionElement.aprilPercentage}
              </td>
              <td>
                {regionElement.mayPercentage}
              </td>
              <td>
                {regionElement.junePercentage}
              </td>
              <td>
                {regionElement.julyPercentage}
              </td>
              <td>
                {regionElement.augustPercentage}
              </td>
              <td>
                {regionElement.septemberPercentage}
              </td>
              <td>
                {regionElement.octoberPercentage}
              </td>
              <td>
                {regionElement.novemberPercentage}
              </td>
              <td>
                {regionElement.decemberPercentage}
              </td>
              <td>
                {(regionElement.januaryPercentage ?? 0) + (regionElement.februaryPercentage ?? 0) + (regionElement.marchPercentage ?? 0) + 
                (regionElement.aprilPercentage ?? 0) + (regionElement.mayPercentage ?? 0) + (regionElement.junePercentage ?? 0) +
                (regionElement.julyPercentage ?? 0) + (regionElement.augustPercentage ?? 0) + (regionElement.septemberPercentage ?? 0) + 
                (regionElement.octoberPercentage ?? 0) + (regionElement.novemberPercentage ?? 0) + (regionElement.decemberPercentage ?? 0) }
              </td>
              <td>
                <Button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => onEditClick(regionElement)}
                  name="Editar"
                ></Button>                
              </td>
            </tr>
          );
        })}
      </tbody>
    </table> : <div />}

  </div>
);




RegionElementList.propTypes = {
  regionElements: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default RegionElementList;
