import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IRegion } from "./types";

type Props = {
  region: IRegion,
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  onCopying: (regionId: number) => void,
  saving: boolean,
  errors: IErrors
}

const RegionForm = ({
  region,
  onSave,
  onChange,
  onCancel,
  onCopying,
  saving = false,
  errors
}: Props) => {
  return (
    <div  className="form-flex">

      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-4">
            <TextInput
              name="name"
              label="Nombre"
              value={region.name}
              onChange={onChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 4"}
            />
          </Col>
          <Col className="col-sm-1">
            <Button
                type= "submit"
                isDisabled={saving}
                className="btn btn-primary"
                name="Guardar"
                actionName="Guardando"     
                onClick={onSave}     
            ></Button>
          </Col>
          {
              region.id && region.id > 0 ?
              <Col className="col-sm-1">
                <Button
                  type="submit"
                  isDisabled={saving}
                  className="btn btn-primary"
                  name="Copiar"
                  actionName="Copiando"
                  onClick={() => onCopying(region.id!)}
                ></Button>              
              </Col>
              : <div></div>
          }
          
        </Row>
      </Container>
    </div>
  );
};

export default RegionForm;
