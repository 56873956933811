import React, { useState } from "react";
import PropTypes from "prop-types";
import { IProduct } from "../products/types";
import { IParcelProduct } from "./types";
import CheckBoxInput from "../framework/CheckBoxInput";
import TextInput from "../framework/TextInput";
import Button from "../framework/Button";

type Props = {
  allSelected: boolean,
  parcelProducts: IParcelProduct[],
  products: IProduct[],
  onSelected: (id: number | null, event: any) => void,
  onSelectedAll: (event: any) => void,
  onChanged: (id: number | null, event: any) => void,
  onIncrement: (id: number | null, event: any) => void,
  onDecrement: (id: number | null, event: any) => void,
}

const ReportProductStandarList2 = ({ allSelected, parcelProducts, products, onSelected, onSelectedAll, onChanged, onIncrement, onDecrement }: Props) => {

  return (
    <div className="scroll-container">
      {parcelProducts.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>
             <CheckBoxInput
              label=""
              value={allSelected}
              onChange={onSelectedAll}
            /></th>
            <th>Nombre</th>
            <th>Grupo</th>
            {parcelProducts[0].elements.map((item, i_index) => (
              <th key={i_index}>{item.elementSymbol}</th>
            ))}
            <th style={{ width: '15%' }}>KG/Mes</th>
            <th style={{ width: '5%' }}></th>
            <th style={{ width: '5%' }}></th>
          </tr>
        </thead>
        <tbody>
          {parcelProducts.map(parcelProduct => {
            return (
              <tr key={parcelProduct.id}>
                <td>
                  <CheckBoxInput
                    label=""
                    value={parcelProduct.isSelected}
                    onChange={(e) => onSelected(parcelProduct.id, e)}
                  />
                </td>
                <td>
                  {products.find(e => e.id === parcelProduct.productId)?.name}
                </td>
                <td>
                  {products.find(e => e.id === parcelProduct.productId)?.group}
                </td>
                {parcelProduct.elements.map((item, i_index) => (
                  <td key={i_index}>{item.percentage}%</td>
                ))}
                <td> 
                  <TextInput
                    name="calculatedValue"
                    value={parcelProduct.calculatedValue?.toString()}
                    onChange={(e) => onChanged(parcelProduct.id, e)}
                    wrapperClass={"col 1"}
                  />
                </td>
                <td>
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    name="+"
                    onClick={(e) => onIncrement(parcelProduct.id, e)}
                  ></Button>                               
                </td>
                <td>
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    name="-"
                    onClick={(e) => onDecrement(parcelProduct.id, e)}
                  ></Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}
    </div>
  );
}

ReportProductStandarList2.propTypes = {
  parcelProducts: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired
};

export default ReportProductStandarList2;
