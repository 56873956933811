import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IParcelElement, IParcelProduct, IReportViewModel } from "./types";
import { IProduct } from "../products/types";
import { IElement } from "../elements/types";
import { Col, Container, Row } from "reactstrap";
import ReportProductStandarList2 from "./ReportProductStandarList2";
import ReportElementList2 from "./ReportElementList2";
import Button from "../framework/Button";
import { IParcel } from "../parcels/types";
import { IRegionElement } from "../regions/types";
import * as reportApi from "../../api/reportApi";
import { toast } from "react-toastify";


type Props = {
    report: IReportViewModel,
    saving: boolean,
    standardParcelProducts: IParcelProduct[],
    setStandardParcelProducts: (event: any) => void,
    parcelElements: IParcelElement[],
    setParcelElements: (event: any) => void,
    regionElements: IRegionElement[],
    products: IProduct[],
    elements: IElement[],
    parcel: IParcel,
    loadRegionElements: () => Promise<void>;
    createReportViewmodel: (existingReport: IReportViewModel) => IReportViewModel,
    setSaving: (event: any) => void,
};

function ManageProductStandard2({
    report,
    saving,
    standardParcelProducts,
    setStandardParcelProducts,
    parcelElements,
    setParcelElements,
    regionElements,
    products,
    elements,
    parcel,
    loadRegionElements,
    createReportViewmodel,
    setSaving,
    ...props
}: Props) {
   
    const [allProductSelected, setAllProductSelected] = useState(false);

    useEffect(() => {
        if (parcelElements.length > 0)
            calculateCurrentTotal();
    }, [standardParcelProducts, parcelElements.length]);

    useEffect(() => {
        if (report.details?.id) {      
            standardParcelProducts.filter(pp => report.products.some(rp => rp.productId === pp.productId)).map(p => p.isSelected = true);
            setStandardParcelProducts(standardParcelProducts);
        }
    }, [report.details?.id]);

    useEffect(() => {
        if (parcel && parcel.regionId) {

            if (regionElements.length === 0) {
                loadRegionElements()
                    .catch(error => {
                        alert("Loading region elements failed" + error);
                    });
            }
            else {

                const selectedParcelElements = getParcelElementsByRegionId(report, parcel, elements, regionElements);
                if (selectedParcelElements)
                    setParcelElements(selectedParcelElements);
            }
        }
    }, [loadRegionElements, elements, parcel, regionElements, report]);


    function getParcelElementsByRegionId(report: IReportViewModel, parcel: IParcel, elements: IElement[],  regionElement: IRegionElement[]): IParcelElement[] | null {
        const regionElements = regionElement.filter(re => re.regionId === parcel.regionId) || null;
        const parcelElements: IParcelElement[] = [];

        regionElements.map((x) => {

            const element = elements.find(e => (!e.productStandardOptional) && e.id == x.elementId);
            if (element) {
                const monthPercentage = getMonthPercentage(report.details.month!.toString(), x);
                const dbElement = report.elements?.find(e => e.elementId === element.id);

                const percentage = dbElement ? dbElement.percentage : monthPercentage;
                const value = dbElement ? dbElement.value : x.value;
                const total = calculateUFTotal(value, percentage, parcel);

                parcelElements.push(
                    {
                        id: x.id,
                        elementId: x.elementId,
                        value: value,
                        percentage: percentage,
                        total: total,
                        currentTotal: 0
                    } as unknown as IParcelElement);
            }
        });

        return parcelElements;
    }

    function calculateUFTotal(totalDosis: number | null, percentage: number | null, parcel: IParcel): number {
        let value = 0;

        if (percentage != null && totalDosis != null && parcel.area != null) {
            value = (percentage / 100) * totalDosis * parcel.area;
        }

        return Number(value.toFixed(8));
    }


    function getMonthPercentage(monthId: string, regionElement: IRegionElement): number | null {
        if (monthId === "1")
            return regionElement.januaryPercentage;
        else if (monthId === "2")
            return regionElement.februaryPercentage;
        else if (monthId === "3")
            return regionElement.marchPercentage;
        else if (monthId === "4")
            return regionElement.aprilPercentage;
        else if (monthId === "5")
            return regionElement.mayPercentage;
        else if (monthId === "6")
            return regionElement.junePercentage;
        else if (monthId === "7")
            return regionElement.julyPercentage;
        else if (monthId === "8")
            return regionElement.augustPercentage;
        else if (monthId === "9")
            return regionElement.septemberPercentage;
        else if (monthId === "10")
            return regionElement.octoberPercentage;
        else if (monthId === "11")
            return regionElement.novemberPercentage;
        else if (monthId === "12")
            return regionElement.decemberPercentage;
        else
            return 0
    }

    function handleElementChange(id: number | null, event: any) {
        const { name, value } = event.target;
        const parcelElementChanged = parcelElements.find(pe => pe.id === id);

        if (parcelElementChanged) {

            const percentage = name === 'percentage' ? value : parcelElementChanged.percentage;
            const elementValue = name === 'value' ? value : parcelElementChanged.value;

            parcelElementChanged.total = calculateUFTotal(elementValue, percentage, parcel);
            const newParcelElements = parcelElements.map(pe => pe.id === id ? {
                ...pe,
                [name]: value,
            } : pe);
            setParcelElements(newParcelElements);
        }
    }

    function handleAllStandardProductSelected(event: any) {
        const newParcelProducts = standardParcelProducts.map(pp => pp.id! > 0 ? { ...pp, isSelected: !allProductSelected } : pp);
        setAllProductSelected(!allProductSelected);

        setStandardParcelProducts(newParcelProducts);
    }

    function handleStandardProductSelected(id: number | null) {
        const parcelProductChanged = standardParcelProducts.find(pp => pp.id === id);

        if (parcelProductChanged) {

            if (parcelProductChanged.isSelected)
                setAllProductSelected(false);

            const newParcelProducts = standardParcelProducts.map(pp => pp.id === id ? { ...pp, isSelected: !pp.isSelected } : pp);
            setStandardParcelProducts(newParcelProducts);
        }
    }

    function handleStandardProductChanged(id: number | null, event: any) {

        const parcelProductChanged = standardParcelProducts.find(pp => pp.id === id);

        if (parcelProductChanged) {

            parcelProductChanged.calculatedValue = event.target.value;
            const newParcelProducts = standardParcelProducts.map(pp => pp.id === id ? { ...pp, calculatedValue: Number(event.target.value) } : pp);
            setStandardParcelProducts(newParcelProducts);
        }
    }

    function handleStandardProductIncremented(id: number | null, event: any) {
        const parcelProductChanged = standardParcelProducts.find(pp => pp.id === id);

        if (parcelProductChanged) {

            if (!parcelProductChanged.calculatedValue)
                parcelProductChanged.calculatedValue = 0;

            const calculatedValue = Number((parcelProductChanged.calculatedValue + 0.1).toFixed(2));
            const newParcelProducts = standardParcelProducts.map(pp => pp.id === id ? { ...pp, calculatedValue: calculatedValue } : pp);
            setStandardParcelProducts(newParcelProducts);
        }
    }

    function handleStandardProductDecremented(id: number | null, event: any) {
        const parcelProductChanged = standardParcelProducts.find(pp => pp.id === id);

        if (parcelProductChanged) {

            if (!parcelProductChanged.calculatedValue)
                parcelProductChanged.calculatedValue = 0;

            const calculatedValue = Number((parcelProductChanged.calculatedValue - 0.1).toFixed(2));
            const newParcelProducts = standardParcelProducts.map(pp => pp.id === id ? { ...pp, calculatedValue: calculatedValue } : pp);
            setStandardParcelProducts(newParcelProducts);
        }
    }


    function handleStandardProductCalculatedValue(productId: number | null, calculatedValue: number | null) {
        const parcelProductChanged = standardParcelProducts.find(pp => pp.productId === productId);

        if (parcelProductChanged) {
            parcelProductChanged.calculatedValue = Number(calculatedValue?.toFixed(2));
        }
    }


    function calculateCurrentTotal() {

        const newParcelElements: IParcelElement[] = [];

        parcelElements.forEach(pe => {

            let currentTotal = 0;

            standardParcelProducts.filter(p => p.calculatedValue && p.calculatedValue !== 0).forEach(p => {
                const element = p.elements.find(e => e.elementId === pe.elementId);
                if (element && element.percentage && element.percentage > 0) {
                    currentTotal! += element.percentage! * p.calculatedValue! / 100;
                    currentTotal = Number(currentTotal?.toFixed(2));
                }
            });

            newParcelElements.push({ ...pe, currentTotal });
        });

        setParcelElements(newParcelElements);
    }

    function handleCalculateButton(event: any) {
        if (event)
            event.preventDefault();

        const newReport = createReportViewmodel(report);

        setSaving(true);
        calculateProductStandardValues(newReport);
    }

    function calculateProductStandardValues(report: IReportViewModel) {
        //eslint-disable-next-line no-unused-vars
        return reportApi
            .calculateStandardProductValues(report)
            .then(savedReport => {
                toast.success("Valores de Produtos calculados");

                standardParcelProducts.forEach(p => p.calculatedValue = 0);
                savedReport.products.filter(p => p.kgXMonth && p.kgXMonth !== 0).map(p => handleStandardProductCalculatedValue(p.productId, p.kgXMonth));
                calculateCurrentTotal();
                setSaving(false);
            })
            .catch(error => {
                toast.error("Error: Valores no pueden ser calculados");
                setSaving(false);
                throw error;
            });
    }
   
    return (
        <div>        
            <Container>
                <Row>
                    <Col>
                        <h4>Productos Standard</h4>
                        <ReportProductStandarList2
                            allSelected={allProductSelected}
                            products={products}
                            parcelProducts={standardParcelProducts}
                            onSelected={handleStandardProductSelected}
                            onSelectedAll={handleAllStandardProductSelected}
                            onChanged={handleStandardProductChanged}
                            onIncrement={handleStandardProductIncremented}
                            onDecrement={handleStandardProductDecremented}
                        />
                    </Col>
                    <Col>
                        <h4>Elementos</h4>
                        <ReportElementList2
                            elements={elements}
                            parcelElements={parcelElements}
                            monthId={report.details.month!.toString()}
                            onChange={handleElementChange}
                        />
                        <Button
                            type="button"
                            isDisabled={saving ||
                                !report.details.month ||
                                parcelElements.length === 0 ||
                                standardParcelProducts.filter(pf => pf.isSelected).length === 0}
                            className="btn btn-primary"
                            name="Calcular"
                            onClick={handleCalculateButton}
                        ></Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}




ManageProductStandard2.propTypes = {
    report: PropTypes.object.isRequired,
    saving: PropTypes.bool.isRequired,
    standardParcelProducts: PropTypes.array.isRequired,
    parcelElements: PropTypes.array.isRequired,
    regionElements: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    elements: PropTypes.array.isRequired,
    parcel: PropTypes.object.isRequired,
    loadRegionElements: PropTypes.func.isRequired,
    createReportViewmodel: PropTypes.func.isRequired,
};


export default ManageProductStandard2;

