import { IDistributor, IDistributorProduct, IDistributorVieWModel } from "../components/distributors/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/distributor";


export async function getDistributors(): Promise<IDistributor[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveDistributor(distributor: IDistributor): Promise<IDistributor> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: distributor.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(distributor)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteDistributor(distributorId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + distributorId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getDistributorProducts(distributorId: number): Promise<IDistributorProduct[]> {

  const endpoint = controllerName + "/products/" + distributorId;

  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveDistributorWithProducts(Distributor: IDistributorVieWModel): Promise<IDistributorVieWModel> {
  const token = await authService.getAccessToken();

  const endpoint = controllerName + "/products/"

  const response = await fetch(endpoint,
    {
      method: Distributor?.distributor?.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(Distributor)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
