import { IParcel } from "../components/parcels/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/parcel";


export async function getParcels(): Promise<IParcel[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveParcel(parcel: IParcel): Promise<IParcel> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: parcel.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(parcel)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function deleteParcel(parcelId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + parcelId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
