import * as types from "./actionTypes";
import * as distributorApi from "../../api/distributorApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadDistributorsSuccess(distributors) {
  return { type: types.LOAD_DISTRIBUTORS_SUCCESS, distributors };
}

export function deleteDistributorOptimistic(distributor) {
  return { type: types.DELETE_DISTRIBUTOR_OPTIMISTIC, distributor };
}

export function createDistributorSuccess(distributor) {
  return { type: types.CREATE_DISTRIBUTOR_SUCCESS, distributor };
}

export function updateDistributorSuccess(distributor) {
  return { type: types.UPDATE_DISTRIBUTOR_SUCCESS, distributor };
}


export function loadDistributors() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return distributorApi
      .getDistributors()
      .then(distributors => {
        dispatch(loadDistributorsSuccess(distributors));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveDistributor(distributor) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return distributorApi
      .saveDistributor(distributor)
      .then(savedDistributor => {
        distributor.id
          ? dispatch(updateDistributorSuccess(savedDistributor))
          : dispatch(createDistributorSuccess(savedDistributor));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function saveDistributorWithProducts(distributor) {
  //eslint-disable-next-line no-unused-vars
  return function (dispatch, getState) {
    dispatch(beginApiCall());
    return distributorApi
      .saveDistributorWithProducts(distributor)
      .then(savedDistributor => {
        distributor.distributor.id
          ? dispatch(updateDistributorSuccess(savedDistributor.distributor))
          : dispatch(createDistributorSuccess(savedDistributor.distributor));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}



export function deleteDistributor(distributorId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteDistributorOptimistic(distributorId));
    return distributorApi.deleteDistributor(distributorId);
  };
}