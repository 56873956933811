import React, { useState } from "react";
import PropTypes from "prop-types";
import { IProduct } from "../products/types";
import { IParcelProduct } from "./types";
import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { GetDosisProductsGroups } from "../utils/constants";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { IParcel } from "../parcels/types";
import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import { toast } from "react-toastify";

type Props = {
  parcel: IParcel,
  parcelProducts: IParcelProduct[],
  products: IProduct[],
  setDoseParcelProducts: (event: any) => void,
}

const ReportProductDosisPlantList2 = ({ parcel, parcelProducts, products, setDoseParcelProducts }: Props) => {

  const [errors] = useState(newError);
  const productGroups = GetDosisProductsGroups()
  const [productId, setProductId] = useState(0);
  const [productIndex, setProductIndex] = useState(-100);

  function handleDosedProductChange(id: number | null, event: any) {
    const { name, value } = event.target;
    const productChanged = parcelProducts.find(pe => pe.id === id);

    if (productChanged) {

      let doseValue = 0;
      let groupValue = "";
      if (name === "dose") {
        doseValue = value;
        groupValue = productChanged.group ?? "";
      }

      if (!doseValue)
        productChanged.doseTotal = 0;
      else {        
        if (groupValue === "Grupo E") {
          if (parcel.plantsNumber)
            productChanged.doseTotal = Number((doseValue * parcel.plantsNumber).toFixed(2));
          else
            productChanged.doseTotal = 0;
        }
        else
          productChanged.doseTotal = 0;
      }

      const newDosedParcelProducts = parcelProducts.map(pe => pe.id === id ? {
        ...pe,
        [name]: value,
      } : pe);
      setDoseParcelProducts(newDosedParcelProducts);
    }
  }

  function handleNewProduct(event: any) {

    const product = products.find(p => p.id === productId);

    if (parcelProducts.some(p => p.productId === productId && p.group === "Grupo E"))
    {
      toast.error("Error. Producto duplicado");
      return;
    }

    const newProduct = {
      id: productIndex,
      productId: productId,
      dose: 0,
      group: "Grupo E",
      isSelected: true,
      productName: product?.name,
      //elements: IParcelProductElement[],
      doseTotal: 0,
      doseXPlant: 0,
      cost: 0,
      calculatedValue: 0
    } as unknown as IParcelProduct;
    const newDoseProducts = [...parcelProducts, newProduct];
    setDoseParcelProducts(newDoseProducts);
    setProductIndex(productIndex - 1);
  }

  function handleDeleteProduct(parcelProductId: number) {

    const newDoseProducts = [...parcelProducts.filter(p => p.id !== parcelProductId)];
    setDoseParcelProducts(newDoseProducts);
  }

  return (
    <div className="scroll-container">
      <Container>
        <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-3">
            <SelectInput
              name="products"
              value={productId}
              defaultOption="Seleccione Producto"
              options={products.filter(p => p.type === 'Dosis por Hectárea').map(pg => ({
                value: pg.id,
                text: pg.name
              } as SelectOption))}
              onChange={(e) => setProductId(products.find(p => p.id?.toString() === e.target.value)?.id ?? 0)}
            />
          </Col>          
          <Col className="col-sm-3">
            <Button
              type="button"
              className="btn btn-primary"
              name="Añadir Producto"
              onClick={handleNewProduct}
              isDisabled={productId === 0}
            ></Button>
          </Col>
        </Row>
      </Container>      
      {parcelProducts.length > 0 &&
        <div>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Nombre</th>
                <th style={{ width: '25%' }}>Dosis</th>
                <th style={{ width: '25%' }}>Dosis Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {parcelProducts.filter(p => p.group === "Grupo E").map(parcelProduct => {
                return (
                  <tr key={parcelProduct.id}>
                    <td>
                      {products.find(e => e.id === parcelProduct.productId)?.name}
                    </td>
                    <td>
                      <TextInput
                        name="dose"
                        value={parcelProduct.dose?.toString()}
                        onChange={(e) => handleDosedProductChange(parcelProduct.id, e)}
                        errors={errors.fieldErrors}
                        wrapperClass={"col 1"}
                      />
                    </td>
                    <td>{parcelProduct.doseTotal}</td>
                   <td>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        name="Borrar"
                        onClick={(e) => handleDeleteProduct(parcelProduct.id!)}
                      ></Button>
                   </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

ReportProductDosisPlantList2.propTypes = {
  parcel: PropTypes.object.isRequired,
  parcelProducts: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  setDoseParcelProducts: PropTypes.func.isRequired,
};

export default ReportProductDosisPlantList2;
