import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadParcels } from "../../redux/actions/parcelsActions";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { months, newParcel, newReport, newUser } from "../../tools/mockData";
import { IParcel } from "../parcels/types";
import { Col, Container, Row } from "reactstrap";
import { IRegionElement } from "../regions/types";
import { loadRegionElements } from "../../redux/actions/regionElementsActions";
import { loadElements } from "../../redux/actions/elementsActions";
import { IElement } from "../elements/types";
import { IParcelElement, IParcelProduct, IReportDetails, IReportViewModel, IReportElement, IReportProduct } from "./types";
import { IProduct, IProductElement } from "../products/types";
import { loadProducts } from "../../redux/actions/productsActions";
import { loadProductElements } from "../../redux/actions/productElementsActions";
import { toast } from "react-toastify";
import ReportProductDosisHAList2 from "./ReportProductDosisHaList2";
import * as reportApi from "../../api/reportApi";
import ReportInfo2 from "./ReportInfo2";
import { loadUsers } from "../../redux/actions/usersActions";
import { IUser } from "../users/types";
import ReportFoliarTreatment2 from "./ReportFoliarTreatment2";
import TextArea from "../framework/TextArea";
import ReportTemplateForm2 from "./ReportTemplateForm2";
import ManageProductStandard2 from "./ManageProductStandard2";
import ReportProductDosisPlantList2 from "./ReportProductDosisPlantList2";
import AdminContainer from "../framework/AdminContainer";

type Props = {
  parcels: IParcel[],
  users: IUser[],
  regionElements: IRegionElement[],
  elements: IElement[],
  products: IProduct[],
  productElements: IProductElement[],
  loadParcels: () => Promise<void>;
  loadUsers: () => Promise<void>;
  loadRegionElements: () => Promise<void>;
  loadElements: () => Promise<void>;
  loadProducts: () => Promise<void>;
  loadProductElements: () => Promise<void>;
  saveTemplateWithProducts: (event: any) => Promise<void>;
};

function ManageGenerateReportPage2({
  parcels,
  users,
  regionElements,
  elements,
  products,
  productElements,
  loadParcels,
  loadUsers,
  loadRegionElements,
  loadElements,
  loadProducts,
  loadProductElements,
  ...props
}: Props) {

  const params = useParams();

  const emptyParcelProducts: IParcelProduct[] = [];
  const emptyFoliarProducts: IParcelProduct[] = [];

  const [report, setReport] = useState(newReport);
  const [monthId, setMonthId] = useState("")
  const [parcel, setParcel] = useState(newParcel);
  const [user, setUser] = useState(newUser);

  const [parcelElements, setParcelElements] = useState([] as IParcelElement[]);
  const [standardParcelProducts, setStandardParcelProducts] = useState([] as IParcelProduct[]);
  const [dosisParcelProducts, setDosedParcelProducts] = useState(emptyParcelProducts)
  const [foliarObservations, setFoliarObservations] = useState("")
  const [foliarProducts, setFoliarProducts] = useState(emptyFoliarProducts)
  const [comment, setComment] = useState("");
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const parcelId = params.parcelId;
  let reportId = params.reportId;

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          alert("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);

  useEffect(() => {
    if (users.length === 0) {
      loadUsers()
        .catch(error => {
          alert("Loading users failed" + error);
        });
    }
  }, [loadUsers, users.length]);

  useEffect(() => {
    if (elements.length === 0) {
      loadElements()
        .catch(error => {
          alert("Loading elements failed" + error);
        });
    }
  }, [loadElements, elements.length]);

  useEffect(() => {
    if (productElements.length === 0) {
      loadProductElements()
        .catch(error => {
          alert("Loading product elements failed" + error);
        });
    }
  }, [loadProductElements, productElements.length]);


  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          alert("Loading products failed" + error);
        });
    }
  }, [loadProducts, products]);

  useEffect(() => {
    if (parcels.length > 0 && users.length > 0) {
      const parcelSelected = parcelId && parcels.length > 0 ? getParcelById(parcels, parcelId) : newParcel;
      if (parcelSelected) {
        setParcel(parcelSelected);

        const userSelected = users.find(u => u.id === parcelSelected.userId);
        if (userSelected)
          setUser(userSelected);
      }
    }
  }, [parcels, parcelId, users]);


  //Load Parcel Products & Parcel Elements
  useEffect(() => {

    if (products.length > 0 && productElements.length > 0 && elements.length > 0 && ((reportId && report.details.id) || !reportId)) {
      const selectedParcelProducts = getParcelProducts(report, monthId, parcel, products, productElements, elements);

      if (selectedParcelProducts) {

        const standardProducts = products.filter(p => p.type === "Standard");
        const standardParcelProductsx = selectedParcelProducts.filter(pp =>
          standardProducts.some(sp => sp.id === pp.productId));

        setStandardParcelProducts(standardParcelProductsx);

        const foliarProducts = [] as IParcelProduct[];

        products.forEach(p => {
          foliarProducts.push({
            dose: 0,
            doseTotal: 0,
            group: "Grupo F",
            isSelected: false,
            productId: p.id,
            id: p.id,
            productName: p.name,
          } as IParcelProduct);
        });

        if (report) {
          foliarProducts.forEach(p => {

            let dbFoliarProduct = report.foliarProducts.find(rp => rp.productId === p.productId);
            if (dbFoliarProduct) {
              p.dose = dbFoliarProduct.dose ?? 0;
              p.doseTotal = dbFoliarProduct.doseTotal ?? 0;
              p.isSelected = true;
            }
          });

          setFoliarObservations(report.details.foliarObservations ?? "");
          setComment(report.details.comment ?? "");
        }
        setFoliarProducts(foliarProducts);
      }

      var doseProducts = getParcelDoseProducts(report, monthId, parcel, products, productElements, elements);
      setDosedParcelProducts(doseProducts);
    }
  }, [elements, loadProducts, productElements, products, monthId, parcel, report, reportId]);

  useEffect(() => {
    if (reportId) {
      reportApi
        .getReportById(Number(reportId))
        .then(dbReport => {
          if (dbReport) {
            setReport(dbReport)
          }
        })
        .catch(error => {
          toast.error("Error loading reports");
          setSaving(false);
          throw error;
        });

    }
  }, [reportId]);

  useEffect(() => {
    if (report && report.details?.id) {
      setMonthId(report.details.month?.toString()!);
    }
  }, [report]);


  function handleMonthChange(event: any) {
    const { value } = event.target;
    setReport({ ...report, details: { ...report.details, month: value } })
    setMonthId(value);
  }

  function handleCommentChanged(event: any): void {
    const { name, value } = event.target;
    setComment(value);
  }

  function handleGenerateButton(event: any) {
    if (event)
      event.preventDefault();
    generateReport(report);
  }

  function createReportViewmodel(existingReport: IReportViewModel): IReportViewModel {
    const reportDetails = {
      id: reportId ?? 0,
      month: Number(monthId),
      parcelId: Number(parcelId),
      insertDate: existingReport?.details.insertDate ?? new Date(),
      plantsNumber: parcel.plantsNumber,
      area: parcel.area,
      comment: comment,
      foliarObservations: foliarObservations,
      distributorId: user?.distributorId
    } as IReportDetails;

    const selectedStandardProducts = [] as unknown as IReportProduct[];
    const selectedDoseProducts = [] as unknown as IReportProduct[];
    const selectedFoliarProducts = [] as unknown as IReportProduct[];
    const selectedElements = [] as unknown as IReportElement[];

    standardParcelProducts.filter(p => p.isSelected).map(p =>
      selectedStandardProducts.push(
        {
          id: 0,
          reportId: 0,
          group: p.group,
          productId: p.productId,
          kgXMonth: p.calculatedValue
        } as unknown as IReportProduct)
    );

    dosisParcelProducts.filter(p => p.isSelected).map(p =>
      selectedDoseProducts.push(
        {
          id: 0,
          reportId: 0,
          group: p.group,
          productId: p.productId,
          dose: p.dose,
          doseTotal: p.doseTotal,
        } as unknown as IReportProduct)
    );

    foliarProducts.filter(p => p.isSelected).map(p =>
      selectedFoliarProducts.push(
        {
          id: 0,
          reportId: 0,
          group: p.group,
          productId: p.productId,
          doseTotal: p.doseTotal,
        } as unknown as IReportProduct)
    );

    parcelElements.map(e =>
      selectedElements.push(
        {
          id: 0,
          reportId: 0,
          elementId: e.elementId,
          value: e.value,
          percentage: e.percentage,
          ufCurrentTotal: e.currentTotal,
          ufTotal: e.total
        } as unknown as IReportElement)
    );

    const report = {
      details: reportDetails,
      products: selectedStandardProducts,
      doseProducts: selectedDoseProducts,
      elements: selectedElements,
      foliarProducts: selectedFoliarProducts
    } as IReportViewModel;

    return report;
  }

  function generateReport(existingReport: IReportViewModel) {

    setSaving(true);
    const report = createReportViewmodel(existingReport);

    reportApi
      .createReport(report)
      .then(savedReport => {
        toast.success((report.details.id ?? 0) > 0 ? "Informe Editado" : "Informe Creado");
        setSaving(false);
        navigate('/parcela/' + parcel.id + '/crearinforme/' + savedReport.details.id);
      })
      .catch(error => {
        toast.error("Error creando informe");
        setSaving(false);
        throw error;
      });
  }


  return (
    <div>
      <Container >
        <h4>Informe de Parcela</h4>
        <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <ReportInfo2
            report={report}
            months={months}
            parcel={parcel}
            user={user}
            handleGenerateButton={handleGenerateButton}
            handleMonthChange={handleMonthChange}
            isGenerateButtonDisabled={saving ||
              !monthId ||
              parcelElements.length === 0 ||
              standardParcelProducts.filter(pf => pf.isSelected).length === 0 ||
              !standardParcelProducts.some(pf => pf.calculatedValue) ||
              (parcel.billingMonthAmount ?? 0) <= 0}
          />
        </Row>
        {monthId &&
          <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <ManageProductStandard2
              elements={elements}
              products={products}
              parcel={parcel}
              regionElements={regionElements}
              loadRegionElements={loadRegionElements}
              standardParcelProducts={standardParcelProducts}
              setStandardParcelProducts={setStandardParcelProducts}
              parcelElements={parcelElements}
              setParcelElements={setParcelElements}
              createReportViewmodel={createReportViewmodel}
              report={report}
              saving={saving}
              setSaving={setSaving}
            />
          </Row>
        }
        {
          monthId &&
          <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <h4>Productos Dosis Por Hectárea</h4>
            <ReportProductDosisHAList2
              parcel={parcel}
              products={products}
              parcelProducts={dosisParcelProducts}
              setDoseParcelProducts={setDosedParcelProducts}
            />
          </Row>
        }
        {
          monthId &&
          <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <h4>Productos Dosis Por Planta</h4>
            <ReportProductDosisPlantList2
              parcel={parcel}
              products={products}
              parcelProducts={dosisParcelProducts}
              setDoseParcelProducts={setDosedParcelProducts}
            />
          </Row>
        }
        {
          monthId &&
          <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <h4>Tratamiento Foliar</h4>
            <ReportFoliarTreatment2
              parcel={parcel}
              products={products}
              observations={foliarObservations}
              foliarProducts={foliarProducts}
              setFoliarProducts={setFoliarProducts}
              setFoliarObservations={setFoliarObservations}
            />
          </Row>
        }
        {
          monthId &&
          <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <h4>Observaciones</h4>
            <Container>
              <Row>
                <Col>
                  <TextArea
                    name="comment"
                    value={comment || ""}
                    onChange={handleCommentChanged}
                    wrapperClass={"col 1"}
                  />
                </Col>
              </Row>
            </Container>
          </Row>
        }
        {
          monthId &&
          <AdminContainer>          
            <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
              <h4>Plantilla</h4>
              <Container>
                <Row>
                  <Col>
                    <ReportTemplateForm2
                      monthId={Number(monthId)}
                      regionId={parcel.regionId}
                      comment={comment}
                      foliarObservations={foliarObservations}
                      standardProducts={standardParcelProducts}
                      dosisProducts={dosisParcelProducts}
                      foliarProducts={foliarProducts}
                    />
                  </Col>
                </Row>
              </Container>
            </Row>
          </AdminContainer>
        }
      </Container>
    </div >
  );
}

export function getParcelById(parcels: IParcel[], id: string): IParcel | null {
  return parcels.find(parcel => parcel.id?.toString() === id) || null;
}

export function getParcelProducts(report: IReportViewModel, monthId: string, parcel: IParcel, products: IProduct[], productElements: IProductElement[], elements: IElement[]): IParcelProduct[] | null {
  const parcelProducts: IParcelProduct[] = [];

  products.filter(p => p.type === "Standard").map((x) => {

    let dbReportProduct: IReportProduct | undefined;

    if (x.type !== "Dosis por Hectárea") {
      if (report.details?.id) {
        if (x.group === "Grupo A" || x.group === "Grupo B")
          dbReportProduct = report.details?.id ? report.products.find(p => p.productId === x.id) : null as unknown as IReportProduct;
      }

      parcelProducts.push(
        {
          id: x.id,
          isSelected: dbReportProduct != null,
          productId: x.id,
          productName: x.name,
          elements: [],
          group: dbReportProduct != null ? dbReportProduct.group! : x.group,
          cost: x.cost,
          calculatedValue: dbReportProduct != null ? dbReportProduct.kgXMonth : 0
        } as unknown as IParcelProduct);
    }
  });

  productElements.map(pe => {
    const parcelProduct = parcelProducts.find(p => p.productId === pe.productId);


    const element = elements.filter(e => (!e.productStandardOptional)).find(e => e.id === pe.elementId);

    if (element) {
      parcelProduct?.elements.push({
        elementId: element?.id ?? 0,
        elementName: element?.name ?? "",
        elementSymbol: element?.symbol ?? "",
        percentage: pe.percentage,
        productStandardOptional: element?.productStandardOptional ?? false
      });
    }
  });

  return parcelProducts;
}

function getParcelDoseProducts(report: IReportViewModel, monthId: string, parcel: IParcel, products: IProduct[], productElements: IProductElement[], elements: IElement[]): IParcelProduct[] {
  const doseProducts = [] as IParcelProduct[];
  if (report.details?.id) {

    report.doseProducts.map(x => {
      doseProducts.push(
        {
          id: x.id,
          isSelected: true,
          productId: x.productId,
          productName: products.find(p => p.id === x.productId)?.name,
          elements: [],
          group: x.group,
          cost: 0,
          dose: x.group === "Grupo B" ? (x.doseTotal! / parcel.area!).toFixed(2) : (x.doseTotal! / parcel.plantsNumber!).toFixed(2),
          calculatedValue: x.kgXMonth ?? 0,
          doseTotal: Number(x.doseTotal!.toFixed(2))
        } as unknown as IParcelProduct);
    });
  }
  return doseProducts;
}


ManageGenerateReportPage2.propTypes = {
  regionElements: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  loadParcels: PropTypes.func.isRequired,
  loadRegionElements: PropTypes.func.isRequired,
  loadElements: PropTypes.func.isRequired,
};


function mapStateToProps(state: any, ownProps: any) {
  return {
    parcels: state.parcels,
    users: state.users,
    regionElements: state.regionElements,
    elements: state.elements,
    products: state.products,
    productElements: state.productElements,
  };
}

const mapDispatchToProps = {
  loadParcels,
  loadUsers,
  loadProducts,
  loadRegionElements,
  loadElements,
  loadProductElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGenerateReportPage2);
