import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function distributorProductReducer(state = initialState.distributorProducts, action) {
  switch (action.type) {
    case types.CREATE_DISTRIBUTOR_PRODUCT_SUCCESS:
      return [...state, { ...action.distributorProduct }];
    case types.UPDATE_DISTRIBUTOR_PRODUCT_SUCCESS:
      return state.map(distributorProduct =>
        distributorProduct.id === action.distributorProduct.id ? action.distributorProduct : distributorProduct
      );
    case types.LOAD_DISTRIBUTOR_PRODUCTS_SUCCESS:
      return action.distributorProducts;
    case types.DELETE_DISTRIBUTOR_PRODUCT_OPTIMISTIC:
      return state.filter(distributorProduct => distributorProduct.id !== action.distributorProductId);
    default:
      return state;
  }
}
