import { IGenerateTemplateReportsResponse, ITemplate, ITemplateProduct, ITemplateVieWModel } from "../components/templates/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/template";


export async function getTemplates(): Promise<ITemplate[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getTemplateProducts(templateId: number): Promise<ITemplateProduct[]> {

  const endpoint = controllerName + "/products/" + templateId;

  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveTemplate(Template: ITemplate): Promise<ITemplate> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: Template.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(Template)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function generateReports(templateId: number): Promise<IGenerateTemplateReportsResponse> {
  const token = await authService.getAccessToken();

  const endpoint = controllerName + "/generateReports/" + templateId

  const response = await fetch(endpoint,
    {
      method: "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveTemplateWithProducts(Template: ITemplateVieWModel): Promise<ITemplateVieWModel> {
  const token = await authService.getAccessToken();

  const endpoint = controllerName + "/products/"

  const response = await fetch(endpoint,
    {
      method: Template?.template?.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(Template)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}



export async function deleteTemplate(TemplateId: number) {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName + "/" + TemplateId,
    {
      method: "Delete",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}
