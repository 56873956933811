import { combineReducers } from "redux";
import maze from "./mazeReducer";
import users from "./userReducer";
import parcels from "./parcelReducer";
import elements from "./elementReducer";
import regions from "./regionReducer";
import regionElements from "./regionElementReducer";
import products from "./productReducer";
import productElements from "./productElementReducer";
import reports from "./reportReducer";
import templates from "./templateReducer";
import billing from "./billingReducer";
import billingParcels from "./billingParcelReducer";
import distributors from "./distributorReducer";
import distributorProducts from "./distributorProductReducer";
import tasks from "./tasksReducer";
import applicationUser from "./applicationUserReducer";

const rootReducer = combineReducers({
    maze,
    users,
    parcels,
    elements,
    regions,
    regionElements,
    products,
    productElements,
    reports,
    templates,
    billing,
    billingParcels,
    distributors,
    distributorProducts,
    tasks,    
    applicationUser
});

export default rootReducer;
