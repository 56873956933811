import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../framework/Spinner";
import UserList from "./UsersList";
import { Navigate } from "react-router-dom";
import Button from "../framework/Button";
import { Container, Row } from "reactstrap";
import { IUser } from "./types";
import { IDistributor } from "../distributors/types";
import { loadUsers } from "../../redux/actions/usersActions";
import { loadDistributors } from "../../redux/actions/distributorActions";
import { ROLE_ADMIN } from "../utils/constants";

type Props = {
  users: IUser[];
  distributors: IDistributor[],
  loadUsers: () => Promise<void>,
  loadDistributors: () => Promise<void>,  
};


function UsersPage({
  users,
  distributors,
  loadUsers,  
  loadDistributors,
  ...props
}: Props) {

  const [redirectToAddUserPage, setRedirectToAddUserPage] = useState(false);
  const [loading, setLoading] = useState(false);

  if (users.length === 0) {    
    loadUsers()
    .then(response => {
      setLoading(false);     
    })
    .catch(error => {
      console.error("Loading users failed" + error);
    });
  } 

  if (distributors.length === 0) {
    loadDistributors().catch(error => {
      console.error("Loading distributors failed" + error);
    });
  }
 
    return (
      <>
        <Container>       
          <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            {redirectToAddUserPage && <Navigate to="/usuario" />}
            <h4>Usuarios</h4>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  type="submit"
                  className="btn btn-primary add-course"
                  onClick={() => setRedirectToAddUserPage(true)}
                  name="Crear Usuario"
                  roles = {["", ROLE_ADMIN]}
                ></Button>
                {
                  <UserList
                    distributors={distributors}
                    users={users}
                  />
                }
              </>
            )}
          </Row>
        </Container>
      </>
    );  
}

UsersPage.propTypes = {
  users: PropTypes.array.isRequired,
  distributors: PropTypes.array.isRequired,  
};


function mapStateToProps(state: any, ownProps: any) {
  return {    
    distributors: state.distributors,
    users: state.users
  };
}

const mapDispatchToProps = {
  loadUsers,
  loadDistributors
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPage);
