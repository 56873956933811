import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IBilling } from "./types";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { months } from "../../tools/mockData";

type Props = {
  billing: IBilling,
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const BillingForm = ({
  billing,
  onSave,
  onChange,
  saving = false,
  errors
}: Props) => {


  const years: SelectOption[] = [
    { value: 2024, text: "2024" },
    { value: 2023, text: "2023" },
  ];

  return (
    <div  className="form-flex">

      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}      

      <Container>
        <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-4">
            <SelectInput
                name="year"
                label="Año"
                value={billing.year}
                defaultOption=""
                options={years.map(month => ({
                  value: month.value,
                  text: month.text
                } as SelectOption))}
                onChange={onChange}
                isReadOnly={!!billing.id}
                wrapperClass={"col 4"}
              />
          </Col>
          <Col className="col-sm-4">
            <SelectInput
                name="month"
                label="Mes"
                value={billing.month}
                defaultOption=""
                options={months.map(month => ({
                  value: month.id,
                  text: month.name
                } as SelectOption))}
                onChange={onChange}
                isReadOnly={!!billing.id}
                wrapperClass={"col 4"}
              />
          </Col>
          </Row>
          {  
          !!billing.id ?
          <Row className="container-row" style={{ alignItems: "flex-end" }}>           
            <Col className="col-sm-4">
              <TextInput
                name="totalAmount"
                label="Total"
                value={billing.totalAmount?.toString()}
                isReadOnly={true}
                onChange={onChange}
                errors={errors.fieldErrors}
                wrapperClass={"col 6"}
              />
            </Col>
            <Col className="col-sm-4">
              <TextInput
                name="notes"
                label="Notas"
                value={billing.notes?.toString()}
                onChange={onChange}
                errors={errors.fieldErrors}
                wrapperClass={"col 6"}
              />
          </Col> 
          </Row> : <div />
          }
        <Row className="container-row" style={{ alignItems: "flex-end" }}>      
          <Col className="col-sm-1">            
            <Button
                type= "submit"
                isDisabled={saving}
                className="btn btn-primary"
                name={!billing.id ? "Crear" : "Guardar"}                
                actionName={!billing.id ? "Creando" : "Guardar"}         
                onClick={onSave}     
            ></Button>
          </Col>          
        </Row>
      </Container>
    </div>
  );
};

export default BillingForm;
