import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadParcels, saveParcel } from "../../redux/actions/parcelsActions";
import { loadUsers } from "../../redux/actions/usersActions";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { IParcel } from "./types";
import { newError, newParcel, newReport } from "../../tools/mockData";
import ParcelForm from "./ParcelForm";
import { IRegion } from "../regions/types";
import { loadRegions } from "../../redux/actions/regionsActions";
import { Container, Row } from "reactstrap";
import { deleteReport } from "../../redux/actions/reportsActions";
import { IReportViewModel } from "../createReport2/types";
import ParcelReportList from "./ParcelReportList";
import * as reportApi from "../../api/reportApi";
import * as taskApi from "../../api/taskApi";
import ReportForm from "../reports/ReportForm";
import { IUser } from "../users/types";
import { IProduct } from "../products/types";
import { loadProducts } from "../../redux/actions/productsActions";
import ParcelFormRO from "./ParcelFormRO";
import CheckBoxInput from "../framework/CheckBoxInput";
import { IElement } from "../elements/types";
import { loadElements } from "../../redux/actions/elementsActions";
import { loadDistributors } from "../../redux/actions/distributorActions";
import ParcelCarousel from "./ParcelCarousel";
import { IDistributor } from "../distributors/types";
import TaskList from "../tasks/TaskList";
import { ITask } from "../tasks/types";
import TaskForm from "../tasks/TaskForm";
import { loadTasks, saveTask } from "../../redux/actions/tasksActions";
import Button from "../framework/Button";
import YearReportForm from "../reports/YearReportForm";

type Props = {
  parcels: IParcel[],
  regions: IRegion[],
  reports: IReportViewModel[],
  users: IUser[],
  products: IProduct[],
  elements: IElement[],
  distributors: IDistributor[],
  tasks: ITask[],
  loadParcels: () => Promise<void>,
  loadRegions: () => Promise<void>,
  loadUsers: () => Promise<void>,
  loadProducts: () => Promise<void>,
  loadElements: () => Promise<void>,
  loadDistributors: () => Promise<void>,
  loadTasks: () => Promise<void>,
  saveParcel: (event: any) => Promise<void>
  deleteReport: (event: any) => Promise<void>,
  saveTask: (event: any) => Promise<void>
}

function ManageParcelPage({
  parcels,
  regions,
  reports,
  users,
  products,
  elements,
  distributors,
  tasks,
  loadParcels,
  loadUsers,
  loadRegions,
  loadProducts,
  loadElements,
  loadDistributors,
  loadTasks,
  saveParcel,
  deleteReport,
  saveTask,
  ...props
}: Props) {

  const emptyTasks: ITask[] = [];
  const emptyReports: IReportViewModel[] = [];
  const [parcel, setParcel] = useState(newParcel);
  const [errors, setErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const [parcelReports, setParcelReports] = useState(emptyReports);
  const [parcelTasks, setParcelTasks] = useState(emptyTasks);
  const [taskId, setTaskId] = useState(0);
  const [createTask, setCreateTask] = useState(false);
  const params = useParams();
  const [reportToShow, setReportToShow] = useState(newReport);
  const [showReport, setShowReport] = useState(false);
  const [showAnualReport, setShowAnualReport] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const parcelId = params.id;

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          console.error("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);


  useEffect(() => {
    if (users.length === 0) {
      loadUsers()
        .catch(error => {
          console.error("Loading users failed" + error);
        });
    }
  }, [loadUsers, users.length]);

  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          console.error("Loading products failed" + error);
        });
    }
  }, [loadProducts, products.length]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
  }, [loadRegions, regions.length]);

  useEffect(() => {
    if (elements.length === 0) {
      loadElements()
        .catch(error => {
          console.error("Loading elements failed" + error);
        });
    }
  }, [loadElements, elements.length]);

  useEffect(() => {
    if (distributors.length === 0) {
      loadDistributors()
        .catch(error => {
          console.error("Loading distributors failed" + error);
        });
    }
  }, [loadDistributors, distributors.length]);

  useEffect(() => {
    if (parcels.length > 0) {
      const parcelSelected = parcelId && parcels.length > 0 ? getParcelById(parcels, parcelId) : newParcel;
      if (parcelSelected)
        setParcel(parcelSelected);
    }
  }, [parcels, parcelId]);



  useEffect(() => {
    if (parcel && parcel.id) {
      reportApi
        .getReportByParcelId(parcel.id, showAllReports)
        .then(reports => {
          setParcelReports(reports);
        })
        .catch(error => {
          toast.error("Error loading reports");
          setSaving(false);
          throw error;
        });
    }
  }, [parcel, showAllReports]);

  useEffect(() => {
    if (parcel.id) {
      loadTasks()
        .catch(error => {
          console.error("Loading tasks failed" + error);
        });

      taskApi
        .getTasksByParcelId(parcel.id)
        .then(tasks => {
          setParcelTasks(tasks.filter(t => !t.deletedDate));
        })
        .catch(error => {
          toast.error("Error loading tasks");
          setSaving(false);
          throw error;
        });
    }
  }, [parcel]);

  useEffect(() => {
    if (tasks.length > 0) {
      setParcelTasks(tasks.filter(t => !t.deletedDate && t.parcelId === parcel.id));
    }
  }, [tasks, parcel]);

  useEffect(() => {
    if (products && users && parcel)
      if (reportToShow.details.id)
        setShowReport(true);
      else
        setShowReport(false);
  }, [reportToShow]);

  function handleChange(event: any) {
    const { name, value } = event.target;
    setParcel(prevParcel => ({
      ...prevParcel,
      [name]: value
    }));
  }

  function handleSave(event: any) {
    event.preventDefault();
    setSaving(true);
    saveParcel(parcel)
      .then(() => {
        toast.success("Parcela guardada");
        setSaving(false);
        setEditMode(false);
      })
      .catch(error => {
        setSaving(false);
        setErrors({ ...error, onSave: error.message });
      });
  }

  function handleCancel(event: any) {
    setSaving(false);
    setEditMode(false);
  }

  async function handleDeleteReport(reportViewModel: IReportViewModel) {
    try {
      if (reportViewModel.details.id)
        deleteReport(reportViewModel.details.id)
          .then(() => {
            toast.success("Informe borrado");
          })
          .catch(error => {
            setSaving(false);
            setErrors({ ...error, onSave: error.message });
          });

    } catch (error: any) {
      toast.error("Delete failed. " + error.message, { autoClose: false });
    }
  }

  async function handlePreviewReport(display: boolean, reportViewModel: IReportViewModel) {

    setShowReport(false);

    if (display) {
      setReportToShow(reportViewModel);
    }
    else
      setReportToShow(newReport);
  }

  function handleTaskSaved(savedTask: ITask) {
    setCreateTask(false);
    setTaskId(0);
  }


  return (
    <div>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>

          {
            editMode ? <ParcelForm
              parcel={parcel}
              regions={regions}
              errors={errors}
              onChange={handleChange}
              onSave={handleSave}
              onCancel={handleCancel}
              saving={saving}
            /> :
              <ParcelFormRO
                parcel={parcel}
                errors={errors}
                regions={regions}
                onEdit={() => setEditMode(!editMode)}
              ></ParcelFormRO>
          }

        </Row>
        {
          parcelReports && parcelReports.length > 0 &&
          <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <Container>
              <Row>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div><h2>Informes</h2></div>
                  <div>
                    <CheckBoxInput
                      label="Mostrar Todos"
                      value={showAllReports}
                      onChange={(e) => setShowAllReports(!showAllReports)}
                    />
                    <div style={{ marginTop: "4px" }}>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setShowAnualReport(!showAnualReport);
                        }
                        }
                        name="Informe Anual"
                      ></Button>
                    </div>
                  </div>
                </div>
                <div>
                  <ParcelReportList
                    reports={parcelReports}
                    onDeleteClick={handleDeleteReport}
                    onPreview={handlePreviewReport}
                    reportIdDisplayed={reportToShow.details.id ?? 0}
                  />
                </div>
              </Row>
            </Container>
          </Row>
        }
        {showAnualReport &&
          <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <div>
              <YearReportForm
                reports={parcelReports}
                parcel={parcel}
                products={products}
                regions={regions}
                users={users}
              ></YearReportForm>
            </div>
          </Row>
        }

        {showReport &&
          <div>
            <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
              {
                <ReportForm
                  report={reportToShow}
                  parcel={parcel}
                  products={products}
                  regions={regions}
                  users={users}
                  elements={elements}
                  distributors={distributors}
                ></ReportForm>
              }
            </Row>
          </div>
        }
        <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {
            (taskId === 0 && !createTask) ?
              <>
                <h3>Tareas</h3>
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setCreateTask(true)}
                  name="Crear Tarea"
                ></Button>
                <TaskList
                  onDeleteClick={() => { }}
                  onEditClick={(task) => { setTaskId(task.id ?? 0) }}
                  onSave={() => { }}
                  tasks={parcelTasks}
                  parcels={parcels}
                  allowEdit={false}
                ></TaskList>
              </>
              :
              <TaskForm
                parcels={parcels}
                tasks={parcelTasks}
                taskId={taskId}
                parcelId={parcel.id ?? 0}
                onCancel={() => { setTaskId(0) }}
                onNotify={(action: string, savedTask: ITask) => {
                  handleTaskSaved(savedTask);
                }}
              ></TaskForm>
          }
        </Row>
        {
          parcel && parcel.id && parcel.geoJson &&
          <Row className="card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <ParcelCarousel
              parcel={parcel}
            ></ParcelCarousel>
          </Row>
        }

      </Container>
    </div>
  );
}

ManageParcelPage.propTypes = {
  parcels: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  distributors: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  loadParcels: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadProducts: PropTypes.func.isRequired,
  loadElements: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  loadDistributors: PropTypes.func.isRequired,
  loadTasks: PropTypes.func.isRequired,
  saveParcel: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  saveTask: PropTypes.func.isRequired,
};

export function getParcelById(parcels: IParcel[], id: string): IParcel | null {
  return parcels.find(parcel => parcel.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    parcels: state.parcels,
    regions: state.regions,
    products: state.products,
    elements: state.elements,
    users: state.users,
    distributors: state.distributors,
    tasks: state.tasks
  };
}

const mapDispatchToProps = {
  loadParcels,
  loadRegions,
  loadUsers,
  loadProducts,
  loadElements,
  loadDistributors,
  loadTasks,
  saveParcel,
  deleteReport,
  saveTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageParcelPage);
