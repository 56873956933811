import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadProducts, saveProduct } from "../../redux/actions/productsActions";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router-dom";
import { IProduct } from "./types";
import ProductList from "./ProductList";
import Button from "../framework/Button";
import { Container, Row } from "reactstrap";

type Props = {
  products: IProduct[],
  loadProducts: () => Promise<void>,
}

function ProductsPage({
  products,
  loadProducts,
  ...props
}: Props) {

  const [redirectToAddProductPage, setRedirectToAddProductPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          console.error("Loading products failed" + error);
        });
    }
  }, [loadProducts, products.length]);

  function handleDeleteProduct(product: IProduct) {

  }

  function handleEditProduct(product: IProduct) {
    navigate(`/product/${product?.id}`);
  }

  return (
    <>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {redirectToAddProductPage && <Navigate to="/producto" />}
          <h4>Productos</h4>
          <Button
            type="submit"
            className="btn btn-primary add-course"
            onClick={() => setRedirectToAddProductPage(true)}
            name="Crear Producto"
          ></Button>
          {
            <ProductList
              onDeleteClick={handleDeleteProduct}
              onEditClick={handleEditProduct}
              products={products}
            />
          }
        </Row>
      </Container>
    </>
  );
}

ProductsPage.propTypes = {
  products: PropTypes.array.isRequired,
  loadProducts: PropTypes.func.isRequired,

};

export function getProductById(products: IProduct[], id: string): IProduct | null {
  return products.find(product => product.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    products: state.products,
  };
}

const mapDispatchToProps = {
  loadProducts,
  saveProduct
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsPage);
