import { IReportViewModel } from "../components/createReport2/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/report";

export async function getReportByParcelId(parcelId: number, showAllReports: boolean): Promise<IReportViewModel[]> {

  let endpoint ="";
  if (showAllReports)    
    endpoint = controllerName + "/report-parcels/" + parcelId;
  else
    endpoint = controllerName + "/report-parcels-latest/" + parcelId;

  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getLatestReportByMonthId(monthId: number): Promise<IReportViewModel[]> {

  const endpoint = controllerName + "/report-month/" + monthId;
  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getLatestReportByYear(year: number): Promise<IReportViewModel[]> {

  const endpoint = controllerName + "/report-year/" + year;
  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getReportById(reportId: number): Promise<IReportViewModel> {

  const endpoint = controllerName + "/" + reportId;
  const token = await authService.getAccessToken();
  const response = await fetch(endpoint, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function calculateStandardProductValues(report: IReportViewModel): Promise<IReportViewModel> {
  const token = await authService.getAccessToken();

  const endpoint = controllerName + "/calculate/"

  const response = await fetch(endpoint,
    {
      method: "POST",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(report)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function createReport(report: IReportViewModel): Promise<IReportViewModel> {
  const token = await authService.getAccessToken();

  const endpoint = controllerName + "/create/"

  const response = await fetch(endpoint,
    {
      method: "POST",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(report)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function deleteReport(reportId: number) {
  const token = await authService.getAccessToken();

  const endpoint = controllerName + "/" + reportId;
  await fetch(endpoint,
    {
      method: "DELETE",
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
    })   
    .catch(handleError);
}