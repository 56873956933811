// eslint-disable-next-line import/no-anonymous-default-export
export default {
  heroCords: {
    x: 0,
    y: 0,
  },
  bugCords: {
    x: -1,
    y: -1,
  },
  tail: [],
  boardRows: 30,
  boardColumns: 15,
  lastHeroDir: "right",
  users: [],
  parcels: [],
  parcelElements: [],
  elements: [],
  regions: [],
  regionElements: [],
  products: [],
  productElements: [],
  reports: [],
  templates: [] ,
  billing: [],
  billingParcels: [],
  distributors: [],
  distributorProducts: [],
  tasks:[],
  applicationUser: {},
};
