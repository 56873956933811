import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadReportById } from "../../redux/actions/reportsActions";
import { loadProducts } from "../../redux/actions/productsActions";
import { loadParcels } from "../../redux/actions/parcelsActions";
import PropTypes from "prop-types";
import ReportForm from "./ReportForm";
import { months, newParcel, newReport, newUser } from "../../tools/mockData";
import { Link, useParams } from "react-router-dom";
import * as reportApi from "../../api/reportApi";
import { toast } from "react-toastify";
import { IProduct } from "../products/types";
import { IParcel } from "../parcels/types";
import { IUser } from "../users/types";
import { loadUsers } from "../../redux/actions/usersActions";
import ReportList from "./ReportList";
import { IReportViewModel } from "../createReport2/types";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { Col, Container, Row } from "reactstrap";
import { GetPlantTypes, GetTechnicalClasifications, ROLE_ADMIN, ROLE_TECHNICAL } from "../utils/constants";
import { IRegion } from "../regions/types";
import { loadRegions } from "../../redux/actions/regionsActions";
import { loadElements } from "../../redux/actions/elementsActions";
import { loadDistributors } from "../../redux/actions/distributorActions";
import { IElement } from "./types";
import Button from "../framework/Button";
import html2canvas from "html2canvas";
import { IDistributor } from "../distributors/types";
import AdminContainer from "../framework/AdminContainer";

type Props = {
  products: IProduct[],
  parcels: IParcel[],
  users: IUser[],
  reports: IReportViewModel[],
  regions: IRegion[],
  elements: IElement[],
  distributors: IDistributor[],
  loadReportById: (id: number) => Promise<void>,
  loadProducts: () => Promise<void>,
  loadParcels: () => Promise<void>,
  loadUsers: () => Promise<void>,
  loadRegions: () => Promise<void>,
  loadElements: () => Promise<void>,
  loadDistributors: () => Promise<void>
}

function ManageReportPage({
  products,
  parcels,
  users,
  regions,
  elements,
  distributors,
  loadReportById,
  loadProducts,
  loadParcels,
  loadUsers,
  loadRegions,
  loadElements,
  loadDistributors,
  ...props
}: Props) {


  const [report, setReport] = useState(newReport);
  const [filteredReports, setFilteredReports] = useState([] as IReportViewModel[]);
  const [reports, setReports] = useState([] as IReportViewModel[]);
  const [parcel, setParcel] = useState(newParcel);
  const [user, setUser] = useState(newUser);
  const [loading, setLoading] = useState(false);
  const [monthId, setMonthId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [plantType, setPlantType] = useState("");
  const [technicalClasification, setTechnicalClasification] = useState("");
  const [distributorId, setDistributorId] = useState(0);
  const [regionId, setRegionId] = useState(0);
  const params = useParams();
  const reportId = params.id;

  useEffect(() => {
    if (reportId)
      loadReport();
  }, [reportId]);


  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          console.error("Loading products failed" + error);
        });
    }
  }, [loadProducts, products.length]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
  }, [loadRegions, regions.length]);

  useEffect(() => {
    if (elements.length === 0) {
      loadElements()
        .catch(error => {
          console.error("Loading elements failed" + error);
        });
    }
  }, [loadElements, elements.length]);

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          console.error("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);

  useEffect(() => {
    if (users.length === 0) {
      loadUsers()
        .catch(error => {
          console.error("Loading users failed" + error);
        });
    }
  }, [loadUsers, users.length]);

  useEffect(() => {
    if (distributors.length === 0) {
      loadDistributors()
        .catch(error => {
          console.error("Loading distributors failed" + error);
        });
    }
  }, [loadDistributors, distributors.length]);

  useEffect(() => {
    if (!reportId && monthId) {
      loadReportsByMonth()
        .catch(error => {
          console.error("Loading reports failed" + error);
        });
    }
  }, [reportId, monthId]);

  useEffect(() => {
    if (reports) {
      filter();
    }
  }, [reports, userId, regionId, plantType, technicalClasification, distributorId]);

  useEffect(() => {
    if (parcels.length > 0) {
      const parcelSelected = report?.details?.parcelId && parcels.length > 0 ? parcels.find(parcel => parcel.id === report.details.parcelId) : newParcel;
      if (parcelSelected)
        setParcel(parcelSelected);
    }
  }, [parcels, report.details.parcelId]);

  useEffect(() => {
    if (parcel && parcel.userId && users.length > 0) {
      const userSElected = users.find(u => u.id === parcel.userId);
      if (userSElected)
        setUser(userSElected);
    }
  }, [parcel, users]);

  function loadReport() {
    setLoading(true);
    return reportApi
      .getReportById(Number(reportId))
      .then(report => {
        setReport(report);
        setLoading(false);
      })
      .catch(error => {
        toast.error("Error cargando report");
        setLoading(false);
        throw error;
      });
  }

  function loadReportsByMonth() {
    setLoading(true);
    return reportApi
      .getLatestReportByMonthId(monthId)
      .then(reports => {
        setReports(reports);
        setLoading(false);
      })
      .catch(error => {
        toast.error("Error cargando reports");
        setLoading(false);
        throw error;
      });
  }

  function filter() {
    setLoading(true);
    let reportList = reports;

    if (userId)
      reportList = reportList.filter(r => r.details.user.id === userId);

    if (regionId) {
      const validParcels = parcels.filter(p => p.regionId === regionId);
      reportList = reportList.filter(r => validParcels.some(p => p.id === r.details.parcelId));
    }

    if (plantType) {
      const validParcels = parcels.filter(p => p.plantType === plantType);
      reportList = reportList.filter(r => validParcels.some(p => p.id === r.details.parcelId));
    }

    if (technicalClasification) {
      const validParcels = parcels.filter(p => users.find(u => u.id === p.userId)?.technicalClasification === technicalClasification);
      reportList = reportList.filter(r => validParcels.some(p => p.id === r.details.parcelId));
    }

    if (distributorId) {
      reportList = reportList.filter(r => r.details.distributorId === Number(distributorId));
    }

    setFilteredReports(reportList);
    setLoading(false);
  }

  function handleCopyImages() {
    //function that copies several divs into one image
    const divs: HTMLElement[] = [];

    filteredReports.map(report => {
      const div = document.getElementById("divReport" + report.details.id);
      if (div)
        divs.push(div);
    });

    const divReports = document.getElementById("divCopyReports")!;

    divs.forEach(div => {
      const clone = div.cloneNode(true)
      divReports.appendChild(clone);

      //apend child that is a div with a 10px

      const divLine = document.createElement("div");
      divLine.style.width = "100%";
      divLine.style.height = "3px";
      divLine.style.backgroundColor = "black";
      divLine.style.marginTop = "2em";
      divLine.style.marginBottom = "2em";
      divReports.appendChild(divLine);

      const divSpace = document.createElement("div");
      divSpace.style.height = "5em";

      divSpace.appendChild(divLine);
      divReports.appendChild(divSpace);
    });

    html2canvas(divReports).then(function (canvas) {
      canvas.toBlob(function (blob) {
        navigator.clipboard
          .write([
            new ClipboardItem(
              Object.defineProperty({}, blob!.type, {
                value: blob,
                enumerable: true
              })
            )
          ])
          .then(function () {
            toast.info("Copiado");
            divReports.innerHTML = "";
          });
      });
    });
  }

  function handleCopyProductImages() {
    //function that copies several divs into one image
    const divs: HTMLElement[] = [];

    filteredReports.map(report => {
      const div = document.getElementById("divReportProducts" + report.details.id);
      if (div)
        divs.push(div);
    });

    const divReports = document.getElementById("divCopyReportProducts")!;

    divs.forEach(div => {
      const clone = div.cloneNode(true)
      divReports.appendChild(clone);

      //apend child that is a div with a 10px

      const divLine = document.createElement("div");
      divLine.style.width = "100%";
      divLine.style.height = "3px";
      divLine.style.backgroundColor = "black";
      divLine.style.marginTop = "2em";
      divLine.style.marginBottom = "2em";
      divReports.appendChild(divLine);

      const divSpace = document.createElement("div");
      divSpace.style.height = "5em";

      divSpace.appendChild(divLine);
      divReports.appendChild(divSpace);
    });


    html2canvas(divReports).then(function (canvas) {
      canvas.toBlob(function (blob) {
        navigator.clipboard
          .write([
            new ClipboardItem(
              Object.defineProperty({}, blob!.type, {
                value: blob,
                enumerable: true
              })
            )
          ])
          .then(function () {
            toast.info("Copiado");
            divReports.innerHTML = "";
          });
      });
    });
  }


  return (
    <div>
      {reportId && <div>
        <div style={{ margin: '10px', padding: '3px' }}>
          <Link to={'/usuario/' + user.id}>Usuario</Link>
        </div>
        <div style={{ margin: '10px', padding: '3px' }}>
          <Link to={'/parcela/' + parcel.id}>Parcela</Link>
        </div>
        {products.length > 0 && !loading && report && parcel.id && user &&
          <ReportForm
            report={report}
            parcel={parcel}
            products={products}
            regions={regions}
            elements={elements}
            users={users}
            distributors={distributors}
          />}
      </div>}
      {!reportId && !loading && <div>
        <Container>
          <h4>Informes</h4>
          <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <Container>
              <Row style={{ padding: "1px", marginBottom: "2px" }}>
                <Col className="col-sm-3">
                  <SelectInput
                    name="monthId"
                    label="Mes *"
                    value={monthId}
                    defaultOption=""
                    options={months.map(month => ({
                      value: month.id,
                      text: month.name
                    } as SelectOption))}
                    onChange={(event) => setMonthId(Number(event.target.value))}
                    wrapperClass={""}
                  />
                </Col>
                <Col className="col-sm-3">
                  <SelectInput
                    name="regionId"
                    label="Region"
                    value={regionId}
                    defaultOption=""
                    options={regions.map(r => ({
                      value: r.id,
                      text: r.name
                    } as SelectOption))}
                    onChange={(event) => setRegionId(Number(event.target.value))}
                    wrapperClass={""}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "1px", marginBottom: "2px" }}>
                <Col className="col-sm-3">
                  <SelectInput
                    name="userId"
                    label="Usuario"
                    value={userId}
                    defaultOption=""
                    options={users.map(user => ({
                      value: user.id,
                      text: user.name
                    } as SelectOption))}
                    onChange={(event) => setUserId(Number(event.target.value))}
                    wrapperClass={""}
                  />
                </Col>
                <Col className="col-sm-3">
                  <SelectInput
                    name="plantType"
                    label="Tipo Planta"
                    value={plantType}
                    defaultOption=""
                    options={GetPlantTypes().map(p => ({
                      value: p.text,
                      text: p.value
                    } as SelectOption))}
                    onChange={(event) => setPlantType(event.target.value)}
                    wrapperClass={""}
                  />
                </Col>
              </Row>
              <AdminContainer>
                <Row style={{ padding: "1px", marginBottom: "2px" }}>
                  <Col className="col-sm-3">
                    <SelectInput
                      name="technicalClasification"
                      label="Técnico"
                      value={technicalClasification}
                      defaultOption=""
                      options={GetTechnicalClasifications().map(p => ({
                        value: p.text,
                        text: p.value
                      } as SelectOption))}
                      onChange={(event) => setTechnicalClasification(event.target.value)}
                      wrapperClass={""}
                    />
                  </Col>
                  <Col className="col-sm-3">
                    <SelectInput
                      name="distributorId"
                      label="Distribuidor"
                      value={distributorId}
                      defaultOption=""
                      options={distributors.map(d => ({
                        value: d.id,
                        text: d.name
                      } as SelectOption))}
                      onChange={(event) => setDistributorId(event.target.value)}
                      wrapperClass={""}
                    />
                  </Col>
                </Row>                  
              </AdminContainer>
              <Row style={{ padding: "1px", marginBottom: "2px" }}>
                <Col className="col-sm-3">
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => handleCopyImages()}
                    name="Copiar Informes Agrícolas"
                  ></Button>
                </Col>
                <Col className="col-sm-3">
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => handleCopyProductImages()}
                    name="Copiar Informes Distribuidor"
                  ></Button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        <ReportList
          reports={filteredReports}
          parcels={parcels}
          products={products}
          regions={regions}
          users={users}
          elements={elements}
          distributors={distributors}
        />
      </div>
      }
      {
        !reportId && loading && <h5>Cargando</h5>
      }
    </div>);

}

ManageReportPage.propTypes = {
  products: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  distributors: PropTypes.array.isRequired,
  loadReportById: PropTypes.func.isRequired,
  loadProducts: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  loadElements: PropTypes.func.isRequired,
  loadDistributors: PropTypes.func.isRequired
};

function mapStateToProps(state: any, ownProps: any) {
  return {
    products: state.products,
    parcels: state.parcels,
    users: state.users,
    regions: state.regions,
    elements: state.elements,
    distributors: state.distributors
  };
}

const mapDispatchToProps = {
  loadReportById,
  loadProducts,
  loadParcels,
  loadUsers,
  loadRegions,
  loadElements,
  loadDistributors
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageReportPage);
