import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IUser } from "./types";
import { GetTechnicalClasifications, ROLE_ADMIN, ROLE_TECHNICAL } from "../utils/constants";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { IDistributor } from "../distributors/types";
import * as usersApi from "../../api/usersApi"; // Import the module or API that provides the 'usersapi' object
import { useState } from "react";
import AdminContainer from "../framework/AdminContainer";

type Props = {
  user: IUser;
  distributros: IDistributor[];
  onSave: (event: any) => void;
  onChange: (event: any) => void;
  saving: boolean;
  errors: IErrors
};

const UserForm = ({
  user,
  distributros,
  onSave,
  onChange,
  saving = false,
  errors,
}: Props) => {

  const technicalClasifications = GetTechnicalClasifications();
  const [techUsers, setTechUsers] = useState<string[]>([]);
  const [techUsersLoaded, setTechUsersLoaded] = useState(false);

  function getTechnicalNames(): SelectOption[] {

    if (techUsersLoaded) {
      return techUsers.map((techUser: string) => ({
        value: techUser,
        text: techUser
      } as SelectOption));
    }
    else {
      usersApi.getTechnicians().then((response: any) => {
        if (response) {
          setTechUsersLoaded(true);
          setTechUsers(response);
          return response.map((techUser: any) => ({
            value: techUser,
            text: techUser
          } as SelectOption));
        }
      });

      return [];
    }
  }

  return (
    <div>
      <form onSubmit={onSave} className="form-flex">
        {errors.onSave && (
          <div className="alert alert-danger" role="alert">
            {errors.onSave}
          </div>
        )}

        <Container>
          <Row className="container-row">
            <Col className="col-sm-6">
              <TextInput
                name="name"
                label="Nombre"
                value={user.name}
                onChange={onChange}
                errors={errors.fieldErrors}
                wrapperClass={"col 4"}
              />
            </Col>
            <Col className="col-sm-6">
              <TextInput
                name="mobileNumber"
                label="Telefono"
                value={user.mobileNumber}
                onChange={onChange}
                errors={errors.fieldErrors}
                wrapperClass={"col 4"}
              />
            </Col>
          </Row>
          <AdminContainer>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
              <Col className="col-sm-6">
                <SelectInput
                  name="technicalName"
                  label="Usuario Técnico"
                  value={user.technicalName || ""}
                  defaultOption="Seleccione Usuario Técnico"
                  options={getTechnicalNames()}
                  onChange={onChange}
                  errors={errors.fieldErrors}
                />
              </Col>
              <Col className="col-sm-6">
                <SelectInput
                  name="technicalClasification"
                  label="Técnico Admin"
                  value={user.technicalClasification || ""}
                  defaultOption="Seleccione Admin Técnico"
                  options={technicalClasifications}
                  onChange={onChange}
                  errors={errors.fieldErrors}
                />
              </Col>
            </Row>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
              <Col className="col-sm-6">
                <SelectInput
                  name="distributorId"
                  label="Distribuidor"
                  value={user.distributorId || ""}
                  defaultOption="Seleccione Distribuidor"
                  options={distributros.map(region => ({
                    value: region.id,
                    text: region.name
                  } as SelectOption))}
                  onChange={onChange}
                  errors={errors.fieldErrors}
                />
              </Col>
            </Row>
          </AdminContainer>
          <Row className="container-row" style={{ alignItems: "flex-end" }}>
            <Col className="col-sm-4">
              <Button
                type="submit"
                isDisabled={saving}
                className="btn btn-primary"
                name="Guardar"
                actionName="Guardando"
              ></Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default UserForm;
