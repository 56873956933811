import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../framework/Button";
import { IReportProduct, IReportViewModel } from "../createReport2/types";
import { IProduct } from "../products/types";
import { ConvertToMonthName } from "../utils/monthCalculations";
import html2canvas from "html2canvas";
import { IParcel } from "../parcels/types";
import { IUser } from "../users/types";
import { IRegion } from "../regions/types";
import { IElement } from "./types";
import { IDistributor } from "../distributors/types";
import { report } from "process";

type Props = {
  reports: IReportViewModel[],
  parcel: IParcel,
  products: IProduct[],
  regions: IRegion[],
  users: IUser[],
}

type ProductsMonth = {
  Month: number,
  Products: IReportProduct[]
}

const YearReportForm = ({
  reports,
  parcel,
  products,
  regions,
  users,
}: Props) => {

  const [loading, setloading] = useState(true);
  const [productsA, setProductsA] = useState<ProductsMonth[]>([]);
  const [productAIDS, setProductAIDS] = useState<number[]>([]);
  const [productsE, setProductsE] = useState<ProductsMonth[]>([]);
  const [productEIDS, setProductEIDS] = useState<number[]>([]);
  const [productsF, setProductsF] = useState<ProductsMonth[]>([]);
  const [productFIDS, setProductFIDS] = useState<number[]>([]);


  function calculateDosePerHa(value: number) {
    return Math.round(value * 10 / 10);
  }

  function handleCopyImage(reportId: number) {
    let src = document.getElementById("divReport" + reportId)!;
    html2canvas(src).then(function (canvas) {
      canvas.toBlob(function (blob) {
        navigator.clipboard
          .write([
            new ClipboardItem(
              Object.defineProperty({}, blob!.type, {
                value: blob,
                enumerable: true
              })
            )
          ])
          .then(function () {
            toast.info("Copiado");
          });
      });
    });
  }

  useEffect(() => {
    if (productsA.length > 0) return;
    if (!reports || reports.length === 0) return;

    reports.map(report => report.details.user = users.find(u => u.id === parcel.userId)!);

    let prods: ProductsMonth[] = reports.map(r => {
      return { Month: r.details.month!, Products: r.products.filter(p => p.kgXMonth) };
    });

    setProductsA(prods);
    let ids = prods.map(p => p.Products.map(p => p.productId!)).flat().filter((value, index, self) => self.indexOf(value) === index);
    setProductAIDS(ids);

    prods = reports.map(r => {
      return { Month: r.details.month!, Products: r.doseProducts.filter(p => p.group === "Grupo E" && p.doseTotal) };
    });

    setProductsE(prods);
    ids = prods.map(p => p.Products.map(p => p.productId!)).flat().filter((value, index, self) => self.indexOf(value) === index);
    setProductEIDS(ids);

    prods = reports.map(r => {
      return { Month: r.details.month!, Products: r.foliarProducts.filter(p => p.group === "Grupo F" && p.doseTotal) };
    });
    setProductsF(prods);
    ids = prods.map(p => p.Products.map(p => p.productId!)).flat().filter((value, index, self) => self.indexOf(value) === index);
    setProductFIDS(ids);

    setloading(false);
  }, [reports?.length]);



  function getTotalProductsA(productId: number, products: ProductsMonth[]) {
    return products.map(p => p.Products.find(p => p.productId === productId)?.kgXMonth ?? 0).reduce((a, b) => a + b, 0);
  }

  function getTotalProductsE(productId: number, products: ProductsMonth[]) {
    return products.map(p => p.Products.find(p => p.productId === productId)?.doseTotal ?? 0).reduce((a, b) => a + b, 0) / 1000;
  }

  function getTotalProductsF(productId: number, products: ProductsMonth[]) {
    return products.map(p => p.Products.find(p => p.productId === productId)?.doseTotal ?? 0).reduce((a, b) => a + b, 0 / 1000);
  }

  return (

    <div style={{ marginTop: '10px', padding: '3px' }} className="scroll-container">
      {
        !loading &&
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", flexWrap: "wrap" }}>
            <div>
              <Button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => handleCopyImage(reports[0].details.id!)}
                name="Copiar Informe Anual"
              ></Button>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", flexWrap: "wrap" }}>
            <div>
              <b>Parcela: </b>{parcel.name}
            </div>
            <div>
              <b>Region: </b>{regions.find(r => r.id === parcel.regionId)?.name}
            </div>
            <div>
              <b>Usuario: </b>{users.find(r => r.id === parcel.userId)?.name}
            </div>
            <div>
              <b>Usuario Técnico: </b>{users.find(r => r.id === parcel.userId)?.technicalClasification}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: "10px" }}>
            <div id={"divReport" + reports[0].details.id}>
              <table className="table table-bordered" >
                <tbody>
                <tr style={{ fontWeight: "bold" }}>
                    <td colSpan={1} style={{ backgroundColor: "#DBDBDB" }}>{parcel.plantType?.toUpperCase()}</td>
                    <td colSpan={13} style={{ backgroundColor: "#DBDBDB", textAlign: "left" }}>{parcel.name?.toUpperCase()}</td>
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td style={{ backgroundColor: "#DBDBDB" }}></td>
                    <td colSpan={12} style={{ backgroundColor: "#FFD966", textAlign: "center" }}>MES / DOSIS POR Ha / Kg</td>
                    <td colSpan={12} style={{ backgroundColor: "#DBDBDB" }}></td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#DBDBDB" }}></td>
                    <td style={{ backgroundColor: "#FFD966" }}>ENERO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>FEBRERO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>MARZO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>ABRIL</td>
                    <td style={{ backgroundColor: "#FFD966" }}>MAYO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>JUNIO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>JULIO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>AGOSTO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>SEPTIEMBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>OCTUBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>NOVIEMBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>DICIEMBRE</td>
                    <td style={{ backgroundColor: "#DBDBDB" }}>TOTAL</td>
                  </tr>
                  {
                    productAIDS.map(productId => {
                      return (
                        <tr key={"_a" + productId} style={{ backgroundColor: "#F4B084", textAlign: 'right' }}>
                          <td style={{ backgroundColor: "#DBDBDB" }}>
                            {products.find(p => p.id === productId)?.name}
                          </td>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
                            return (
                              <td key={"_a" + month}>
                                {calculateDosePerHa(reports.find(r => r.details.month === month)?.products.find(p => p.productId === productId)?.kgXMonth ?? 0)}
                              </td>
                            );
                          })}
                          <td style={{ backgroundColor: "#DBDBDB" }}>{calculateDosePerHa(getTotalProductsA(productId, productsA))}</td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan={14} style={{ backgroundColor: "#DBDBDB" }}></td>
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td style={{ backgroundColor: "#DBDBDB" }}></td>
                    <td colSpan={12} style={{ backgroundColor: "#FFD966", textAlign: "center" }}>MES / DOSIS POR Ha / L</td>
                    <td colSpan={12} style={{ backgroundColor: "#DBDBDB" }}></td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#DBDBDB", fontWeight: "bold" }}>TRATAMIENTOS ENRAIZANTES</td>
                    <td style={{ backgroundColor: "#FFD966" }}>ENERO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>FEBRERO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>MARZO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>ABRIL</td>
                    <td style={{ backgroundColor: "#FFD966" }}>MAYO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>JUNIO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>JULIO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>AGOSTO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>SEPTIEMBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>OCTUBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>NOVIEMBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>DICIEMBRE</td>
                    <td style={{ backgroundColor: "#DBDBDB" }}>TOTAL</td>
                  </tr>
                  {
                    productEIDS.map(productId => {
                      return (
                        <tr key={"_e" + productId} style={{ backgroundColor: "#F4B084", textAlign: 'right' }}>
                          <td style={{ backgroundColor: "#DBDBDB" }}>
                            {products.find(p => p.id === productId)?.name}
                          </td>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
                            return (
                              <td key={"_e" + month}>
                                {calculateDosePerHa((reports.find(r => r.details.month === month)?.doseProducts.find(p => p.productId === productId)?.doseTotal ?? 0) / 1000) }
                              </td>
                            );
                          })}
                          <td style={{ backgroundColor: "#DBDBDB" }}>{calculateDosePerHa(getTotalProductsE(productId, productsE))}</td>
                        </tr>
                      );
                    })}

                  <tr>
                    <td colSpan={14} style={{ backgroundColor: "#DBDBDB" }}></td>
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td style={{ backgroundColor: "#DBDBDB" }}></td>
                    <td colSpan={12} style={{ backgroundColor: "#FFD966", textAlign: "center" }}>MES / DOSIS POR Ha A 1000 DE CALDO POR Ha APROX</td>
                    <td colSpan={12} style={{ backgroundColor: "#DBDBDB" }}></td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#DBDBDB", fontWeight: "bold" }}>TRATAMIENTOS FOLIARES</td>
                    <td style={{ backgroundColor: "#FFD966" }}>ENERO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>FEBRERO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>MARZO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>ABRIL</td>
                    <td style={{ backgroundColor: "#FFD966" }}>MAYO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>JUNIO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>JULIO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>AGOSTO</td>
                    <td style={{ backgroundColor: "#FFD966" }}>SEPTIEMBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>OCTUBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>NOVIEMBRE</td>
                    <td style={{ backgroundColor: "#FFD966" }}>DICIEMBRE</td>
                    <td style={{ backgroundColor: "#DBDBDB" }}>TOTAL</td>
                  </tr>
                  {
                    productFIDS.map(productId => {
                      return (
                        <tr key={"_e" + productId} style={{ backgroundColor: "#F4B084", textAlign: 'right' }}>
                          <td style={{ backgroundColor: "#DBDBDB" }}>
                            {products.find(p => p.id === productId)?.name}
                          </td>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
                            return (
                              <td key={"_e" + month}>
                                {calculateDosePerHa((reports.find(r => r.details.month === month)?.foliarProducts.find(p => p.productId === productId)?.doseTotal ?? 0) / 1000) }
                              </td>
                            );
                          })}
                          <td style={{ backgroundColor: "#DBDBDB" }}>{calculateDosePerHa(getTotalProductsF(productId, productsF) / 1000)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }    
    </div>
  );
};

export default YearReportForm;
