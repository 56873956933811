import React from "react";
import PropTypes from "prop-types";
import { IBilling } from "./types";
import { Link, useNavigate } from "react-router-dom";
import Button from "../framework/Button";
import { ConvertToMonthName } from "../utils/monthCalculations";

type Props = {
  billing: IBilling[],
  onEditClick: (billing: IBilling) => void,
  onDeleteClick: (billing: IBilling) => void,
}

const BillingList = ({ billing, onEditClick, onDeleteClick }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="scroll-container">
      {billing.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>Año</th>
            <th>Mes</th>
            <th>Total</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {billing.map(b => {
            return (
              <tr key={b.id}>
                <td>
                  {b.year}
                </td>
                <td>
                  {ConvertToMonthName(b.month!)}
                </td>
                <td>
                  {b.totalAmount}
                </td>
                <td>
                  <Button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/factura/' + b.id)}
                    name="Editar"
                  ></Button>   
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <div />}

    </div>
  )

}




BillingList.propTypes = {
  billing: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default BillingList;
