import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { useState } from "react";
import { IRegion } from "../regions/types";
import { ITemplate } from "./types";
import { IMonth } from "../createReport2/types";
import Button from "../framework/Button";

type Props = {
    template: ITemplate,
    months: IMonth[],
    regions: IRegion[],        
    handleChange: (event: any) => void,
    handleSave: (event: any) => void,
    handleGenerateReports: (event: any) => void,
}

const TemplateForm = ({ 
    template,
    months,    
    regions,
    handleChange,
    handleSave,
    handleGenerateReports }: Props) => {

    const [errors] = useState(newError);

    return (
        <Container>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
                <Col className="col-sm-4">
                    <TextInput
                        name="name"
                        label="Nombre"
                        value={template.name}
                        onChange={handleChange}
                    />
                </Col>    
                <Col className="col-sm-4">
                    <SelectInput
                        name="month"
                        label="Mes"
                        value={template.month}
                        defaultOption=""
                        options={months.map(month => ({
                            value: month.id,
                            text: month.name
                        } as SelectOption))}
                        onChange={handleChange}
                        errors={errors.fieldErrors}
                        wrapperClass={""}
                    />
                </Col>
                <Col className="col-sm-4">
                    <SelectInput
                        name="regionId"
                        label="Region"
                        value={template.regionId}
                        defaultOption=""
                        options={regions.map(region => ({
                            value: region.id,
                            text: region.name
                        } as SelectOption))}
                        onChange={handleChange}
                        errors={errors.fieldErrors}
                        wrapperClass={""}
                    />
                </Col>    
            </Row>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
                <Col className="col-sm-12">
                    <TextInput
                        name="comment"
                        label="Comentarios"
                        value={template.comment}
                        onChange={handleChange}
                    />
                </Col>                                          
            </Row>   
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
                
                <Col className="col-sm-12">
                    <TextInput
                        name="foliarObservations"
                        label="Observaciones Foliares"
                        value={template.foliarObservations}
                        onChange={handleChange}
                    />
                </Col>
            </Row>   
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
                <Col className="col-sm-1">
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        name="Guardar"
                        actionName="Guardando"
                        onClick={handleSave}
                    ></Button>
                </Col>    
                <Col className="col-sm-3">
                   {
                        template.id &&
                        <Button
                            type="submit"
                            className="btn btn-primary"
                            name="Generar Informes"
                            actionName="..."
                            onClick={handleGenerateReports}
                        ></Button>                    
                   }
                </Col>    
            </Row>        
        </Container>
    );
}


export default TemplateForm;
