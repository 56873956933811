import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import CheckBoxInput from "../framework/CheckBoxInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { ITask } from "./types";
import TextArea from "../framework/TextArea";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import { IParcel } from "../parcels/types";
import DateTimeInput from "../framework/DateTimeInput";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { saveTask } from "../../redux/actions/tasksActions";
import { useEffect, useState } from "react";
import { newError, newTask } from "../../tools/mockData";


type Props = {
  tasks: ITask[],
  taskId: number,
  parcelId: number | undefined,
  onCancel: (event: any) => void,
  saveTask: (event: any) => Promise<void>,
  onNotify: (action: "Save" | "Archive" | "Reactive" | "Complete", savedTask: ITask) => void,
  parcels: IParcel[]
}

const TaskForm = ({
  tasks,
  taskId,
  parcelId,
  onCancel,
  saveTask,
  onNotify,
  parcels,
}: Props) => {

  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(newError);
  const [task, setTask] = useState<ITask>(newTask);

  useEffect(() => {
    if (taskId) {
      const task = tasks.find(t => t.id === taskId) || newTask;
      setTask(task);
    }
  }, [taskId, tasks]);


  function handleChange(event: any) {
    const { name, value } = event.target;

    if (name === "isCompleted") {
      setTask(prevTask => ({
        ...prevTask,
        [name]: event.target.checked
      }));
    }
    else {
      setTask(prevTask => ({
        ...prevTask,
        [name]: value
      }));

    }
  }

  function handleSave(event: any, action: "Save" | "Archive" | "Reactive" | "Complete") {
    event.preventDefault();
    setSaving(true);

    let taskToSave = { ...task, parcelId: !!parcelId ? parcelId : task.parcelId };

    if (action === "Complete") {

      taskToSave =
      {
        ...task,
        isCompleted: true
      };
    }
    else if (action === "Archive") {
      taskToSave =
      {
        ...task,
        deletedDate: new Date()
      }
    }
    else if (action === "Reactive") {
      taskToSave =
      {
        ...task,
        deletedDate: null,
        isCompleted: false
      }
    }

    saveTask(taskToSave)
      .then(() => {
        toast.success("Tarea guardada");
        setTimeout(() => {
          setSaving(false);
          onNotify(action, taskToSave);
        }, (700));
      })
      .catch(error => {
        setSaving(false);
        setErrors({ ...error, onSave: error.message });
      });
  }


  return (
    <form className="form-flex">
      <h4>{task.id ? "Editar" : "Crear"} Tarea</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <Container>
        <Row className="container-row">
          <Col className="col-sm-12">
            <TextInput
              name="title"
              label="Título"
              value={task.title?.toString()}
              onChange={handleChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 12"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-12">
            <TextArea
              name="description"
              label="Decripción"
              value={task.description?.toString()}
              onChange={handleChange}
              errors={errors.fieldErrors}
              wrapperClass={"col 12"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          {            
            <Col className="col-sm-6">
              <SelectInput
                name="parcelId"
                label="Parcela"
                value={parcelId ? parcelId : task.parcelId || ""}
                defaultOption="Seleccione Parcela"
                options={parcels.map(pg => ({
                  value: pg.id?.toString(),
                  text: pg.name
                } as SelectOption))}
                onChange={handleChange}
                errors={errors.fieldErrors}
                wrapperClass={"col 6"}
                isReadOnly={!!parcelId}
              />
            </Col>
          }
          <Col className="col-sm-6">
            <DateTimeInput
              name="targetDate"
              label="Fecha Notificación"
              value={task.targetDate}
              onChange={handleChange}
              errors={errors.fieldErrors}
              format="dd/MM/yyyy"
              wrapperClass={"col 6"}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-1">
            <Button
              type="button"
              onClick={(evt) => handleSave(evt, "Save")}
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
              isDisabled={saving}
            ></Button>
          </Col>
          {/* {
            !task.isCompleted &&
            <Col className="col-sm-1">
              <Button
                type="button"
                onClick={(evt) => onSave(evt, "Complete")}
                className="btn btn-primary"
                name="Completar"
                actionName="Guardando"
                isDisabled={saving}
              ></Button>
            </Col>
          } */}
          {
            !task.deletedDate &&
            <Col className="col-sm-1">
              <Button
                type="button"
                onClick={(evt) => handleSave(evt, "Archive")}
                className="btn btn-primary"
                name="Archivar"
                actionName="Guardando"
                isDisabled={saving}
              ></Button>
            </Col>
          }
          {
            (task.deletedDate || task.isCompleted) &&
            <Col className="col-sm-1">
              <Button
                type="button"
                onClick={(evt) => handleSave(evt, "Reactive")}
                className="btn btn-primary"
                name="Reactivar"
                actionName="Guardando"
                isDisabled={saving}
              ></Button>
            </Col>
          }
          <Col className="col-sm-1">
            <Button
              type="button"
              isDisabled={saving}
              className="btn btn-secondary"
              onClick={onCancel}
              name="Cancelar"
            ></Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

function mapStateToProps(state: any, ownProps: any) {
  return {
  };
}

const mapDispatchToProps = {
  saveTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskForm);

