import * as types from "./actionTypes";
import * as billingApi from "../../api/billingApi";
import { beginApiCall, apiCallError } from "./apiStatusAction";

export function loadBillingSuccess(billing) {
  return { type: types.LOAD_BILLINGS_SUCCESS, billing };
}

export function deleteBillingOptimistic(billing) {
  return { type: types.DELETE_BILLING_OPTIMISTIC, billing };
}

export function createBillingSuccess(billing) {
  return { type: types.CREATE_BILLING_SUCCESS, billing };
}

export function updateBillingSuccess(billing) {
  return { type: types.UPDATE_BILLING_SUCCESS, billing };
}


export function loadBilling() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return billingApi
      .getBilling()
      .then(billing => {
        dispatch(loadBillingSuccess(billing));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveBilling(billing) {
  //eslint-disable-next-line no-unused-vars
  return function(dispatch, getState) {
    dispatch(beginApiCall());
    return billingApi
      .saveBilling(billing)
      .then(savedBilling => {
        billing.id
          ? dispatch(updateBillingSuccess(savedBilling))
          : dispatch(createBillingSuccess(savedBilling));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function deleteBilling(billingId) {
  return function(dispatch) {
    // Doing optimistic delete, so not dispatching begin/end api call
    // actions, or apiCallError action since we're not showing the loading status for this.
    dispatch(deleteBillingOptimistic(billingId));
    return billingApi.deleteBilling(billingId);
  };
}