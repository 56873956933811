import { SelectOption } from "../framework/SelectInput";
import { IProductGroup } from "../products/types";

export function GetAllGroups(): IProductGroup[] {

    return [
        { id: 1, name: 'Grupo A' } as unknown as IProductGroup,
        { id: 2, name: 'Grupo B' } as unknown as IProductGroup,
        { id: 3, name: 'Grupo E' } as unknown as IProductGroup,
        { id: 3, name: 'Grupo F' } as unknown as IProductGroup,
    ];
}


export function GetProductsGroups(): IProductGroup[]{

    return [
        { id: 1, name: 'Grupo A' } as unknown as IProductGroup,
        { id: 2, name: 'Grupo B' } as unknown as IProductGroup,
        { id: 3, name: 'Grupo E' } as unknown as IProductGroup,
    ];
}

export function GetDosisProductsGroups(): IProductGroup[] {

    return GetProductsGroups().filter(p => p.name !== 'Grupo A');
}

export function GetPlantTypes(): SelectOption[] {

    return [
        { value: 'Aguacate', text: 'Aguacate' } as unknown as SelectOption,
        { value: 'Mango', text: 'Mango' } as unknown as SelectOption,
        { value: 'Nispero', text: 'Nispero' } as unknown as SelectOption,
        { value: 'Chirimoyo', text: 'Chirimoyo' } as unknown as SelectOption,        
    ];
}


export function GetTechnicalClasifications(): SelectOption[] {

    return [
        { value: 'Sayalonga', text: 'Sayalonga' } as unknown as SelectOption,
        { value: 'Almuñecar', text: 'Almuñecar' } as unknown as SelectOption,   
    ];
}


export const ROLE_ADMIN = "Admin";
export const ROLE_TECHNICAL = "Tec";
export const ROLE_DISTRIBUTOR = "User";