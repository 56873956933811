import React, { useEffect, useState } from 'react';
import { IParcel, IParcelImage } from './types';
import './Carousel.css';
import * as parcelImageApi from "../../api/parcelImageApi";

interface IParcelCarouseProps {
  parcel: IParcel,
}

const ParcelCarousel: React.FC<IParcelCarouseProps> = ({ parcel }) => {
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [images, setImages] = useState([] as IParcelImage[]);

  useEffect(() => {
    if (parcel.id)
      getParcelImages();

  }, [parcel]);

  function getParcelImages() {

    if (parcel.id) {
      return parcelImageApi
        .getImages(parcel.id)
        .then(response => {
          if (response) {
            setImages(response);
          }
        })
        .catch(error => {

          throw error;
        });
    }

    return null;
  }

  const handleCheckboxChange = (index: number) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter(i => i !== index));
    } else {
      setSelectedImages([...selectedImages, index]);
    }
  }; 

  return (
    <div className="carousel-container">
      {(images && images.length > 0) ? <div>       
        <div className="carousel-images">
          {images.map((image, index) => (                        
            <div key={index} className="carousel-image">
              <img src={"data:image/png;base64, " + image.image} alt="" style={{width:"300px", height:"300px"}}/>
              <p>{new Date(image.insertDate!.toString()).toLocaleDateString("es-ES", { year: 'numeric', month: '2-digit', day: '2-digit'})}</p>
              <input type="checkbox" checked={selectedImages.includes(index)} onChange={() => handleCheckboxChange(index)} />
            </div>
          ))}
        </div>
      </div> : <div>Sin imagenes</div>}      
    </div>
  );
};

export default ParcelCarousel;