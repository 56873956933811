import { Col, Container, Row } from "reactstrap";
import Button from "../framework/Button";
import DateTimeInput from "../framework/DateTimeInput";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import TextInput from "../framework/TextInput";
import { IErrors } from "../framework/types";
import { IRegion } from "../regions/types";
import { GetPlantTypes, ROLE_ADMIN, ROLE_TECHNICAL } from "../utils/constants";
import { IParcel } from "./types";
import TextArea from "../framework/TextArea";
import AdminContainer from "../framework/AdminContainer";

type Props = {
  parcel: IParcel,
  regions: IRegion[],
  onSave: (event: any) => void,
  onChange: (event: any) => void,
  onCancel: (event: any) => void,
  saving: boolean,
  errors: IErrors
}

const ParcelForm = ({
  parcel,
  regions,
  onSave,
  onChange,
  onCancel,
  saving = false,
  errors
}: Props) => {

  const plantTypes = GetPlantTypes();

  return (
    <form onSubmit={onSave} className="form-flex">
      <h4>{parcel.id ? "Editar" : "Crear"} Parcela</h4>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <Container>
        <Row className="container-row">
          <Col className="col-sm-6">
            <TextInput
              name="name"
              label="Nombre Parcela"
              value={parcel.name}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
          <Col className="col-sm-6">
            <SelectInput
              name="regionId"
              label="Region"
              value={parcel.regionId || ""}
              defaultOption="Seleccione Region"
              options={regions.map(region => ({
                value: region.id,
                text: region.name
              } as SelectOption))}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-6">
            <TextInput
              name="year"
              label="Año parcela"
              value={parcel.year?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
          <Col className="col-sm-6">
            <TextInput
              name="area"
              label="Superficie"
              value={parcel.area?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-6">
            <TextInput
              name="plantsNumber"
              label="Num. Plantas"
              value={parcel.plantsNumber?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
          <Col className="col-sm-6">
            <SelectInput
              name="plantType"
              label="Tipo de Planta"
              value={parcel.plantType || ""}
              defaultOption="Seleccione Tipo de Planta"
              options={plantTypes}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col className="col-sm-6">
            <TextInput
              name="variety"
              label="Variedad"
              value={parcel.variety}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
          <Col className="col-sm-6">
            <TextInput
              name="volumeXArea"
              label="Vol. Hectárea"
              value={parcel.volumeXArea?.toString()}
              onChange={onChange}
              errors={errors.fieldErrors}
            />
          </Col>
        </Row>
        <AdminContainer>
          <Row className="container-row">
            <Col className="col-sm-6">
              <TextInput
                name="billingMonthAmount"
                label="Facturación por mes"
                value={parcel.billingMonthAmount?.toString()}
                onChange={onChange}
                errors={errors.fieldErrors}
              />
            </Col>
            <Col className="col-sm-6">
              <DateTimeInput
                name="billingStartDate"
                label="Facturando desde"
                value={parcel.billingStartDate}
                onChange={onChange}
                errors={errors.fieldErrors}
                format="MM/yyyy"
              />
            </Col>
          </Row>
          <Row className="container-row">
            <Col className="col-sm-12">
              <TextArea
                name="geoJson"
                label="geoJson"
                value={parcel.geoJson?.toString()}
                onChange={onChange}
                eerrors={errors.fieldErrors}
              />
            </Col>
          </Row>
          <Row className="container-row">
            <Col className="col-sm-1">
              <Button
                type="button"
                isDisabled={saving}
                className="btn btn-primary"
                name="Foliares"
              ></Button>
            </Col>
            <Col className="col-sm-1">
              <Button
                type="button"
                isDisabled={saving}
                className="btn btn-primary"
                name="Sonda"
              ></Button>
            </Col>
            <Col className="col-sm-1">
              <Button
                type="button"
                isDisabled={saving}
                className="btn btn-primary"
                name="Satélite"
              ></Button>
            </Col>
            <Col className="col-sm-1">
              <Button
                type="button"
                isDisabled={saving}
                className="btn btn-primary"
                name="Plagas"
              ></Button>
            </Col>
            <Col className="col-sm-2">
              <Button
                type="button"
                isDisabled={saving}
                className="btn btn-primary"
                name="Info Visual"
              ></Button>
            </Col>
          </Row>
        </AdminContainer>
        <Row className="container-row">
          <Col className="col-sm-1">
            <Button
              type="submit"
              isDisabled={saving}
              className="btn btn-primary"
              name="Guardar"
              actionName="Guardando"
            ></Button>
          </Col>
          <Col className="col-sm-1">
            <Button
              type="button"
              isDisabled={saving}
              className="btn btn-secondary"
              onClick={onCancel}
              name={"Cancelar"}
            ></Button>
          </Col>
        </Row>

      </Container>
    </form>
  );
};

export default ParcelForm;
