import { connect } from "react-redux";
import Button from "../framework/Button";
import { Col, Container, Row } from "reactstrap";
import * as sentinelApi from "../../api/sentinelApi";
import { toast } from "react-toastify";
import { useState } from "react";
import * as rolesApi from "../../api/rolesApi";

type Props = {
}

function AdminPage({
  ...props
}: Props) {

  const [acting, setActing] = useState(false);

  function handleDownloadImages() {
    setActing(true)
    sentinelApi
      .downloadImages()
      .then(response => {
        if (response) {
          if (response.items.length === 0)
            toast.success("No hay imagenes para descargar");
          else {
            toast.success("Imagenes descargadas:");
            toast.info(JSON.stringify(response), { autoClose: false })
          }
        }
        setActing(false);
      })
      .catch(error => {
        toast.error("Error descargando imagenes:" + error);
        setActing(false);
      });

  }

  return (
    <>
      <Container>
        {/* <Row className="container-row" style={{ alignItems: "flex-end" }}>
          <Col className="col-sm-2">
            <Button
              type="button"
              className="btn btn-primary"
              onClick={() => handleDownloadImages()}
              name="Descargar Imagenes"
              isDisabled={acting}
              actionName={"Descargando..."}
            ></Button>
          </Col>  
          <Col className="col-sm-2">
            <Button
              type="button"
              className="btn btn-primary"
              onClick={() => rolesApi.createRoles()}
              name="Roles"
            ></Button> 
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

AdminPage.propTypes = {

};


function mapStateToProps(state: any, ownProps: any) {
  return {
  };
}

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPage);
