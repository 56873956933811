import { IUser } from "../components/users/types";
import authService from "./api-authorization/AuthorizeService";
import { handleResponse, handleError } from "./apiUtils";

const controllerName = "api/user";

export async function getUsers(): Promise<IUser[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName, {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}

export async function getTechnicians(): Promise<string[]> {
  const token = await authService.getAccessToken();
  const response = await fetch(controllerName + "/technicUsers", {
    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
  })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export async function saveUser(user: IUser): Promise<IUser> {
  const token = await authService.getAccessToken();

  const response = await fetch(controllerName,
    {
      method: user.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
      body: JSON.stringify(user)
    })
    .then(handleResponse)
    .catch(handleError);

  return response;
}


export function deleteCourse(userId: number) {

}
