import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadRegions } from "../../redux/actions/regionsActions";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { months, newTemplate, } from "../../tools/mockData";
import { Col, Container, Row } from "reactstrap";
import { IProduct } from "../products/types";
import { IRegion } from "../regions/types";
import { ITemplate, ITemplateProduct, ITemplateVieWModel } from "./types";
import { loadTemplates, saveTemplateWithProducts } from "../../redux/actions/templateActions";
import { loadProducts } from "../../redux/actions/productsActions";
import TemplateForm from "./TemplateForm";
import Button from "../framework/Button";
import { toast } from "react-toastify";
import SelectInput, { SelectOption } from "../framework/SelectInput";
import TemplateProductList from "./TemplateProductList";
import * as templateApi from "../../api/templateApi";
import { GetAllGroups } from "../utils/constants";

type Props = {
  products: IProduct[],
  regions: IRegion[],
  templates: ITemplate[],
  loadProducts: () => Promise<void>;
  loadRegions: () => Promise<void>,
  loadTemplates: () => Promise<void>,
  saveTemplateWithProducts: (event: any) => Promise<void>
}

function ManageTemplatePage({
  regions,
  products,
  templates,
  loadProducts,
  loadRegions,
  loadTemplates,
  saveTemplateWithProducts,
  ...props
}: Props) {

  const [productIndex, setProductIndex] = useState(-1);
  const [template, setTemplate] = useState(newTemplate);
  const [templateProducts, setTemplateProducts] = useState([] as ITemplateProduct[]);
  const [productId, setProductId] = useState(0);
  const [group, setGroup] = useState("");
  const [saving, setSaving] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const productGroups = GetAllGroups()

  const templateId = params.id;

  useEffect(() => {
    if (templates.length === 0) {
      loadTemplates()
        .catch(error => {
          console.error("Loading templates failed" + error);
        });
    }
    else {
      const templateSelected = templateId && templates.length > 0 ? getTemplateById(templates, templateId) : newTemplate;
      if (templateSelected)
        setTemplate(templateSelected);

      if (templateSelected?.id) {

      }
    }
  }, [loadTemplates, templateId, templates]);

  useEffect(() => {
    if (templateId) {
      templateApi.getTemplateProducts(Number(templateId))
        .then((products) => {
          setTemplateProducts(products);
        })
        .catch(error => {
          console.error("Loading template prodcuts failed" + error);
        });
    }
  }, [templateId]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
  }, [loadRegions, regions.length]);


  useEffect(() => {
    if (products.length === 0) {
      loadProducts()
        .catch(error => {
          console.error("Loading products failed" + error);
        });
    }
  }, [loadProducts, products.length]);


  function handleTemplateChange(event: any) {
    const { name, value } = event.target;
    setTemplate(prevRegion => ({
      ...prevRegion,
      [name]: value
    }));
  }

  function handleTemplateSave(event: any) {
    event.preventDefault();
    setSaving(true);

    const templateViewMode = {
      template,
      products: templateProducts
    } as ITemplateVieWModel;

    saveTemplateWithProducts(templateViewMode)
      .then(() => {
        toast.success("Plantilla guardada");
        setTimeout(() => {
          setSaving(false);
        }, (700));

        navigate(`/plantillas`);;
      })
      .catch(error => {
        setSaving(false);
        toast.error("Error. " + error.message, { autoClose: false });
      });
  }

  function handleGenerateReport(event: any) {
    if (template.id) {
      setSaving(true);
      templateApi.generateReports(template.id).then((response) => {
        toast.success("Informes generados");
        toast.info(JSON.stringify(response), { autoClose: false })
        setSaving(false);
      })
        .catch(error => {
          setSaving(false);
          toast.error("Error. " + error.message, { autoClose: false });
        });
    }
  }

  function handleProductChange(templateProduct: ITemplateProduct, event: any) {
    const { name, value } = event.target;
    let templateProductChanged = templateProducts.find(pe => pe.id === templateProduct.id);
    templateProductChanged = {
      ...templateProduct,
      [name]: value
    }

    const newTemplateProducts = templateProducts.map(p => p.id === templateProductChanged?.id ? templateProductChanged : p);
    setTemplateProducts(newTemplateProducts);
  }

  function handleProductDelete(templateProduct: ITemplateProduct, event: any) {
    const newTemplateProducts = templateProducts.filter(p => p.id !== templateProduct?.id);
    setTemplateProducts(newTemplateProducts);
  }

  function handleNewProduct(event: any) {

    const product = products.find(p => p.id === productId);

    if (product?.type === "Standard" && group !== "Grupo A" && group !== "Grupo B" && group !== "Grupo F") 
    {
      toast.error("Error. Productos de dosis por Hectárea solo pueden ser configurados con Grupos A, B o F");
      return;
    }

    if (product?.type === "Dosis por Hectárea" && group !== "Grupo B" && group !== "Grupo E" && group !== "Grupo F") {
      toast.error("Error. Productos de dosis por Hectárea solo pueden ser configurados con Grupos B, E o F");
      return;
    }

    const newProduct = {
      id: productIndex,
      templateId: templateId,
      productId: productId,
      dose: 0,
      group: group,
    } as unknown as ITemplateProduct;
    const newTemplateProducts = [...templateProducts, newProduct];
    setTemplateProducts(newTemplateProducts);

    setProductIndex(productIndex - 1);
  }

  return (
    <div>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <h4>Plantilla</h4>
          <TemplateForm
            template={template}
            months={months}
            regions={regions}
            handleChange={handleTemplateChange}
            handleSave={handleTemplateSave}
            handleGenerateReports={handleGenerateReport}
          />
        </Row>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <Container>
            <h5>Añadir Producto</h5>
            <Row className="container-row" style={{ alignItems: "flex-end" }}>
              <Col className="col-sm-3">
                <SelectInput
                  name="products"
                  value={productId}
                  defaultOption="Seleccione Producto"
                  options={products.map(pg => ({
                    value: pg.id,
                    text: pg.name
                  } as SelectOption))}
                  onChange={(e) => setProductId(products.find(p => p.id?.toString() === e.target.value)?.id ?? 0)}
                />
              </Col>
              <Col className="col-sm-3">
                <SelectInput
                  name="grupos"
                  value={group}
                  defaultOption="Seleccione Grupo"
                  options={productGroups.map(pg => ({
                    value: pg.name,
                    text: pg.name
                  } as SelectOption))}
                  onChange={(e) => setGroup(e.target.value)}
                />
              </Col>
              <Col className="col-sm-3">
                <Button
                  type="button"
                  className="btn btn-primary"
                  name="Añadir Producto"
                  onClick={handleNewProduct}
                  isDisabled={productId === 0}
                ></Button>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      { templateProducts && templateProducts.length > 0 &&
        <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          <Col className="col-sm-12">
            <TemplateProductList
              products={products}
              templateProducts={templateProducts}
              onChange={handleProductChange}
              onDelete={handleProductDelete}
            />
          </Col>
        </Row>
      </Container>
      }
    </div >
  );
}

ManageTemplatePage.propTypes = {
  regions: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  loadTemplates: PropTypes.func.isRequired,
  loadProducts: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  saveTemplateWithProducts: PropTypes.func.isRequired,
};

export function getTemplateById(templates: ITemplate[], id: string): ITemplate | null {
  return templates.find(template => template.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    regions: state.regions,
    products: state.products,
    templates: state.templates
  };
}

const mapDispatchToProps = {
  loadTemplates,
  loadRegions,
  loadProducts,
  saveTemplateWithProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTemplatePage);
