import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadTasks, saveTask } from "../../redux/actions/tasksActions";
import { loadParcels } from "../../redux/actions/parcelsActions";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ITask } from "./types";
import { newError, newTask } from "../../tools/mockData";
import TaskList from "./TaskList";
import Button from "../framework/Button";
import { Container, Row } from "reactstrap";
import { IParcel } from "../parcels/types";

type Props = {
  tasks: ITask[],
  parcels: IParcel[],
  loadTasks: () => Promise<void>,
  saveTask: (event: any) => Promise<void>,
  loadParcels: () => Promise<void>,

}

function TasksPage({
  tasks,
  parcels,
  loadTasks,
  saveTask,
  loadParcels,
  ...props
}: Props) {

  const [redirectToAddTaskPage, setRedirectToAddTaskPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (tasks == null || tasks?.length === 0) {
      loadTasks()
        .catch(error => {
          console.error("Loading tasks failed" + error);
        });
    }
  }, [loadTasks]);

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels()
        .catch(error => {
          console.error("Loading parcels failed" + error);
        });
    }
  }, [loadParcels, parcels.length]);

  function handleDeleteTask(task: ITask) {

  }

  function handleEditTask(task: ITask) {
    navigate(`/tarea/${task?.id}`);
  }

  function handleSave(task: ITask, action: string) {

    let taskToSave = task;

    if (action === "Complete") {

      taskToSave = 
      {
        ...task,
        isCompleted: true        
      };
    }
    else if (action === "Archive") {
      taskToSave = 
      {
        ...task,
        deletedDate: new Date()
      }
    }
    else if (action === "Reactive") {
      taskToSave = 
      {
        ...task,
        deletedDate: null,
        isCompleted: false
      }
    }

    saveTask(taskToSave)
      .then(() => {
        toast.success("Tarea guardada");
        setTimeout(() => {
          navigate(`/tareas`);
        }, (700));
      })
      .catch(error => {
        toast.error("Error al guardar la tarea: " + error.message, { autoClose: false });
      });
  }

  return (
    <>
      <Container>
      <h3>Tareas</h3>
      {redirectToAddTaskPage && <Navigate to="/tarea" />}       
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => setRedirectToAddTaskPage(true)}
            name="Crear Tarea"
          ></Button>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px", marginTop: "10px" }}>               
        <h4>Activas</h4>
          {
            <TaskList
              onDeleteClick={handleDeleteTask}
              onEditClick={handleEditTask}
              onSave={handleSave}
              tasks={tasks.filter(task => task.isCompleted === false && !task.deletedDate)}
              parcels={parcels}
              allowEdit={true}
            />
          }
        </Row>
        {/* <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>         
        <h4>Completadas</h4>
          {
            <TaskList
              onDeleteClick={handleDeleteTask}
              onEditClick={handleEditTask}
              onSave={handleSave}
              tasks={tasks.filter(task => task.isCompleted === true && !task.deletedDate)}
              parcels={parcels}
            />
          }
        </Row> */}
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>         
        <h4>Archivadas</h4>
          {
            <TaskList
              onDeleteClick={handleDeleteTask}
              onEditClick={handleEditTask}
              onSave={handleSave}
              tasks={tasks.filter(task => !!task.deletedDate)}
              parcels={parcels}
              allowEdit={true}
            />
          }
        </Row>
      </Container>
    </>
  );
}

TasksPage.propTypes = {
  tasks: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  loadTasks: PropTypes.func.isRequired,
  saveTask: PropTypes.func.isRequired,
  loadParcels: PropTypes.func.isRequired,
};

export function getTaskById(tasks: ITask[], id: string): ITask | null {
  return tasks.find(task => task.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    tasks: state.tasks,
    parcels: state.parcels,
  };
}

const mapDispatchToProps = {
  loadTasks,
  loadParcels,
  saveTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksPage);
