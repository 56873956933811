import { Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './ApiAuthorizationConstants';

type Props = {
  isAuthenticated: boolean | undefined,
  userName: string | undefined,
}

const LoginMenu = ({ isAuthenticated, userName }: Props) => {

  const anonymousView = (
    <Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-dark" to={ApplicationPaths.Login}>Login</NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-dark" to={ApplicationPaths.Register}>Register</NavLink>
      </NavItem>
    </Fragment>
  );

  const authenticatedView = (
    <Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-dark" to={ApplicationPaths.Profile}>{userName}</NavLink>
      </NavItem>    
    </Fragment>
  );

  return (
    <ul className="navbar-nav ml-auto">
      {isAuthenticated ? authenticatedView : anonymousView}
    </ul>
  );
};

export default LoginMenu;