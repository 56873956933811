import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router-dom";
import { ITemplate } from "./types";
import Button from "../framework/Button";
import { loadTemplates, saveTemplate } from "../../redux/actions/templateActions";
import TemplateList from "./TemplateList";
import { IRegion } from "../regions/types";
import { loadRegions } from "../../redux/actions/regionsActions";
import { Container, Row } from "reactstrap";

type Props = {
  templates: ITemplate[],
  regions: IRegion[],
  loadTemplates: () => Promise<void>,
  loadRegions: () => Promise<void>,
}

function TemplatePage({
  templates,
  regions,
  loadTemplates,
  loadRegions,
  ...props
}: Props) {

  const [redirectToAddTemplatePage, setRedirectToAddTemplatePage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (templates.length === 0) {
      loadTemplates()
        .catch(error => {
          console.error("Loading templates failed" + error);
        });
    }
  }, [loadTemplates, templates.length]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading templates failed" + error);
        });
    }
  }, [loadRegions, regions.length]);

  function handleEditTemplate(template: ITemplate) {
    navigate(`/plantilla/${template?.id}`);
  }

  return (
    <>
      <Container>
        <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
          {redirectToAddTemplatePage && <Navigate to="/plantilla" />}
          <h4>Plantillas</h4>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => setRedirectToAddTemplatePage(true)}
            name="  Crear Plantilla"
          ></Button>
          {
            <TemplateList
              onEditClick={handleEditTemplate}
              templates={templates}
              regions={regions}
            />
          }
        </Row>
      </Container>
    </>
  );
}

TemplatePage.propTypes = {
  templates: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  loadTemplates: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
};

export function getTemplateById(templates: ITemplate[], id: string): ITemplate | null {
  return templates.find(template => template.id?.toString() === id) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    templates: state.templates,
    regions: state.regions,
  };
}

const mapDispatchToProps = {
  loadTemplates,
  loadRegions,
  saveTemplate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatePage);
