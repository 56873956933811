import { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Maze.css";
import HeroControl from "./HeroControl";
import Board from "./Board";
import { bindActionCreators } from "redux";
import * as mazeActions from "../../../redux/actions/mazeActions"
import PropTypes from "prop-types";
import utils from "./math-utils";

const MazeGame = ({
  heroCords,
  bugCords,
  tail,
  numberOfRows,
  numberOfColumns,
  lastHeroDir,
  actions,
}) => {
  const secondsPerBug = 5;
  const heroSpeed = 40;

  const [secondsLeftMove, setSecondsLeftMove] = useState(0);
  const [secondsLeftBug, setSecondsLeftBug] = useState(secondsPerBug);
  const [bugsEaten, setBugsEaten] = useState(0);

  const handleHeroMove = (dir) => {
    actions.changeHeroDir(dir);
  };

  const generateBug = () => {
    const x = utils.random(0, numberOfRows);
    const y = utils.random(0, numberOfColumns);
    actions.showBug({ x, y });
  };

  useEffect(() => {
    const timerId = setTimeout(
      () => setSecondsLeftMove((prevSecondsLeft) => prevSecondsLeft + 1),
      heroSpeed
    );

    if (heroCords.x === bugCords.x && heroCords.y === bugCords.y) {
      actions.addTail(lastHeroDir);
      setBugsEaten(bugsEaten + 1);
      generateBug();
      setSecondsLeftBug(secondsPerBug);
    }

    actions.moveHero(lastHeroDir);

    return () => {
      clearTimeout(timerId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeftMove]);

  useEffect(() => {
    const timerId = setTimeout(
      () => setSecondsLeftBug((prevSecondsLeft) => prevSecondsLeft - 1),
      1000
    );

    if (secondsLeftBug === 0) {
      generateBug();
      setSecondsLeftBug(secondsPerBug);
    }

    return () => {
      clearTimeout(timerId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeftBug]);

  return (
    <>
      <div>Move:{secondsLeftMove}</div>
      <div>Bug:{secondsLeftBug}</div>
      <div>BugsEaten:{bugsEaten}</div>
      <div className="maze-game">
        <Board
          heroCords={heroCords}
          bugCords={bugCords}
          tail={tail}
          numRows={numberOfRows}
          numColumns={numberOfColumns}
        />
        <HeroControl handleHeroMove={handleHeroMove} />
      </div>
    </>
  );
};

MazeGame.propTypes = {
  heroCords: PropTypes.object.isRequired,
  bugCords: PropTypes.object.isRequired,
  tail: PropTypes.array.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  numberOfColumns: PropTypes.number.isRequired,
  lastHeroDir: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    heroCords: state.maze.heroCords,
    bugCords: state.maze.bugCords,
    tail: state.maze.tail,
    numberOfRows: state.maze.boardRows,
    numberOfColumns: state.maze.boardColumns,
    lastHeroDir: state.maze.lastHeroDir,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      moveHero: bindActionCreators(mazeActions.moveHero, dispatch),
      changeHeroDir: bindActionCreators(mazeActions.changeHeroDir, dispatch),
      showBug: bindActionCreators(mazeActions.showBug, dispatch),
      addTail: bindActionCreators(mazeActions.addTail, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MazeGame);
