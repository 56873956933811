import React from "react";
import PropTypes from "prop-types";
import { IProductElement } from "./types";
import { IElement } from "../elements/types";
import Button from "../framework/Button";

type Props = {
  productElements: IProductElement[],
  elements: IElement[]
  onEditClick: (event: any) => void,
  onDeleteClick: (event: any) => void,
}

const ProductElementList = ({ productElements, elements, onEditClick, onDeleteClick }: Props) => (
  <div className="scroll-container">
    {productElements.length > 0 ? <table className="table">
      <thead>
        <tr>
          <th>Elemento</th>
          <th>Porcentage</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {productElements.map(productElement => {
          return (
            <tr key={productElement.id}>
              <td>
                {elements.find(e => e.id === productElement.elementId)?.name}
              </td>
              <td>
                {productElement.percentage}
              </td>
              <td>
                <Button
                  type="submit"
                  className="btn btn-outline-primary"
                  onClick={() => onEditClick(productElement)}
                  name="Editar"
                ></Button>  
              </td>
            </tr>
          );
        })}
      </tbody>
    </table> : <div />}

  </div>
);




ProductElementList.propTypes = {
  productElements: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default ProductElementList;
