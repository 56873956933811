import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadUsers, saveUser } from "../../redux/actions/usersActions";
import { loadParcels, deleteParcel, saveParcel } from "../../redux/actions/parcelsActions";
import PropTypes from "prop-types";
import UserForm from "./UserForm";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { IUser } from "./types";
import { newError, newParcel, newUser } from "../../tools/mockData";
import { IParcel } from "../parcels/types";
import ParcelList from "../parcels/ParcelList";
import { Container, Row } from "reactstrap";
import ParcelForm from "../parcels/ParcelForm";
import { IRegion } from "../regions/types";
import { loadRegions } from "../../redux/actions/regionsActions";
import Button from "../framework/Button";
import { IDistributor } from "../distributors/types";
import { loadDistributors } from "../../redux/actions/distributorActions";
import { IApplicationUser } from "../menu/types";

type Props = {
  users: IUser[];
  applicationUser: IApplicationUser;
  parcels: IParcel[],
  regions: IRegion[],
  distributors: IDistributor[],
  loadUsers: () => Promise<void>;
  loadParcels: () => Promise<void>;
  loadRegions: () => Promise<void>;
  loadDistributors: () => Promise<void>;
  saveParcel: (parcel: IParcel) => Promise<void>;
  deleteParcel: (parcelId: number) => void;
  saveUser: (event: any) => Promise<void>;
};

function ManageUserPage({
  users,
  applicationUser,
  parcels,
  regions,
  distributors,
  loadUsers,
  loadParcels,
  loadRegions,
  loadDistributors,
  saveParcel,
  deleteParcel,
  saveUser,
  ...props
}: Props) {
  const emptyParcels: IParcel[] = [];
  const [user, setUser] = useState(newUser);
  const [userParcels, setUserParcels] = useState(emptyParcels);
  const [parcel, setParcel] = useState(newParcel);
  const [userErrors, setUserErrors] = useState(newError);
  const [parcelErrors, setParcelErrors] = useState(newError);
  const [saving, setSaving] = useState(false);
  const [showParcelForm, setShowParcelForm] = useState(false);
  const params = useParams();
  const userId = params.id;
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (parcels.length === 0) {
      loadParcels().catch((error) => {
        console.error("Loading parcels failed" + error);
      });
    }
  }, [parcels.length, loadParcels, users.length]);

  useEffect(() => {
    if (users.length === 0) {
      loadUsers().catch((error) => {
        console.error("Loading users failed" + error);
      });
    }
    else if (userId && users.length > 0) {
      const userSelected = getUserById(users, userId);

      if (userSelected) {
        setUser(userSelected);
      }
      else {
        setShowForm(false);
        toast.error("Usuario no encontrado");
      }
    }
    else if (users.length > 0) {
      setUser(newUser);
    }

  }, [users, userId]);

  useEffect(() => {
    if (user?.id && parcels.length > 0) {
      const userParcels = getParcelsByUserId(user.id, parcels);
      if (userParcels)
        setUserParcels(userParcels);
    }
  }, [user, parcels]);

  useEffect(() => {
    if (regions.length === 0) {
      loadRegions()
        .catch(error => {
          console.error("Loading regions failed" + error);
        });
    }
  }, [loadRegions, regions.length]);

  useEffect(() => {
    if (distributors.length === 0) {
      loadDistributors()
        .catch(error => {
          console.error("Loading distributors failed" + error);
        });
    }
  }, [loadDistributors, distributors.length]);

  function handleChange(event: any) {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }

  function userFormIsValid() {
    const newError = { fieldErrors: [] as any[], onSave: null };

    if (!user.name) newError.fieldErrors.push({ title: "Nombre es requerido", fieldName: "name" })
    
    setUserErrors(newError);
    // Form is valid if the errors object still has no properties
    return newError.fieldErrors.length === 0;
  }

  function parcelFormIsValid() {
    const newError = { ...parcelErrors, onSave: null };

    if (!parcel.variety) newError.fieldErrors.push({ title: "Variedad es requerido", fieldName: "variety" })

    setParcelErrors(newError);
    // Form is valid if the errors object still has no properties
    return newError.fieldErrors.length === 0;
  }

  function handleUserSave(event: any) {
    event.preventDefault();
    if (!userFormIsValid()) return;
    setSaving(true);
    saveUser(user)
      .then(() => {
        toast.success("Usuario guardado");
        setTimeout(() => {
          setSaving(false);
        }, (700));
      })
      .catch((error) => {
        setSaving(false);
        setUserErrors({ ...userErrors, onSave: error.message });
      });
  }

  function handleParcelChange(event: any) {
    const { name, value } = event.target;
    setParcel(prevParcel => ({
      ...prevParcel,
      [name]: value
    }));

    const newError = { ...parcelErrors };

    newError.fieldErrors = newError.fieldErrors.filter(e => e.fieldName !== name)

    setParcelErrors(newError);
  }

  function handleParcelCancel(event: any) {
    setShowParcelForm(!showParcelForm);
    setSaving(false);
  }

  function handleParcelSave(event: any) {
    event.preventDefault();
    if (!parcelFormIsValid()) return;
    setSaving(true);
    saveParcel(parcel)
      .then(() => {
        toast.success("Parcela guardada");
        setShowParcelForm(false);
        setParcelErrors(newError);
        setSaving(false);
      })
      .catch(error => {
        setSaving(false);
        setParcelErrors({ ...parcelErrors, onSave: error.message });
      });
  }

  async function handleCreateParcel() {
    setParcelErrors(newError);
    setParcel({ ...newParcel, userId: user.id ?? 0 });
    setShowParcelForm(true)
  }

  return (
    <div>
      {
        showForm && <Container>
          <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            <h4>Usuario</h4>
            <UserForm
              user={user}
              distributros={distributors}
              errors={userErrors}
              onChange={handleChange}
              onSave={handleUserSave}
              saving={saving}
            />
          </Row>
          {user.id ? <Row className=" card card-lift--hover shadow border-2 card wise-card" style={{ padding: "5px", marginBottom: "10px" }}>
            {showParcelForm ? <ParcelForm
              parcel={parcel}
              regions={regions}
              errors={parcelErrors}
              onChange={handleParcelChange}
              onSave={handleParcelSave}
              onCancel={handleParcelCancel}
              saving={saving}
            /> :
              <div>
                <h4>Parcelas</h4>
                <Button
                  type="button"
                  className="btn btn-primary add-course"
                  onClick={handleCreateParcel}
                  name="  Crear Parcela"
                ></Button>
                <ParcelList
                  parcels={userParcels}
                  regions={regions}
                  users={users}
                />
              </div>

            }
          </Row> : <div></div>}

        </Container>
      }

    </div >
  );
}

ManageUserPage.propTypes = {
  users: PropTypes.array.isRequired,  
  regions: PropTypes.array.isRequired,
  distributors: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  parcels: PropTypes.array.isRequired,
  loadParcels: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  loadDistributors: PropTypes.func.isRequired,
  deleteParcel: PropTypes.func.isRequired,
  saveParcel: PropTypes.func.isRequired,
};

export function getUserById(users: IUser[], id: string): IUser | null {
  return users.find((user) => user.id?.toString() === id) || null;
}

export function getParcelsByUserId(
  userId: number,
  parcels: IParcel[]
): IParcel[] | null {
  return parcels.filter((parcel) => parcel.userId === userId) || null;
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    users: state.users,
    applicationUser: state.applicationUser,
    parcels: state.parcels,
    regions: state.regions,
    distributors: state.distributors,
  };
}

const mapDispatchToProps = {
  loadUsers,
  saveUser,
  loadParcels,
  loadRegions,
  loadDistributors,
  deleteParcel,
  saveParcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserPage);
