import React, { useState } from "react";
import PropTypes from "prop-types";
import { IRegionElement } from "../regions/types";
import { IElement } from "../elements/types";
import TextInput from "../framework/TextInput";
import { newError } from "../../tools/mockData";
import { IParcelElement } from "./types";

type Props = {
  monthId: string,
  parcelElements: IParcelElement[],
  elements: IElement[],
  onChange: (id: number | null, event: any) => void,
}

const ReportElementList2 = ({ monthId, parcelElements, elements, onChange }: Props) => {

  const [errors] = useState(newError);

  return (
    <div className="scroll-container">
      {parcelElements.length > 0 ? <table className="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th style={{ width: '20%' }}>Valor</th>
            <th style={{width: '20%'}}>Porcentaje</th>
            <th>UF</th>
            <th>UF Actual</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {parcelElements.map(parcelElement => {
            return (
              <tr key={parcelElement.id}>
                <td>
                  {elements.find(e => e.id === parcelElement.elementId)?.name}
                </td>
                <td>
                  <TextInput
                    name="value"
                    value={parcelElement.value?.toString()}
                    onChange={(e) => onChange(parcelElement.id,  e)}
                    errors={errors.fieldErrors}
                    wrapperClass={"col 1"}
                  />
                </td>
                <td>
                  <TextInput
                    name="percentage"
                    value={parcelElement.percentage?.toString()}
                    onChange={(e) => onChange(parcelElement.id, e)}
                    errors={errors.fieldErrors}
                    wrapperClass={"col 1"}
                  />
                </td>
                <td>{parcelElement.total}</td>
                <td>{parcelElement.currentTotal}</td>
                <td><span className="dot" style={{ backgroundColor: parcelElement.total! > 0 && Math.abs(parcelElement.total! - parcelElement.currentTotal!) < 0.1 ? 'green' : 'red' }}></span></td>
              </tr>
            );
          })}         
        </tbody>
      </table> : <div />}
    </div>
  );
}

ReportElementList2.propTypes = {
  parcelElements: PropTypes.array.isRequired,
  elements: PropTypes.array.isRequired,
  monthId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReportElementList2;
